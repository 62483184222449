import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Overlay extends Component {
    // constructor(props){
    //     super(props)
    // }
    render() {
        return(
            <aside className="panel panel--overlay">
                <h3>{ this.props.title } <span onClick={this.props.close}>x</span></h3>
                { this.props.children }
            </aside>
        )
    }
}

export default inject('mainStore')(observer(Overlay));

import React, { Component } from 'react';
import { Input } from '../FormComponents';
import { observer } from 'mobx-react';

import "./ClaimForm.css";


class ItemForm extends Component { 
    constructor(props){
        super(props)

        this.state = {
            itemValues: {},
            items: [],
            requiredFields: ['weight','weightbreakdown','coilform','cutsheet','scrapweight','scraprate','location'],
            errorList: []
        }
        this.onChange = this.onChange.bind(this)
    }

    onChange(e){
        this.setState({ itemValues: { ...this.state.itemValues, [e.target.name]: e.target.value } })
    }

    add(){
        let errorList = [...this.state.requiredFields]
        for(let key in this.state.itemValues){
            if(this.state.requiredFields.includes(key) && this.state.itemValues[key] === ''){
                // errorList.splice(errorList.indexOf(key),1)
            } else {
                errorList.splice(errorList.indexOf(key),1)
            }
        }

        this.setState({ errorList: errorList})

        if(this.state.itemValues.weight > parseFloat(this.extractWeight(this.props.data))){
            errorList = [...this.state.errorList, 'weight']

            this.setState({ errorList: errorList})
        }

        if(errorList.length === 0){
            const { id, itemLocationId, lotNumber, itemName } = this.props.data.lot
            let item = { ...this.state.itemValues, id, itemLocationId, lotNumber, itemName }
            this.props.add(item)
        }

    }
    cancel(){
        let filledList = []
        for(let key in this.state.itemValues){
            if(this.state.requiredFields.includes(key) && this.state.itemValues[key] === ''){
                filledList.push(key)
            }
            if(this.state.requiredFields.includes(key)){
                filledList.push(key)
            }
        }

        if(filledList.length > 0 && filledList.length <= this.state.requiredFields.length){
            this.props.cancel(this.props.data)
            return;
        }
        if(filledList.length >  this.state.requiredFields.length){
            this.props.cancel(this.props.data)
            return;
        }
        if(filledList.length === 0 ){
            this.props.cancel(this.props.data)
            return;
        }
    }
    extractWeight(data){
        let weightLotText = data.name;
        let weight = (weightLotText.split(' '))[2]

        return weight.substr(1,weight.length - 2)
    }

    render(){
        const { data } = this.props
        const { errorList } = this.state
        return (
            <div className={`item-data`}>
                {/* <Label label={"Item Number"} value={data.name}/> 
                <Label label={"AAM Lot Number"} value={"500001"}/>
                <Label label={"AAM Heat Number"} value={"6549.89/1"}/>
                <Label label={"Original Invoiced Weight (LBS)"} value={"40998690303409490956"}/> */}
                <Input type={"number"} label={"Total Rejected Weight"} name={'weight'} onChange={this.onChange} altText={`The Total rejected weight should not exceed ${this.extractWeight(data)} *`} className={errorList.includes('weight') ? 'error' : ''} />
                <Input type={"text"} label={"Rejected weight Breakdown"} name={'weightbreakdown'} onChange={this.onChange} className={errorList.includes('weightbreakdown') ? 'error' : ''}/>
                <Input type={"text"} label={"Coil Form (current weight, has it been slit or painted, if so please explain) *"} name={'coilform'} onChange={this.onChange} className={errorList.includes('coilform') ? 'error' : ''}/>
                <Input type={"text"} label={"Cut Sheet (to what sizes, how many pieces per size) *"} name={'cutsheet'} onChange={this.onChange} className={errorList.includes('cutsheet') ? 'error' : ''}/>
                <Input type={"text"} label={"Additional scrap weight (not in sheet or coil form) *"} name={'scrapweight'} onChange={this.onChange} className={errorList.includes('scrapweight') ? 'error' : ''}/>
                <Input type={"number"} label={"Scrap Rate ($ / LB) *"} name={'scraprate'} onChange={this.onChange} className={errorList.includes('scraprate') ? 'error' : ''}/>
                <Input type={"text"} label={"Material location *"} name={'location'} onChange={this.onChange} className={errorList.includes('location') ? 'error' : ''}/>
                
                <div className='item-form'>
                    <button className='preview-button danger' onClick={() => this.cancel()}>Cancel</button>
                    <button className='preview-button' onClick={() => this.add()}>Add</button>
                </div>
            </div>
        )
    }
}

export default (observer(ItemForm));
// we need import this packages for run in another browsers:
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import '@babel/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter  } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { configure } from 'mobx';
import { Provider } from 'mobx-react';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// Components
import App from './App';

// Service workers
// import registerServiceWorker from './registerServiceWorker';

// State management
import { authStore } from './stores/AuthStore';
import { cartStore } from './stores/CartStore';
import { inventoryStore } from './stores/InventoryStore';
import { mainStore } from './stores/MainStore';
import { orderStore } from './stores/OrderStore';

const stores = {
    authStore,
    cartStore,
    inventoryStore,
    mainStore,
    orderStore
};

// Mobx configuration
configure({
    enforceActions: 'never'
    //isolatedGlobalState: true,
    //computedRequiresReaction: true
});

const history = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_IO_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing({
        tracingOrigins: [process.env.REACT_APP_API_URL],
  
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
  
        // ... other options
      })],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider { ...stores }>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

// registerServiceWorker();

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-order-confirmation {
    padding: 1rem 1rem 4rem 1rem;
    overflow: auto;
    height: 88vh;
    position: absolute;
    z-index: 0;
    right: -1000px;
    /* margin-left: 5px; */
    top: 80px;
}

.preview-button.button-outline {
    background: #fff;
    color: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 20px;
}
.preview-button.button-solid {
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/InventorySummary/PurchaseOrderConfirmation.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".purchase-order-confirmation {\n    padding: 1rem 1rem 4rem 1rem;\n    overflow: auto;\n    height: 88vh;\n    position: absolute;\n    z-index: 0;\n    right: -1000px;\n    /* margin-left: 5px; */\n    top: 80px;\n}\n\n.preview-button.button-outline {\n    background: #fff;\n    color: var(--blue);\n    border: 1px solid var(--blue);\n    border-radius: 20px;\n}\n.preview-button.button-solid {\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Style
import './LMEView.css';

// Components
import List from '../Order/History/List/List';

class LMEView extends Component {
    
    componentDidMount() {
        
    }

    handleFilters = (filter) => {
        this.props.inventoryStore.filterMonthlyLME(filter);
    }

    render() {
        const { data, columns, isLoading } = this.props
        return (
            <section className="box">
                <List 
                    columns={columns} 
                    data={data} 
                    type="inventory" 
                    rowSize={data && data.length} 
                    noDataText={"No Daily LME Prices Found"}
                    title={"Daily LME Prices"}
                    description={`There are ${data && data.length } LME prices`}
                    isLoading={isLoading}
                />          
            </section>
        );
    }
}

export default inject('mainStore', 'inventoryStore')(observer(LMEView));

import React from 'react';
import { toJS } from 'mobx';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';


import List from '../../History/List/List';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import { currencyFormatter } from '../../../../stores/MainStore';
import { UNITS, replaceCharactersAfterWord } from './NetSuiteStatusValues';

// Style
import '../OrderDetails.css';
import LoadingSipnner from '../../../UI/LoadingSipnner';

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
    }
}

class SalesOrder extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingRelatedRecords: true
        }
    }
    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            if(this.props.orderStore.fetchedOrderId !== this.props.match.params.id){
                //Reset this.props.orderStore.order to undefined so that the loader will trigger
                this.props.orderStore.order = undefined;
                this.props.orderStore.resetRecentViewedOrder();
                this.fetchData({ tranId: this.props.match.params.id, internalId: this.props.match.params.internalId });
            } else {
                //clear loading state if component remounts and the sales order id for the previous search has NOT changed
                this.setState({ isLoadingRelatedRecords: false })
            }
        } else {
            window.location.href = '/login';
        }
    }

    async fetchData(orderData){
        this.props.orderStore.fetchOrder(orderData)
        .then(() => {
            this.fetchBackgroundData(orderData);
        });
    }

    async fetchBackgroundData(orderData){
        this.props.orderStore.fetchRelatedRecordsBackground(orderData)
        .then(() => {
            this.setState({ isLoadingRelatedRecords: false})
        });
    }

    handlePacketZipFile() {
        let order = this.props.orderStore.orders.find((order) => order.tranId === this.props.match.params.id);
        this.props.orderStore.fetchPacketZipFile(order || this.props.orderStore.order,'download','transaction');
    }
    handleFile() {
        let order = this.props.orderStore.orders.find((order) => order.tranId === this.props.match.params.id);
        this.props.orderStore.fetchFile(order || this.props.orderStore.order,'download','transaction');
    }
    handleRFQFile(record) {
        this.props.orderStore.fetchFile(record,'download','transaction');
    }

    handleMTRFile(fileParams) {
        this.props.orderStore.fetchMTRDownload(fileParams);
    }

    getLotsPerItem(items,lots){
        if(lots.error){
            items.forEach((item) => {
                let lotColValue = item.lots.map((lot,key) =>
                    <div key={`lots-${key}`} className="download-icons">
                        {`(---)`}
                        <br/><br/>
                    </div>
                )

                item.lotValue = lotColValue;
            });
            return items;
        }

        items.forEach((item) => {
            let lotColValue = item.lots.map((lot,key) => {
                    let net_weight = lots[lot.internalId].net_weight;
                    let gross_weight = lots[lot.internalId].gross_weight;
                    let lotnumber = lots[lot.internalId].lotnumber;
                    let heat = lots[lot.internalId].heat;
                    let mtr_id = lots[lot.internalId].mtr_id;
                    let mtr_name = lots[lot.internalId].mtr_name;
                    let pieces = lots[lot.internalId].pieces;
                    let pieces_string = pieces ? pieces + 'ct.' : '';

                    if(net_weight === "") net_weight = 0;
                    if(gross_weight === "") gross_weight = 0;

                    return <div key={`lots-${key}`} className="download-icons" style={{ diplay: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        {`${lotnumber} [${heat}] ${pieces_string} (${net_weight ? (parseFloat(net_weight)).toFixed(2) : '---'})`}
                        {
                            mtr_id && mtr_name && <div className="download-icons">
                                {/* Some PDFs DO NOT Render properly. AAM-1152 has been created for this reason */}
                                {/* <Link to={ `/order/mtrpdfviewer/${ this.props.match.params.id }/${ mtr_id }/${mtr_name}/material-test-report` } className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}>View MTR</Link> */}
                                <div className="view-report__inline" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}  onClick={() => this.handleMTRFile({ internalId: mtr_id, fileName: mtr_name })}>Download MTR</div>
                            </div>
                        }
                        <br/><br/>
                    </div>;
                }
            );

            //check if item description includes the web portal phrase
            if(item.description && (item.description).includes('web portal')) {
                //replace user info from item description 
                item.description = replaceCharactersAfterWord(item.description, 'by');
            }
            item.lotValue = lotColValue;
        })
        return items;
    }

    formatShippingAddress(addressText) {
        let addressTextTokens = [];
        addressTextTokens = addressText ? addressText.split("\r\n") : [];

        let htmlAddressFormat = "";
        addressTextTokens.forEach((element) => {
            htmlAddressFormat += element !== 'undefined' ? `<span>${element}</span><br/>` : "";
        });

        return htmlAddressFormat;
    }
    render() {
        if(this.props.orderStore.order === undefined || this.props.orderStore.showRelatedRecords === false) return <LoadingSipnner/>; 
        const { 
            tranId, 
            orderCondition, 
            totalWeight, 
            status, 
            shippingCost, 
            shippingAddress, 
            shipMethod, 
            shipDate,
            orderMethod,
            itemsTotalCost,
            grandTotalCost,
            createdDate,
            quoteNo,
            quoteID,
            dueDate,
            comments,
            items,
            lots,
            otherRefNum
        } = toJS(this.props.orderStore.order);

        const { progress } = toJS(this.props.mainStore);

        const { isLoadingRelatedRecords } = this.state;
        
        //format lot items for the data table
        const lotItems = this.getLotsPerItem(items,lots);

        //setup table columns
        const columns = [
            {
                id: 'name',
                accessorKey: 'name',
                cell: info => info.getValue(),
                header: () => 'AAM Part #',
                footer: info => info.column.id
            },
            {
                id: 'description',
                accessorKey: 'description',
                cell: info => info.getValue(),
                header: () => 'Part Desc',
                footer: info => info.column.id
            },
            {
                id: 'location',
                accessorKey: 'location',
                cell: info => info.getValue(),
                header: () => 'Location',
                footer: info => info.column.id,
                size: 180
            },
            {
                id: 'quantity',
                accessorKey: 'quantity',
                cell: info => info.getValue(),
                header: () => 'Qty Rsvd',
                footer: info => info.column.id
            },
            {
                id: 'quantityBilled',
                accessorKey: 'quantityBilled',
                cell: info => info.getValue(),
                header: () => 'Qty Billed',
                footer: info => info.column.id
            },
            {
                id: 'units',
                accessorKey: 'units',
                cell: info => info.getValue() && UNITS[info.getValue()],
                header: () => 'UOM',
                footer: info => info.column.id,
                size: 80
            },
            {
                id: 'amount',
                accessorKey: 'amount',
                cell: info => info.getValue() && currencyFormatter(info.getValue(), 'USD', 'en-US'),
                header: () => 'Amount',
                footer: info => info.column.id
            },
            {
                id: 'totalRate',
                accessorKey: 'totalRate',
                cell: info => info.getValue(),
                header: () => 'TOTAL RT/LB',
                footer: info => info.column.id
            },
            {
                id: 'lotValue',
                accessorKey: 'lotValue',
                cell: info => info.getValue(),
                header: () => 'Lot [Heat] Pieces (Weight/LBS) MTR',
                footer: info => info.column.id,
                size: 280
            }
        ]
        const address = this.formatShippingAddress(shippingAddress);

        return (
            <div className="order-details-container">
                { this.props.orderStore.showRelatedRecords && <div className="panel" id="order-details">
                    <div className="order-header">
                    <h2>Sales Order</h2>
                    <div className="order-document-actions">
                        {/* The following line is to show the a link to the PDF viewer, which is still a little buggy as of 04/28/23.
                        It and all other similar links in the code have been commented out until the PDF viewer issue is fixed.
                        See AAM-942 and AAM-916. */}
                        {/* <Link to={ `/order/pdfviewer/${ bolNo }/${ truckId }/billoflading` } className="view-report ">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link> */}
                        {/* <div className="view-report__inline" style={{ cursor: 'pointer', color:'#283A8E' }} onClick={ (e) => this.handlePacketZipFile() }>Download Sales Order Packet Files <i className="icon-download" style={ { display: 'inline-block'} } /></div> */}
                        <div className="view-report__inline" style={{ cursor: 'pointer', color:'#283A8E' }} onClick={ (e) => this.handleFile() }>Download PDF <i className="icon-download" style={ { display: 'inline-block'} } /></div>
                    </div>
                    </div>
                    <div className="order-information">
                        <InfoGridPanel className={"col-4"}>
                            <InfoGridItem  label={"Sales OrderID:"} value={ tranId }/>
                            <InfoGridItem  label={"My PO:"} value={ otherRefNum }/>
                            <InfoGridItem  label={"Status:"} value={ status }/>
                            <InfoGridItem  label={"Condition:"} value={ orderCondition }/>
                            <InfoGridItem  label={"Method:"} value={ orderMethod }/>
                            <InfoGridItem  label={"Total Weight:"} value={ totalWeight }/>
                            <InfoGridItem  label={"Shipping Method:"} value={ shipMethod }/>
                            <InfoGridItem  label={"Shipping Cost:"} value={ currencyFormatter(shippingCost,'USD','en-US')  }/>
                            <InfoGridItem  label={"Ship Date:"} value={ moment(shipDate).local().format("YYYY-MM-DD")  }/>
                            <InfoGridItem  label={"Total Items Cost:"} value={ currencyFormatter(itemsTotalCost,'USD','en-US')  }/>
                            <InfoGridItem  label={"Grand Total Cost:"} value={ currencyFormatter(grandTotalCost,'USD','en-US') }/>
                            <InfoGridItem  label={"Due Date:"} value={ moment(dueDate).local().format("YYYY-MM-DD") }/>
                            <InfoGridItem  label={"Created Date:"} value={ moment(createdDate).local().format("YYYY-MM-DD")  }/>
                            <InfoGridItem  label={"RFQ:"} value={ <div className="view-report" style={ { cursor: 'pointer'} } >{ quoteNo } { quoteNo && <i className="icon-download" onClick={ (e) => this.handleRFQFile({ internalId: quoteID, tranId: quoteNo}) } style={ { display: 'inline-block', marginLeft: 5} } /> }</div>} />
                        </InfoGridPanel>
                        <InfoGridPanel className={"col-4"}>
                            <InfoGridItem  label={"Comments/Memo:"} value={ <div dangerouslySetInnerHTML={{ __html: comments}}></div>} />
                            <InfoGridItem  label={"Shipping Address:"} value={ shippingAddress ? <div dangerouslySetInnerHTML={{ __html: address }}></div> : ""}/>
                            <InfoGridItem  label={""} value={ ""} />
                            <InfoGridItem  label={""} value={ "" }/>
                        </InfoGridPanel>
                        { progress && progress.loading && <LoadingSipnner/>}
                    </div> 
                </div>}
                { 
                    this.props.orderStore.showRelatedRecords && 
                    <div className="box" id="order-items">
                        <List 
                            columns={columns} 
                            data={lotItems || []} 
                            noDataText="No orders found for the specified period." 
                            isLoading={isLoadingRelatedRecords}
                            type={"orders"}
                            title={"Sales Order Lot Items"}
                            description={`There are ${ lotItems && lotItems.length } items`}
                        /> 
                    </div> 
                }
            </div>
        );
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(withRouter(observer(SalesOrder)));

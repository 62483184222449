import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';

// Style
import '../OrderDetails.css';
import LoadingSipnner from '../../../UI/LoadingSipnner';

class RecentIFBillOfLadingDetails extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            bolNo: "", 
            from: "", 
            broker: "", 
            estShipDate: "", 
            weight: "", 
            lotCount: "", 
            status: '', 
            address: "", 
            eTA: "", 
            truckId: "",
            stopName: "",
            stopId: ""
        }
    }
    componentDidMount(){
        // let token = this.props.authStore.token;
        // if (token) {
            this.fetchData();
        // } else {
        //     window.location.href = '/login';
        // }
    }


    async fetchData(){
        let currentBillOfLading = this.props.orderStore.currentBillOfLading.split(';');
        this.setState({ loading: true})
        //if(this.props.orderStore.fetchedItemfulfillmentID !== currentBOL){
            this.props.orderStore.fetchBillOfLading(currentBillOfLading[0],currentBillOfLading[1])
            .then(() => {
                const { bolNo, from, broker, estShipDate, weight, lotCount, status, address, eTA, truckId, stopId, stopName } = this.props.orderStore.billOfLading;

                this.setState({ loading: false, bolNo, from, broker, estShipDate, weight, lotCount, status, address, eTA, truckId, stopId, stopName })
            })
        //}
    }

    generateItems(){
        let items = this.props.orderStore.itemfulfillmentItems
        .map((item) => { 
            return { 
                name: item.itemName,
                description: item.description,
                location: item.location && item.location.refName,
                onhand: item.onHand ,
                quantity: item.quantity,
                lotValue: item.lotValue
            } 
        })

        let columns = [
            {
                id: 'name',
                accessorKey: 'name',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'description',
                accessorKey: 'description',
                cell: info => info.getValue(),
                header: () => 'Description',
                footer: info => info.column.id
            },
            {
                id: 'location',
                accessorKey: 'location',
                cell: info => info.getValue(),
                header: () => 'Location',
                footer: info => info.column.id
            },
            {
                id: 'onhand',
                accessorKey: 'onhand',
                cell: info => info.getValue(),
                header: () => 'On Hand',
                footer: info => info.column.id
            },
            {
                id: 'quantity',
                accessorKey: 'quantity',
                cell: info => info.getValue(),
                header: () => 'Qty',
                footer: info => info.column.id
            },
            {
                id: 'lotValue',
                accessorKey: 'lotValue',
                cell: info => info.getValue(),
                header: () => 'Lot [Heat] (Weight/LBS) MTR',
                footer: info => info.column.id
            }
        ]
        return [items, columns];
    }

    handleFile(record) {
        this.props.orderStore.fetchFile({ internalId: record.id, tranId: record.tranid },'download','transaction')
    }

    handleBOLFile(record) {
        this.setState({ loading: true})

        if(record.id === 'No_truck_id') {
            this.setState({ loading: false})
            return this.props.mainStore.setError('This item fulfillment has no Truct ID');
        }
        
        this.props.orderStore.fetchFile({ internalId: record.id, tranId: record.tranid, stopId: record.stopId },'download','billoflading')
        .then(() => {
            this.setState({ loading: false})
        })
    }

    getLotsPerItem(order, status){
        const items = order.items;
        const lots = order.lots;
        let new_items = [];
        items.forEach((item) => {
            item.lots.map((lot,key) => {
                let net_weight = lots[lot.internalId].net_weight;
                let gross_weight = lots[lot.internalId].gross_weight;
                let lotnumber = lots[lot.internalId].lotnumber;
                let heat = lots[lot.internalId].heat;

                if(net_weight === "") net_weight = 0;
                if(gross_weight === "") gross_weight = 0;
                
                return new_items.push({ 
                    salesOrderNo:order.tranId, 
                    name: item.name, 
                    lotNumber: lotnumber, 
                    heat: heat, 
                    status: status,
                    piece: lot.quantity,
                    weight: (parseFloat(net_weight)) > 0 ? (parseFloat(net_weight)).toFixed(2) : '---'
                })
            }
            )
        })        
        return new_items
    }
    
    render() {
        const { loading } = this.state;
        const { bolNo, from, broker, estShipDate, weight, lotCount, status, address, eTA, truckId, stopId, stopName } = this.state;

        return (
            <section className="box inventory">
                <div className="order-details-container">
                    <div className="panel" id="order-details">
                        <div className="order-header">
                            <h2>Bill of Lading</h2>
                           {
                            bolNo && <div className="order-document-actions">
                                {/* The following line is to show the a link to the PDF viewer, which is still a little buggy as of 04/28/23.
                                It and all other similar links in the code have been commented out until the PDF viewer issue is fixed.
                                See AAM-942 and AAM-916. */}
                                {/* <Link to={ `/order/pdfviewer/${ bolNo }/${ truckId }/billoflading` } className="view-report ">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link> */}
                                    <div className="view-report" onClick={ (e) => this.handleBOLFile({id:truckId ? truckId : 'No_truck_id' , tranid:'BOL_' + stopName, stopId }) }>Download PDF <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } /></div>
                                </div>
                            }

                        </div>
                        <div className="order-information">
                            <InfoGridPanel className={"col-4"}>
                                <InfoGridItem  label={"BOL ID:"} value={ bolNo }/>
                                <InfoGridItem  label={"Stop Name:"} value={ stopName }/>
                                <InfoGridItem  label={"Broker/Company:"} value={ broker }/>
                                <InfoGridItem  label={"Weight:"} value={ weight }/>
                                <InfoGridItem  label={"# of Lots"} value={ lotCount }/>
                                <InfoGridItem  label={"Est Ship Date:"} value={ estShipDate }/>
                                <InfoGridItem  label={"ETA Date:"} value={ eTA }/>
                                <InfoGridItem  label={"Status:"} value={ status }/>
                            </InfoGridPanel>
                            <InfoGridPanel className={"col-2"}>
                                <InfoGridItem  label={"From:"} value={ from }/>
                                <InfoGridItem  label={"To:"} value={ address }/>
                            </InfoGridPanel>
                            { loading && <LoadingSipnner />}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(RecentIFBillOfLadingDetails));

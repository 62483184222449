import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import List from './List/List';

class Claims extends Component {

    componentDidMount(){
        let token = this.props.authStore.token;
        if (token) {
            
        } else {
            window.location.href = '/login';
        }
    }


    render() {

        let columns = [
            { Header: 'TranId', accessor: 'tranid'},
            { Header: 'Name', accessor: 'trandisplayname' },
            { Header: 'Tran Date', accessor: "trandate"},
	        { Header: 'Created Date', accessor: "createddate"}
        ]
        
        return (
            <div className="box order-history">
                <List items={[]} columns={columns} noDataText={"Claim Records"} />
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(Claims));

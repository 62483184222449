import React, { useEffect } from 'react';
import {  NavLink, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

// Style
import './OrderDetails.css';
import OrderDetailsRouter from "./OrderDetailsRouter";

function OrderDetails(props){
    const params = useParams()

    useEffect(() => {
        const token = props.authStore.token
        if (!token) { window.location.href = '/login'; } 
    },[props.authStore.token])

    const relatedRecordsMenu = (tranId, internalId) => {
        const routes = [
            { name: props.orderStore.showRelatedRecords ? "Sales Order" : null, path:"order" }, 
            { name: props.orderStore.showRelatedRecords ? "Item Fulfillments" : null, path:"itemfulfillment" }, 
            { name: props.orderStore.showRelatedRecords ? "Invoices" : null, path:"invoice" }
        ]
        let links = routes.map((route,key) => <div key={`item-docs-${key}`} className="order-document-actions">
                <NavLink to={{ pathname: `/order/detail/${route.path}/${ tranId }/${ internalId }` }}>{ route.name }</NavLink>
            </div>)

        return links
    }


    let page = window.location.pathname.split('/')[3];
    
    const { currentItemShip, currentInvoice, quote } = toJS(props.orderStore);
    let invoiceID = currentInvoice ? currentInvoice.tranid : '';
    let itemfulfillmentID = currentItemShip ? currentItemShip.tranid : '';
    let quoteID = quote ? quote.tranId : '';
    
    const pageDetails = {
        "order": "Sales Order Details", 
        "quote": `RFQ ${ quoteID }`,
        "itemfulfillment": "Item Fulfillments",
        "itemfulfillment-details": `Item Fulfillment ${ itemfulfillmentID }`,
        "itemfulfillment-bol": `Item Fulfillment Bill of Lading ${ itemfulfillmentID }`,
        "invoice": "Invoices",
        "invoice-details": `Invoice ${ invoiceID }`
    }

    return (
        <section className="box inventory">
            <div className="panel order-details-page">
                <div>
                    <NavLink to={"/order/history"}>Return to the sales order page</NavLink>
                    <h2 style={{ marginTop: '5px' }}>Sales Order { params.id } &gt; { pageDetails[page] } </h2>
                </div>
                <div className="order-related-links">
                    { relatedRecordsMenu(params?.id, params?.internalId) }
                </div>
            </div>
            <OrderDetailsRouter />
        </section>
    );
    
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(OrderDetails));

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import "./PurchaseOrderConfirmation.css";

class PurchaseOrderConfirmation extends Component { 
    constructor(props){
        super(props)
        
        this.showClaims = this.showClaims.bind(this)
        this.close = this.close.bind(this)
        this.loadPage = this.loadPage.bind(this)
    }

    close(){
        this.props.inventoryStore.slideViewOut('purchase-order-confirmation')
        this.props.inventoryStore.clearFilters(true)
    }
    showClaims(){
        this.props.finish()
    }
    loadPage(path){
        this.props.inventoryStore.slideViewOut('purchase-order-confirmation')
        window.location.href = `/${path}`;
    }
    goBack(){
        const element = document.getElementById("purchase-order-confirmation")
        element.style.right = "-1000px";
        element.style.transition = "0.5s ease-in-out";
    }

    render(){

        return (
            <div className='panel purchase-order-confirmation' id="purchase-order-confirmation">
                <div style={{ float: 'right', cursor: 'pointer', fontSize: '20px'}} onClick={() => this.close() }>&times;</div>
                <div className='confirmation'>
                    <p className='submission-text'>Thanks for Your PO Submission!</p>
                    <p className='submission-message'>Official confirmation of your order to follow within one (1) business day.</p>
                    <p className='submission-message'>Your pending orders can be viewed in <b>My Account &gt; View Order History</b></p>
                    {/* <p className='submission-message'>Email images and documentation to <a href='mailto:claims@aametals.com'>claims@aametals.com</a> with a reference to <b>RMA####</b> in the subject line. </p> */}
                    <div style={{ display: 'flex', gap: '10px'}}>
                        <button className='preview-button button-outline' onClick={() => this.loadPage('inventory-search')}>Create Another PO</button>
                        <button className='preview-button button-solid' onClick={() => this.loadPage('order/history')}>View My Order History</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('inventoryStore', 'mainStore', 'authStore')(observer(PurchaseOrderConfirmation));
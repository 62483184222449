import React, { Component } from 'react';

import "./FormComponents.css";

class Spacer extends Component { 

    render(){
        return (
            <div className='spacer'></div>
        )
    }
}

export default Spacer;
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import LoadingSipnner from '../../../UI/LoadingSipnner';
import { mainStore } from '../../../../stores/MainStore';

class LOT extends Component {
    constructor(props) {
        super(props)

        this.state = {
            heat: "",
            lotnum: "", 
            itemid: "",
            item_name: "", 
            eTA: "", 
            mtr_id: "", 
            mtr_filename: "", 
            mtr_rc_status: "",
            weight: "", 
            salesdescription: "", 
            lot_class: "", 
            location: "", 
            status: "",
            salesOrderNo: "",
            itemShipNo: "",
            invoiceNo: "",
            lotNo: "",
            heatNo: "",
            piece_count: "",
            loading: false,
            disableLotField: false,
            disableHeatField: false
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            const { searchResults } = this.props.orderStore;
            if(searchResults) {
                this.setState({ ...searchResults.data })
            }
        } else {
            window.location.href = '/login';
        }
    }

    handleLotNoChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ lotNo: value.trim(), heatNo:'', disableHeatField: true });
        }
        if(value === "" || value === " ") {
            this.setState({ disableHeatField: false });
        }
    }

    handleLotHeatNoChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ lotNo: '', heatNo: value.trim(), disableLotField: true });
        }
        if(value === "" || value === " ") {
            this.setState({  disableLotField: false });
        }
    }

    handleSearch = (e) => {
        e.preventDefault()
        const { lotNo, heatNo } = this.state
        if(lotNo) {
            this.setState({ loading: true })
            this.props.orderStore.search(lotNo,'lot')
            .then(() => {
                this.setState({ loading: false })
                const { searchResults } = this.props.orderStore;
                if(searchResults) {
                    this.setState({ ...searchResults.data })
                }
            })
            return
        } 
        if(heatNo) {
            this.setState({ loading: true })
            this.props.orderStore.search(heatNo,'heat')
            .then(() => {
                this.setState({ loading: false })
                const { searchResults } = this.props.orderStore;
                if(searchResults) {
                    this.setState({ ...searchResults.data })
                }
            })
            return
        } 
        
        if(heatNo === "" && lotNo === "") {
            mainStore.setError("Please provide either a lot or heat number");
        }
    }

    handleMTRFile(fileParams) {
        this.setState({ loading: true })
        this.props.orderStore.fetchMTRDownload(fileParams)
        .then(() => {
            this.setState({ loading: false })
        })
    }
    download(mtr_id,mtr_filename){

        //add mtr_filename to the truthy statement
        return mtr_id && mtr_filename && <div className="order-document-actions">
        {/* Some PDFs DO NOT Render properly. AAM-1152 has been created for this reason */}           
        {/* <Link to={ `/order/mtrpdfviewer/${ mtr_id }/${ mtr_id }/${mtr_filename}/material-test-report` } className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}} >View MTR</Link>
        - */}
        <div className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}  onClick={() => this.handleMTRFile({ internalId: mtr_id, tranId: mtr_filename, fileName: mtr_filename })}>
            Download MTR
        </div>
    </div>
    }

    render() {
        const { heat, lotnum, item_name, eTA, mtr_id, mtr_filename, weight, salesOrderNo, itemShipNo, invoiceNo, lot_class, location, status, pieceCount, lotNo, heatNo, disableHeatField, disableLotField, loading } = this.state
        const { searchResults } = this.props.orderStore;


        return (
            <section className="box inventory">
                <div className="panel panel-default">
                    <h3>Search My Lots/Heat</h3>
                    <div className="panel-body">
                        <form onSubmit={ this.handleSearch }>
                            <div className="flex flex-row">
                                <div className="flex-1 flex-2-md">
                                    <input ref="tranId" className={`no-margin ${ disableLotField ? 'disable' : ''} `}  type="text" placeholder="Lot # e.g. XXXXXXX" value={ lotNo ? lotNo : '' } onChange={ this.handleLotNoChange }/>
                                </div>
                                <div className="flex-2 flex-2-md">
                                    <input ref="tranId" className={`no-margin ${ disableHeatField ? 'disable' : ''} `} type="text" placeholder="Heat # " value={ heatNo ? heatNo : '' } onChange={ this.handleLotHeatNoChange } disabled={disableHeatField}/>
                                </div>
                                <div className="flex-1">
                                    <button className="button button--block no-margin" type="submit">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='panel' style={{ height: 400 }}>
                    { loading ? <LoadingSipnner /> : null }
                    { searchResults && searchResults.error ? <div style={{ position: "absolute", top:"40%", left: "30%", fontSize: "1.2rem"}}>{ searchResults.message }</div> : ""}
                    {
                        searchResults && searchResults.searchType === "lot" 
                        ? 
                        <div className="order-information">
                            <InfoGridPanel className={"col-4"}>
                                <InfoGridItem  label={"Heat #:"} value={ heat }/>
                                <InfoGridItem  label={"Lot #:"} value={ lotnum }/>
                                <InfoGridItem  label={"Sales Order #:"} value={ salesOrderNo ? salesOrderNo : "N/A"}/>
                                <InfoGridItem  label={"Item Fulfillment #:"} value={ itemShipNo ? itemShipNo : "N/A"}/>
                                <InfoGridItem  label={"Invoice #:"} value={ invoiceNo ? invoiceNo : "N/A"}/>
                                <InfoGridItem  label={"AAM Part #:"} value={ item_name }/>
                                <InfoGridItem  label={"ETA Date:"} value={ eTA }/>
                                <InfoGridItem  label={"MTR"} value={ this.download(mtr_id, mtr_filename) }/>
                                <InfoGridItem  label={"Weight:"} value={ weight }/>
                                <InfoGridItem  label={"Class:"} value={ lot_class }/>
                                <InfoGridItem  label={"Location:"} value={ location }/>
                                <InfoGridItem  label={"Status:"} value={ status }/>
                                <InfoGridItem  label={"Pieces:"} value={pieceCount}/>
                            </InfoGridPanel> 
                        </div>
                        : ""
                    }
                </div>
            </section>
        );
    }
}

export default inject( 'orderStore','mainStore', 'authStore')(observer(LOT));
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Style
import './RecentItemFulfillments.css';

// Components
import List from '../Order/History/List/List';
import SkeletonLoader from '../UI/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

class RecentItemFulfillments extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }
    
    viewBOLDetails(path,tranId){
        this.props.orderStore.setCurrentBillOfLading(tranId);
        this.props.history(path);
    }

    isLoading(props){
        const { value } = props;
        return !value ? <SkeletonLoader height={5} borderRadius={10}/> : value;
    }

    render() {
        const { data, columns, isLoading } = this.props;

        return (
            <section className="box">
                <List 
                    columns={columns} 
                    data={data} 
                    type="inventory" 
                    rowSize={data && data.length} 
                    noDataText={"No Recent BOLs"} 
                    title={"Recent BOLs"}
                    description={`There are ${data.length} recent BOLs`}
                    isLoading={isLoading}
                />          
            </section>
        );
    }
}

export default inject('mainStore', 'inventoryStore', 'orderStore')(observer((props) => <RecentItemFulfillments history={useNavigate()} {...props}/>));

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Session } from 'bc-react-session';


import PreviewGroup from './PreviewGroup';
import { Spacer } from '../FormComponents';
import "./ClaimForm.css";

class PreviewClaimForm extends Component { 
    constructor(props){
        super(props)

        this.state = {
            PONumber: this.props.PONumber
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.preparePayload = this.preparePayload.bind(this)
    }

    onSubmit(e){
        e.preventDefault()
        let payload = this.preparePayload();
        this.props.orderStore.submitClaim(payload)
        .then(() => {
            if(this.props.orderStore.claimResponse === ""){
                this.props.show()
            }
        })
    }
    goBack(){
        this.props.hide()
    }

    preparePayload(){
        const { payload: { currentCompanyID } } = Session.get("aametals-app-session");
        const { basicValues, itemValues, payloadItems, defectType, defectLocation, productType } = this.props.payload
        let items = []
        let materialLocation = ""
        let lotWeights = ""
        let date = (new Date()).toISOString().replace('T',' ')
        let meetingNotes = `${date.split('.')[0]} \n CUSTOMER PORTAL CLAIM FORM \n\n`

        meetingNotes += `Defect Detected At: \n ${basicValues.defect} \n\n` 
        meetingNotes += `Type of Defect: \n ${defectType.map((item) => `${item}`)} \n\n`
        meetingNotes += `Location of Defect: \n ${defectLocation.map((item) => `${item}`)} \n\n`
        meetingNotes += `Type of Product: \n ${productType.map((item) => `${item}`)} \n\n`
        meetingNotes += `Other Comments: \n ${basicValues.comments} \n\n\n`


        payloadItems.forEach((item) => {
            items.push({ 
                "item":{id: item.id}, 
                amount: 2, 
                location: item.itemLocationId, 
                custcol_heat_lot_details: item.lotNumber,
                quantity: 32, 
                custcol_weight_lbs: parseFloat(item.weight),
                custcol_customer_scrap_rate: parseFloat(item.scraprate)
            })
            
            let lotNumber = ((item.lotNumber).split(" "))[0]
            materialLocation += `---Lot ${lotNumber}--- \n ${item.location} \n\n`
            lotWeights += `---Lot ${lotNumber}--- \n Rejected weight breakdown: \n ${item.weight} \n Coil Form: \n ${item.coilform} \n Cut Sheet: \n ${item.cutsheet} \n Additional Scrap Weight: \n ${item.scrapweight} \n\n`
        })
        meetingNotes += lotWeights;
        
        let stp = Date.now().toString(); 
        let payload = {
            "entity": currentCompanyID,
            "custbodyrma_claim_reason_detail": basicValues.description,
            "custbodyrma_claim_types": basicValues.claimtype,
            "custbody_claim_emails": basicValues.email,
            "custbodyrma_secondary_sales_order_no": itemValues.lot.orderId,
            "location":itemValues.lot.orderLocationId,
            "custbody_rma_responsible_party": "1",
            "custbody31": meetingNotes,
            "custbody_pickup_name": basicValues.name,
            "custbody_pickup_phone": basicValues.phone,
            "custbody_current_address_claim_materia": materialLocation,
            "otherrefnum": this.state.PONumber,
            "custbody_customer_ref_number_in_rma":`${this.state.PONumber}-${stp.substring(stp.length-3,stp.length)}`,
            "item": {
                "items": items
            }
        }

        return payload

    }

    extractWeight(item){
        let weightLotText = item.lotNumber;
        let data = (weightLotText.split(' '))

        return {weight: data[2].substr(1,data[2].length - 2), lotNumber: data[0], heat: data[1]}
    }
    
    render(){
        const { basicValues,  payloadItems, claimTypes, defectLocation, productType } = this.props.payload
        return (
            <div className='panel claim'>
                <form onSubmit={(e) => this.onSubmit(e)}>
                    <div className='claimform'>
                        <div className='request-info'>
                            <div className='preview-info'>
                                <div className='exclamation'>!</div>
                                This is a preview. After confirming the information, you must hit SUBMIT below in order to file your claim.
                            </div>
                            <div className='heading'>Claim Request</div>
                        </div>
                        {/* <div className='contact-info'>
                            <div className='heading'>Customer Information</div>
                            <PreviewGroup label={"Contact Name"} value={basicValues.name}/>
                            <PreviewGroup label={"Contact Phone"} value={basicValues.phone}/>
                            <PreviewGroup label={"Email"} value={basicValues.email}/>
                            <PreviewGroup label={"Name"} value={"Value"}/>
                            <PreviewGroup label={"Name"} value={"Value"}/>
                            <PreviewGroup label={"Name"} value={"Value"}/>
                        </div> */}
                        <div className='contact-info'>
                            <div className='heading'>Contact Information</div>
                            <PreviewGroup label={"Contact Name"} value={basicValues.name}/>
                            <PreviewGroup label={"Contact Phone"} value={basicValues.phone}/>
                            <PreviewGroup label={"Email"} value={basicValues.email}/>
                        </div>
                        <div className='rejected-material-info'>
                            <div className='heading'>Material Rejected</div>
                            {
                                payloadItems.map((item,key) => <div key={`previw-${key}`}>
                                    <PreviewGroup label={"Item Number"} value={item.itemName}/>
                                    <PreviewGroup label={"AAM Lot Number"} value={(this.extractWeight(item)).lotNumber}/>
                                    <PreviewGroup label={"AAM Heat Number"} value={(this.extractWeight(item)).heat}/>
                                    <PreviewGroup label={"Original Invoiced Weight (LBS)"} value={(this.extractWeight(item)).weight}/>
                                    <PreviewGroup label={"Total Rejected Weight (LBS)"} value={item.weight}/>
                                    <PreviewGroup label={"Rejected weight breakdown"} value={item.weightbreakdown}/>
                                    <PreviewGroup label={"Coil Form"} value={item.coilform}/>
                                    <PreviewGroup label={"Cut Sheet (PCS)"} value={item.cutsheet}/>
                                    <PreviewGroup label={"Additional scrap weight"} value={item.scrapweight}/>
                                    <PreviewGroup label={"Scrap Rate ($ / LB)"} value={item.scraprate}/>
                                    <PreviewGroup label={"Material location"} value={item.location}/>
                                    <Spacer />
                                </div>
                                )
                            }
                        </div>
                        <div className='detail-info'>
                            <div className='heading'>Details & Other Information</div>
                            <PreviewGroup label={"Defect Detected at"} value={basicValues.defect}/>
                            <PreviewGroup label={"Claim Type"} value={(claimTypes.filter((item) => item.value === basicValues.claimtype)[0])['text']}/>
                            <PreviewGroup label={"Location of Defect"} value={defectLocation.map((item) => `${item}; `)}/>
                            <PreviewGroup label={"Type of Product"} value={productType.map((item) => `${item}; `)}/>
                            <PreviewGroup label={"Detailed Explanation of The Defect(s) Claimed"} value={"N/A"}/>
                            <PreviewGroup label={"Please provide a detailed explanation of the defect(s) claimed *"} value={basicValues.description}/>
                            <PreviewGroup label={"Other Comments"} value={basicValues.comments}/>

                            <div className='button-group'>
                                <button className='preview-button secondary' onClick={ (event) => this.goBack(event) }>Back to edit</button>
                                <button className='preview-button'>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(PreviewClaimForm));
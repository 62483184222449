import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { currencyFormatter } from '../../../../stores/MainStore';

// Style
import '../OrderDetails.css';
import List from '../List/List';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import Loader from '../../../UI/Loader';
import PlaceHolder from '../../../UI/PlaceHolder';

class RequestForQuote extends Component {
    constructor(props){
        super(props)

        this.state = {
            haveQuote: true
        }
    }
    
    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            if(this.props.orderStore.fetchedQuoteTranId !== this.props.match.params.id){
                this.fetchData(this.props.match.params.id)
            }  
        } else {
            window.location.href = '/login';
        }
    }
    

    async fetchData(tranId){
        let order = this.props.orderStore.orders.find((order) => order.tranId === tranId);
        if(order.quoteID === null){
            this.setState({ haveQuote: false })
        }else {
            this.props.orderStore.fetchQuote(order)
            .then(() => {
                this.props.orderStore.fetchQuoteItems(order)
                .then(() => {
                    this.setState({ haveQuote: true })
                })
            })
        }
    }

    handleFile(record) {
        this.props.orderStore.fetchFile(record,'download','transaction')
    }


    render() {
        if(this.state.haveQuote === false) return <PlaceHolder text={`This Order, (${ this.props.match.params.id }) has no quote!`}/> 
        if(this.props.orderStore.quote === undefined) return <Loader noText={true}/>; 

        const { id, tranId,tranDate,totalCostEstimate,shipMethod,status,total,shipDate,lastModifiedDate} = this.props.orderStore.quote

        let quoteitems = this.props.orderStore.quoteitems
        .filter((item) => item.amount > 0)
        .map((item) => { 
            return { 
                name: item.item.refName, 
                quantity: item.quantity, 
                amount: item.amount, 
		        costEstimate: item.costEstimate,
                rate: item.rate, 
		        costEstimateRate: item.costEstimateRate,
		        description: item.description
            } 
        })
        let columns = [
            {
                id: 'name',
                accessorKey: 'name',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'quantity',
                accessorKey: 'quantity',
                cell: info => info.getValue(),
                header: () => 'Qty',
                footer: info => info.column.id
            },
            {
                id: 'amount',
                accessorKey: d => { return currencyFormatter(d.amount,'USD','en-US') } ,
                cell: info => info.getValue(),
                header: () => 'Rate',
                footer: info => info.column.id
            },
            {
                id: 'costEstimate',
                accessorKey: d => { return currencyFormatter(d.costEstimate,'USD','en-US') } ,
                cell: info => info.getValue(),
                header: () => 'Cost Est',
                footer: info => info.column.id
            },
            {
                id: 'rate',
                accessorKey: 'rate',
                cell: info => info.getValue(),
                header: () => 'Rate',
                footer: info => info.column.id
            },
            {
                id: 'costEstimateRate',
                accessorKey: 'costEstimateRate',
                cell: info => info.getValue(),
                header: () => 'Cost Est. Rate',
                footer: info => info.column.id
            }
       ]
       
        return (
            <div className="order-details-container">
                <div className="panel" id="order-details">
                    <div className="order-header">
                        <h2>RFQ</h2>
                        <div className="order-document-actions">
                            {/* The following line is to show the a link to the PDF viewer, which is still a little buggy as of 04/28/23.
                            It and all other similar links in the code have been commented out until the PDF viewer issue is fixed.
                            See AAM-942 and AAM-916. */}
                            {/* <Link to={ `/order/pdfviewer/${ tranId }/${ id }/transaction` } className="view-report">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link> */}
                            <div className="view-report" onClick={ (e) => this.handleFile({ internalId: id, tranId: tranId}) }>Download PDF <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } /></div>
                        </div>
                    </div>
                    <div className="order-information">
                        <InfoGridPanel className={"col-4"}>
                            <InfoGridItem label={"RFQ ID:"} value={ tranId }/>
                            <InfoGridItem label={"Total Estimate Cost:"} value={ currencyFormatter(totalCostEstimate,'USD','en-US') }/>
                            <InfoGridItem label={"Total Cost:"} value={ currencyFormatter(total,'USD','en-US') }/>
                            <InfoGridItem label={"Ship Method:"} value={ shipMethod.refName }/>
                            <InfoGridItem label={"Status:"} value={ status.refName }/>
                            <InfoGridItem label={"Ship Date:"} value={ shipDate }/>
                            <InfoGridItem label={"Transaction Date:"} value={ tranDate }/>
                            <InfoGridItem label={"Lastmodiefied Date::"} value={ lastModifiedDate }/>
                        </InfoGridPanel>
                    </div>
                </div>
                <div className={`panel ${ this.props.orderStore.quoteitems.length < 1 ? "quote" : ""}`} id="order-items">
                    <List items={quoteitems} columns={columns} noDataText={"Quoted Items"} loadingItems={this.props.mainStore.progress.loadingItems}/>
                </div>
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(RequestForQuote));

import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

// Style
import './Details.css';

// Components
import List from './List/List';

function MTReportViewer(props) {


    useEffect(() => {
        let token = props.authStore.token;
        if (token) {
            //Viewing pdfs are not allowed in the app at the moment
            //this.props.orderStore.fetchMTRDownload({internalId:this.props.mtr_id, fileName: this.props.mtr_filename },'view');
        } else {
            window.location.href = '/login';
        }
    },[props.authStore.token])

    const goBack = () => {
        props.back()
        //reset pdf link source
        props.orderStore.resetLinkSource()
    }

    const { tranId, mtr_id, type, mtr_filename } = props

    return (
        <section className="box mtr-report-view">
            <List order={{ tranId, internalId:mtr_id, type, fileName: mtr_filename }} back={goBack}/>
        </section>
    )

}

export default inject('orderStore', 'inventoryStore', 'mainStore', 'cartStore', 'authStore')(observer((props) => <MTReportViewer {...props}/>));


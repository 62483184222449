// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container{
    overflow-x: auto;
}
.ReactTable {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-width: 100%;
    overflow: auto;
    width: fit-content;
}
.ReactTable thead {
    /* head takes the height it requires, 
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100% - 0.25rem);
}
.ReactTable tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow: visible;
    overflow-y: scroll;
}
.ReactTable tbody tr {
    width: 100%;
}
.ReactTable thead, table tbody tr {
    display: table;
    table-layout: fixed;
}


/* THEAD */
.ReactTable thead{
    border-bottom: 1px solid #dadbdd;
}
.ReactTable th{
    border: 0;
    font-size: 12px;
    padding: 10px 10px;
    text-transform: uppercase;
    text-align: left;
    /* min-width: 150px; */
}

/* TBODY */
.ReactTable{

}
.ReactTable tr:nth-child(2n){
    background-color: #efeff0;
}
.ReactTable tr td{
    border: 0;
    font-size: 12px;
    padding: 20px 10px !important;
    /* min-width: 150px; */
}
.rt-th div {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ReactTable/react-table.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;AACA;IACI;+CAC2C;IAC3C,cAAc;IACd,2BAA2B;AAC/B;AACA;IACI,iDAAiD;IACjD,cAAc;IACd,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;;;AAGA,UAAU;AACV;IACI,gCAAgC;AACpC;AACA;IACI,SAAS;IACT,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA,UAAU;AACV;;AAEA;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,SAAS;IACT,eAAe;IACf,6BAA6B;IAC7B,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB","sourcesContent":[".table-container{\n    overflow-x: auto;\n}\n.ReactTable {\n    display: flex;\n    flex-flow: column;\n    height: 100%;\n    min-width: 100%;\n    overflow: auto;\n    width: fit-content;\n}\n.ReactTable thead {\n    /* head takes the height it requires, \n    and it's not scaled when table is resized */\n    flex: 0 0 auto;\n    width: calc(100% - 0.25rem);\n}\n.ReactTable tbody {\n    /* body takes all the remaining available space */\n    flex: 1 1 auto;\n    display: block;\n    overflow: visible;\n    overflow-y: scroll;\n}\n.ReactTable tbody tr {\n    width: 100%;\n}\n.ReactTable thead, table tbody tr {\n    display: table;\n    table-layout: fixed;\n}\n\n\n/* THEAD */\n.ReactTable thead{\n    border-bottom: 1px solid #dadbdd;\n}\n.ReactTable th{\n    border: 0;\n    font-size: 12px;\n    padding: 10px 10px;\n    text-transform: uppercase;\n    text-align: left;\n    /* min-width: 150px; */\n}\n\n/* TBODY */\n.ReactTable{\n\n}\n.ReactTable tr:nth-child(2n){\n    background-color: #efeff0;\n}\n.ReactTable tr td{\n    border: 0;\n    font-size: 12px;\n    padding: 20px 10px !important;\n    /* min-width: 150px; */\n}\n.rt-th div {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

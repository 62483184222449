import React, { Component } from 'react';
import { inject } from 'mobx-react';

// Plugins
import moment from 'moment';

class Filters extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tranId: null,
            otherRefNum: null,
            status: 'all',
            tranDateBegin: moment().add(-1, 'months').format('YYYY-MM-DD'),
            tranDateEnd: moment().format('YYYY-MM-DD'),
            tranIDs: null,
            disableTranIdField: false,
            disablePONoField: false
        };
    }

    componentDidMount() {
        let filter = this.props.orderStore.filter;
        if (filter) {
            this.setState({ 
                tranId: filter.tranId, 
                otherRefNum: filter.otherRefNum, 
                status: filter.status, 
                tranDateBegin: filter.tranDateBegin,
                tranDateEnd: filter.tranDateEnd
            }, () => {
                this.props.filter(filter);
            });
        } else {
            this.props.filter({
                tranId: this.state.tranId,
                otherRefNum: this.state.otherRefNum,
                status: this.state.status === 'all' ? "" : this.state.status,
                tranDateBegin: this.state.tranDateBegin,
                tranDateEnd: this.state.tranDateEnd
            });
        }
    }

    handleFilter = (e) => {
        e.preventDefault();
        const tranId = this.state.tranId === '' ? null : this.state.tranId;
        const otherRefNum = this.state.otherRefNum === '' ? null : this.state.otherRefNum;
        const status = this.state.status === 'all' ? "" : this.state.status;
        const tranDateBegin = this.state.tranDateBegin === '' ? null : this.state.tranDateBegin;
        const tranDateEnd = this.state.tranDateEnd === '' ? null : this.state.tranDateEnd;

        let filter = { tranId, otherRefNum, status, tranDateBegin, tranDateEnd }
        this.props.filter(filter);
    }

    handleTranIdChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ tranId: value.trim(), disablePONoField: true });
        }
        if(value === "" || value === " ") {
            this.setState({ disableTranIdField: false, disablePONoField: false });
        }
    }

    handlePoNumberChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ otherRefNum: value.trim(), disableTranIdField: true });
        }
        if(value === "" || value === " ") {
            this.setState({ disableTranIdField: false, disablePONoField: false });
        }
    }

    handleStatusChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ status: value });
        }
    }

    handleDateBeginChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ tranDateBegin: value });
        }
    }

    handleDateEndChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ tranDateEnd: value });
        }
    }

    render() {
        const { tranId, otherRefNum, status, tranDateBegin, tranDateEnd, disableTranIdField, disablePONoField } = this.state;
        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    <form onSubmit={ this.handleFilter }>
                        <div className="flex flex-row">
                            <div className="flex-1 flex-2-md">
                                <input ref="tranId" className={`no-margin ${ disableTranIdField ? 'disable' : ''}`} type="text" placeholder="Sales Order ID" value={ tranId ? tranId : '' } onChange={ this.handleTranIdChange } disabled={disableTranIdField}/>
                            </div>
                            <div className="flex-1 flex-2-md">
                                <input ref="otherRefNum" className={`no-margin ${ disablePONoField ? 'disable' : ''}`} type="text" placeholder="My PO" value={ otherRefNum ? otherRefNum : '' } onChange={ this.handlePoNumberChange } disabled={disablePONoField}/>
                            </div>
                            <div className="flex-1 flex-2-md">
                                <select ref="status" className="no-margin" value={ status || "" } onChange={ this.handleStatusChange } >
                                    <option value="all">All Status</option>
                                    <option value="SalesOrd:A">Pending Approval</option>
                                    <option value="SalesOrd:B">Pending Fulfillment</option>
                                    <option value="SalesOrd:C">Cancelled</option>
                                    <option value="SalesOrd:D">Partially Fulfilled</option>
                                    <option value="SalesOrd:E">Pending Billing/Partially Fulfilled</option>
                                    <option value="SalesOrd:F">Pending Billing</option>
                                    <option value="SalesOrd:G">Billed</option>
                                    <option value="SalesOrd:H">Closed</option>
                                </select>
                            </div>
                            <div className="flex-1 flex-2-md">
                                <input className="no-margin" type="date" id="tranDateBegin" name="tranDateBegin" value={ tranDateBegin ? tranDateBegin : '' } onChange={ this.handleDateBeginChange } />
                            </div>
                            <div className="flex-1 flex-2-md">
                                <input className="no-margin" type="date" id="tranDateEnd" name="tranDateEnd" value={ tranDateEnd ? tranDateEnd : '' } onChange={ this.handleDateEndChange } />
                            </div>
                            <div className="flex-1 flex-2-md">
                                <button className="button button--block no-margin" type="submit">Filter</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default inject('orderStore')(Filters);

import React, { useState, useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import { inject, observer } from 'mobx-react';

function NewFilters(props) {

    const [ width, setWidth ] = useState("Any");
    const [ thickness, setThickness ] = useState("Any");
    const [ length, setLength ] = useState("Any");
    const [ availabilityList, setAvailabilityList ] = useState("Any");
    const [ selected, setSelected ] = useState([]);
    const [ location, setLocation ] = useState([])
    const [ gauge, setGauge ] = useState("Any");
    const [ surface, setSurface ] = useState("Any");

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (props.inventoryStore) {
            const { clearInventoryFilters } = props.inventoryStore
            if(clearInventoryFilters) {
                props.filter({
                    itemGroup: [],
                    width: "Any",
                    thickness: "Any",
                    length: "Any",
                    availabilityList: "Any",
                    location: [],
                    gauge: "Any",
                    surface: "Any"
                });
                setSelected([]); // Commenting this out.
                setWidth("Any");
                setThickness("Any");
                setLength("Any");
                setAvailabilityList("Any");
                setSurface("Any")
                setGauge("Any")
                setLocation([]);
                props.inventoryStore.clearFilters(false)
            }
        }
    }); // Remove props from the dependency array which was causing infinite loop.

    const handleFilter = (e) => {
        e.preventDefault();
        let filterWidth = width === 'Any' ? "" : width;
        let filterThickness = thickness === 'Any' ? "" : thickness;
        let filterLength = length === 'Any' ? "" : length;
        let filterItemGroup = selected === 'Any' ? "" : selected;
        let filterAvailability = availabilityList === 'Any' ? "" : availabilityList;
        let filterLocation = location === 'Any' ? "" : location;

        let filterSurface = surface === 'Any' ? "" : surface;
        let filterGauge = gauge === 'Any' ? "" : gauge;

        let filter = { filterWidth, filterThickness, filterLength, filterItemGroup, filterAvailability, filterLocation, filterSurface, filterGauge }
        props.filter(filter);
    }

    const handleProductGroupChange = (value) => {
        if (value !== undefined) {
            setSelected(value);
            props.filter({
                itemGroup: value,
                width: width,
                thickness: thickness,
                length: length,
                availability: availabilityList,
                location: location,
                surface: surface,
                gauge: gauge
            })
        }
    }

    const handleWidthChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setWidth(value);
            props.filter({
                itemGroup: selected,
                width: value,
                thickness: thickness,
                length: length,
                availability: availabilityList,
                location: location,
                surface: surface,
                gauge: gauge
            })
        }
    }

    const handleThicknessChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setThickness(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: value,
                length: length,
                availability: availabilityList,
                location: location,
                surface: surface,
                gauge: gauge
            })
        }
    }
    const handleLengthChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setLength(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: thickness,
                length: value,
                availability: availabilityList,
                location: location,
                surface: surface,
                gauge: gauge
            })
        }
    }
    const handleAvailabilityChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setAvailabilityList(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: thickness,
                length: length,
                availability: value,
                location: location,
                surface: surface,
                gauge: gauge
            })
        }
    }

    const handleLocationChange = (value) => {
        if (value !== undefined) {
            setLocation(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: thickness,
                length: length,
                availability: availabilityList,
                location: value,
                surface: surface,
                gauge: gauge
            })
        }
    }

    const handleGaugeChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setGauge(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: thickness,
                length: length,
                availability: availabilityList,
                location: location,
                surface: surface,
                gauge: value
            })
        }
    }

    const handleSurfaceChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            setSurface(value);
            props.filter({
                itemGroup: selected,
                width: width,
                thickness: thickness,
                length: length,
                availability: availabilityList,
                location: location,
                surface: value,
                gauge: gauge
            })
        }
    }
    return (
        <div className="panel panel-default">
            <div className="panel-body">
                <form onSubmit={ handleFilter }>
                    <div className="flex flex-row inventory-summary">
                        <div className="flex-1 flex-2-md">
                            Filter By Product Group
                            <MultiSelect
                                options={props.itemGroups ? props.itemGroups.slice().sort() : []}
                                value={selected}
                                onChange={handleProductGroupChange}
                                labelledBy="Any"
                                hasSelectAll={true}
                                disableSearch={true}
                            />
                        </div>
                        {
                            props.thicknesses && props.showThickness &&
                            <div className="flex-1 flex-2-md">
                                Filter By Thickness/Gauge
                                <select className="no-margin" value={ thickness } onChange={ handleThicknessChange } >
                                    <option value="Any">Any</option>
                                    {
                                        props.thicknesses?.slice().sort().map((item,key) => <option key={`item-${key}-${item}`} value={item }> {item }</option>)
                                    }
                                </select>
                            </div>
                        }
                        {
                            props.widths && props.showWidths &&
                            <div className="flex-1 flex-2-md">
                                Filter By Width
                                <select className="no-margin" value={ width } onChange={ handleWidthChange } >
                                    <option value="Any">Any</option>
                                    {
                                        props.widths?.slice().sort().map((item,key) => <option key={`item-${key}-${item}`} value={item }> {item }</option>)
                                    }
                                </select>
                            </div>
                        }
                        {
                            props.lengths && 
                            <div className="flex-1 flex-2-md">
                                Filter By Length
                                <select className="no-margin" onChange={ handleLengthChange } >
                                    <option value="Any">Any</option>
                                    {
                                        props.lengths?.map((item,key) => <option key={`item-${key}-${item}`} value={ item ? item : ''  }> { item ? item : 'Coil' }</option>)
                                    }
                                </select>
                            </div>
                        }
                        {
                            props.surface && 
                            <div className="flex-1 flex-2-md">
                                Filter By Temper/Surface
                                <select className="no-margin" value={surface} onChange={ handleSurfaceChange } >
                                    <option value="Any">Any</option>
                                    {
                                        props.surface?.slice().sort().map((item,key) => <option key={`item-${key}-${item}`} value={item }> {item }</option>)
                                    }
                                </select>
                            </div>
                        }
                        {
                            props.gauge && 
                            <div className="flex-1 flex-2-md">
                                Filter By Gauge
                                <select className="no-margin" value={gauge} onChange={ handleGaugeChange } >
                                    <option value="Any">Any</option>
                                    {
                                        props.gauge?.map((item,key) => <option key={`item-${key}-${item}`} value={ item ? item : ''  }> { item ? item : 'Coil' }</option>)
                                    }
                                </select>
                            </div>
                        }
                        <div className="flex-1 flex-2-md">
                            Filter By Availability
                            <select className="no-margin" value={ availabilityList } onChange={ handleAvailabilityChange } >
                                <option value="Any">Any</option>
                                {
                                    props.availabilityList?.map((item,key) => <option key={`item-${key}-${item}`} value={ item ? item : ''  }> { item }</option>)
                                }
                            </select>
                        </div>
                        <div className="flex-1 flex-2-md">
                            Filter By Location
                            <MultiSelect
                                options={props.locations ? props.locations.slice().sort() : []}
                                value={location}
                                onChange={handleLocationChange}
                                labelledBy="Any"
                                hasSelectAll={true}
                                disableSearch={true}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default inject('orderStore','inventoryStore')(observer(NewFilters));

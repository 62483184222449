import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


import { Input, Radio, RadioTextArea, Select} from '../FormComponents';
import ItemForm from './ItemForm';
import "./ClaimForm.css";

class ClaimForm extends Component { 
    constructor(props){
        super(props)
        this.state = {
            basicValues: {},
            productType: [],
            defectType: [],
            defectLocation: [],
            items: [],

            showItemForm: false,
            payloadItems: [],
            itemValues: {},
            invoiceLots: this.props.invoiceLots || [],

            isItemsValid: true,
            requiredFields: ['description', 'email', 'name', 'phone','defect','claimtype'],
            errorList: [],
            isFormValid: true,
            isProductType: true,
            isDefectLocation: true,
            isDefectType: true,
            claimTypes: this.props.claimTypes || []
        }

        this.onChange = this.onChange.bind(this)
        this.onCheckProductType = this.onCheckProductType.bind(this)
        this.onCheckDefectType = this.onCheckDefectType.bind(this)
        this.onCheckDefectLocation = this.onCheckDefectLocation.bind(this)
        this.onCheckItem = this.onCheckItem.bind(this)
        this.add = this.add.bind(this)
        this.cancel = this.cancel.bind(this)
        
    }

    onChange(e){
        this.setState({ basicValues: { ...this.state.basicValues, [e.target.name]: e.target.value } })
    }
    onCheckProductType(e){
        let updatedList = [...this.state.productType];
        if (e.target.checked) {
            updatedList = [...this.state.productType, e.target.name];
        } else {
            updatedList.splice(this.state.productType.indexOf(e.target.name), 1);
        }
        this.setState({ productType: updatedList })
    }
    onCheckDefectType(e){
        let updatedList = [...this.state.defectType];
        if (e.target.checked) {
            updatedList = [...this.state.defectType, e.target.name];
        } else {
            updatedList.splice(this.state.defectType.indexOf(e.target.name), 1);
        }
        this.setState({ defectType: updatedList })
    }
    onCheckDefectLocation(e){
        let updatedList = [...this.state.defectLocation];
        if (e.target.checked) {
            updatedList = [...this.state.defectLocation, e.target.name];
        } else {
            updatedList.splice(this.state.defectLocation.indexOf(e.target.name), 1);
        }
        this.setState({ defectLocation: updatedList })
    }

    onCheckItem(e, lot){
        let updatedList = [...this.state.items];
        let newItems = [...this.state.payloadItems]
        if (e.target.checked) {
            updatedList = [...this.state.items, e.target.name];
            this.setState({ showItemForm: true, isItemsValid: true, itemValues: { ...this.state.itemValues, name:e.target.name, itemName: lot.itemName, lot: lot } })
        } else {
            updatedList.splice(this.state.items.indexOf(e.target.name), 1);
            newItems = this.state.payloadItems.filter((item) => item.lotNumber !== e.target.name)
        }
        this.setState({ items: updatedList, payloadItems: newItems })
    }
    goBack(){
        this.props.hide()
    }
    showPreview(){
        const { items, requiredFields, basicValues, productType, defectLocation } = this.state
        let errorList = [...requiredFields]
        for(let key in basicValues){
            if(requiredFields.includes(key) && basicValues[key] === ''){
                // errorList.splice(errorList.indexOf(key),1)
            } else {
                errorList.splice(errorList.indexOf(key),1)
            }
        }
        this.setState({ errorList: errorList})
        if(errorList.length > 0){
            return;
        }

        if(items.length < 1){
            this.setState({ isItemsValid: false })
            return
        }
        
        if(defectLocation.length < 1) {
            this.setState({ isDefectLocation: false })
            return
        }
        if(defectLocation.length > 0) {
            this.setState({ isDefectLocation: true })
        }

        if(productType.length < 1) {
            this.setState({ isProductType: false })
            return
        }
        if(productType.length > 0) {
            this.setState({ isProductType: true })
        }

        this.props.showPreview(this.state)
    }

    add(itemData){
        let f = [...this.state.payloadItems, itemData]
        this.setState({ showItemForm: false,  payloadItems: f})
    }

    cancel(itemData){
        let updatedList = [...this.state.items]
        updatedList.splice(this.state.items.indexOf(itemData.name), 1)

        this.setState({ showItemForm: false, items: updatedList })
    }

    render(){
        const { invoiceLots, showItemForm, itemValues, isItemsValid, errorList, isDefectLocation, isProductType, claimTypes } = this.state
        return (
            <div className='panel claim'>
                <h3 className="flex" onClick={ (event) => this.goBack(event) }>Back</h3>
                <div className='claimform'>
                    <div className='request-info'>
                        <div className='heading'>Claim Request</div>
                        <p>Welcome to AA Metals Return Authorization request form. Please submit your claims here. Thank you.</p>
                    </div>
                    <div className='contact-info'>
                        <div className='heading'>Contact Information</div>
                        <div className='contact-info-wrapper'>
                            <Input type={"text"} label={"Contact Name"} name={'name'}  onChange={this.onChange} className={errorList.includes('name') ? 'error' : ''}/>
                            <Input type={"text"}  label={"Contact Phone"} name={'phone'} onChange={this.onChange} className={errorList.includes('phone') ? 'error' : ''}/>
                            <Input type={"email"} label={"Email"} name={'email'} onChange={this.onChange} className={errorList.includes('email') ? 'error' : ''}/>
                        </div>
                    </div>
                    <div className='rejected-material-info'>
                        <div className='heading'>Material Rejected</div> 
                        <p className='sub-heading'>Choose Items (Minimum 1)</p>
                        <div className={`items`} style={{ color: isItemsValid ? '' : `red`}}>
                            {
                                invoiceLots.map((lot,key) => <Input key={`items-${key}`} checked={this.state.items.includes(lot.lotNumber)} type={"checkbox"} label={lot.lotNumber} name={lot.lotNumber} value={lot.lotNumber} onChange={(e) => this.onCheckItem(e,lot)}/>)
                            }
                        </div>
                        {
                            showItemForm && <ItemForm add={this.add} cancel={this.cancel} data={itemValues}/>
                        }
                    </div>
                    <div className='detail-info'>
                        <div className='heading'>Details & Other Information</div>
                        <p className='sub-heading'>Defect Detected at</p>
                        <div className='defect'>
                            <Radio id="inbound" name="defect" value={"Inbound"} label={"Inbound (picture upon receiving needed, depicting defect or damage as well as a picture showing the full coil/skid and a picture of AAM tag) BOL must be provided and have notes with damage(s) and be signed by the driver"} onChange={this.onChange} className={errorList.includes('defect') ? 'error' : ''}/>
                            <Radio id="before" name="defect" value={"Before processing"} label={"Before processing (pictures needed Depicting defect or damage as well as a picture of full coil/skid and a picture of AAM tag)"} onChange={this.onChange} className={errorList.includes('defect') ? 'error' : ''}/>
                            <Radio id="during" name="defect" value={"During processing"} label={"During processing (Zoom in and zoom out on defect, provide photos of coils and or skids depicting current conditions and a picture of AAM tag)"} onChange={this.onChange} className={errorList.includes('defect') ? 'error' : ''} />
                            <RadioTextArea id="other" name={"defect"} value={"Other"} label="Other (describe)" onChange={this.onChange}  className={errorList.includes('defect') ? 'error' : ''}/>
                        </div>

                        <p className='sub-heading'>Claim Type</p>
                        <div className='defect-type'>
                            {/* <div className='form-groups'> */}
                                <Select data={claimTypes} name="claimtype" onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''}/>
                                {/* <Radio id="Surface" name="claimtype" value={"Surface Defects"} label={"Surface"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''}/>
                                <Radio id="Flatness" name="claimtype" value={"Flatness Issues"} label={"Flatness"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''}/>
                                <Radio id="Water Stains" name="claimtype" value={"Water Stains"} label={"Water Stains"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''} />
                                <Radio id="Surface" name="claimtype" value={"Surface"} label={"Surface"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''}/>
                                <Radio id="Flatness" name="claimtype" value={"Flatness"} label={"Flatness"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''}/>
                                <Radio id="Water Stains" name="claimtype" value={"Water Stains"} label={"Water Stains"} onChange={this.onChange} className={errorList.includes('claimtype') ? 'error' : ''} />


                                <Input type={"checkbox"} label={"Surface"} name={'Surface'} value={'Surface'}  onChange={this.onCheckDefectType} className={isDefectType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Flatness"} name={'Flatness'} value={'Flatness'} onChange={this.onCheckDefectType} className={isDefectType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Water Stains"} name={'Water Stains'} value={'Water Stains'} onChange={this.onCheckDefectType} className={isDefectType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Out of Tolerance"} name={'Out Of Tolerance'} value={'Out of Tolerance'} onChange={this.onCheckDefectType} className={isDefectType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Other (describe)"} name={'Other'} value={'other'} onChange={this.onCheckDefectType} className={isDefectType ? '' : 'error'}/> */}
                            {/* </div> */}

                            {/* <div className='form-group' id='other-form-group'>
                                <textarea name="defectTypeOther" cols="30" rows="5" placeholder='Placeholder Text' onChange={this.onChange} className={isDefectType ? '' : 'error'}></textarea>
                            </div> */}
                        </div>

                        <p className='sub-heading'>Location of Defect (Minimum 1)</p>
                        <div className='defect-location'>
                            <div className='form-groups'>
                                <Input type={"checkbox"} label={"Head"} name={'Head'} value={'Head'} onChange={this.onCheckDefectLocation} className={isDefectLocation ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Tail"} name={'Tail'} value={'Tail'} onChange={this.onCheckDefectLocation} className={isDefectLocation ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Throughout"} name={'Throughout'} value={'Throughout'} onChange={this.onCheckDefectLocation} className={isDefectLocation ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Repeating Pattern"} name={'Repeating Pattern'} value={'Repeating Pattern'} onChange={this.onCheckDefectLocation} className={isDefectLocation ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Other (describe)"} name={'Other'} value={'Other'} onChange={this.onCheckDefectLocation} className={isDefectLocation ? '' : 'error'}/>
                            </div>
                            <div className='form-group' id='other-form-group'>
                                <textarea name="defectLocationOther" cols="30" rows="5" placeholder='Placeholder Text' onChange={this.onChange} className={isDefectLocation ? '' : 'error'}></textarea>
                            </div>
                        </div>

                        <p className='sub-heading'>Type of Product (Minimum 1)</p>
                        <div className='product-type'>
                            <div className='form-groups'>
                                <Input type={"checkbox"} label={"Coil"} name={'Coil'} value={"Coil"} onChange={this.onCheckProductType} className={isProductType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Sheet"} name={'Sheet'} value={"Sheet"} onChange={this.onCheckProductType} className={isProductType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Extrusion"} name={'Extrusion'} value={"Extrusion"} onChange={this.onCheckProductType} className={isProductType ? '' : 'error'}/>
                                <Input type={"checkbox"} label={"Other (describe)"} name={'Other'} value={"Other"} onChange={this.onCheckProductType} className={isProductType ? '' : 'error'}/>
                            </div>
                            <div className='form-group' id='other-form-group'>
                                <textarea name="productTypeOther" cols="30" rows="5" placeholder='Placeholder Text' onChange={this.onChange} className={isProductType ? '' : 'error'}></textarea>
                            </div>
                        </div>

                        <div className='relevant-info'>
                            {/* <div className='form-group'>
                                <label>Attach Relevant Documents & Images</label>
                                <div className='upload-button'></div>
                            </div> */}
                            <Input type={"textarea"}  label={"Please provide a detailed explanation of the defect(s) claimed *"} name={'description'} onChange={this.onChange} className={errorList.includes('description') ? 'error' : ''}/>
                            <Input type={"textarea"}  label={"Other Comments"} name={'comments'} onChange={this.onChange}/>
                        </div>
                        <button className='preview-button' onClick={() => this.showPreview()}>continue to preview</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer(ClaimForm));
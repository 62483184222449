import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Address from './Address';

class Company extends Component {
    constructor(props){
        super(props)

        this.state = {
            showEdit: false,
            ...this.props.data
        }
    }

    handleChange = (e) => {
        this.setState({...this.state, [e.target.name]: e.target.value})
    }

    updateBoolValue() {
        this.setState({showEdit: !this.state.showEdit})
    }
    
    submitData (){
        
        setTimeout(()=>{
            this.setState({showEdit: !this.state.showEdit})
            // send updated into to the server
        },100)
    }
    
    render() {
        const { defaultAddress, salesRepData, name, email, phone, fax} = this.state
        const { customerShippingAddress } = this.props.authStore;

        const salesRep = JSON.parse(salesRepData)
                
        return (
            <div className='company-info'>
                <h2>COMPANY INFO</h2>
                <div className="panel" id="company">
                    {
                        process.env.REACT_APP_FEATURE_FLAG === "true" ?
                            <div className="info-actions">
                                { this.state.showEdit  && <button className="cancel-button" onClick={this.updateBoolValue.bind(this)}>Cancel</button> }
                                { !this.state.showEdit  && <button className="edit-button" onClick={this.updateBoolValue.bind(this)}>Edit</button> } 
                                { this.state.showEdit  && <button className="save-button" onClick={this.submitData.bind(this)}>Save</button> }
                            </div>
                            : ""
                    } 
                    <div className="info-details">
                        <div className="info-name">Company Name:</div> 
                        <div className="info-value">{ this.state.showEdit ? <input type="text" name="name" size={name.length} onChange={ this.handleChange} value={name || ''} className="edit-form" required /> : name}</div>
                    </div>

                    <div className="info-details">
                        <div className="info-name">Phone:</div> 
                        <div className="info-value">{ this.state.showEdit ? <input type="text" name="phone" size={phone && phone.length} onChange={ this.handleChange } value={phone || ''} className="edit-form" required /> : phone }</div>
                    </div>

                    <div className="info-details">
                        <div className="info-name">Fax:</div> 
                        <div className="info-value">{ this.state.showEdit ? <input type="text" name="fax" size={fax && fax.length} onChange={ this.handleChange } value={fax || ''} className="edit-form" required /> : fax }</div>
                    </div>

                    <div className="info-details">
                        <div className="info-name">Email:</div> 
                        <div className="info-value">{ this.state.showEdit ? <input type="email" name="email" size={email && email.length} onChange={ this.handleChange } value={email || ''} className="edit-form" required /> : (email).toLowerCase()}</div>
                    </div>
                </div>
                {
                    !this.state.showEdit && <div className="panel" id="company">
                                                <div className="info-details">
                                                    <div className="info-name">Sales Rep</div> 
                                                    <div className="info-value">{ salesRep?.salesRepEntityId }</div>
                                                </div> 
                                            </div>
                }
                {
                   !this.state.showEdit &&  <Address data={ customerShippingAddress } defaultAddress={ defaultAddress }/>
                }
            </div>
        );
    }
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer(Company));
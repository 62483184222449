// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-contianer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;
    padding-bottom: 2rem;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    background: #fff;
    height: 10em;
    padding:1em;
    margin:1em 0; 
      -webkit-transition: margin 0.5s ease,box-shadow 0.5s ease; /* Safari */
      transition: margin 0.5s ease,box-shadow 0.5s ease; 
    -moz-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);
    -webkit-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.20);

}
.card i {
    font-size:3.5em;
    color: var(--blue);
} 

.card div{
    width: calc(100% - 3.5em);
    display: block;
    text-align:right;
    color: var(--blue);
}
.card div span {
    font-size:1em;
    width: 100%;
    display: block;
}

.card div b {
    font-size:2.2em;
    width: 100%;
    padding-top:0px;
    margin-top:-0.2em;
    margin-bottom:-0.2em;
    display: block;
}

.card:hover{ 
    margin-top:0.5em;  
    -moz-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);
    -webkit-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);
    box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);
}`, "",{"version":3,"sources":["webpack://./src/components/Page/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2DAA2D;IAC3D,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,YAAY;MACV,yDAAyD,EAAE,WAAW;MACtE,iDAAiD;IACnD,6CAA6C;IAC7C,gDAAgD;IAChD,yCAAyC;;AAE7C;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,6CAA6C;IAC7C,gDAAgD;IAChD,wCAAwC;AAC5C","sourcesContent":[".cards-contianer{\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));\n    grid-gap: 1rem;\n    padding-bottom: 2rem;\n}\n\n.card {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    max-width: 400px;\n    background: #fff;\n    height: 10em;\n    padding:1em;\n    margin:1em 0; \n      -webkit-transition: margin 0.5s ease,box-shadow 0.5s ease; /* Safari */\n      transition: margin 0.5s ease,box-shadow 0.5s ease; \n    -moz-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);\n    -webkit-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);\n    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.20);\n\n}\n.card i {\n    font-size:3.5em;\n    color: var(--blue);\n} \n\n.card div{\n    width: calc(100% - 3.5em);\n    display: block;\n    text-align:right;\n    color: var(--blue);\n}\n.card div span {\n    font-size:1em;\n    width: 100%;\n    display: block;\n}\n\n.card div b {\n    font-size:2.2em;\n    width: 100%;\n    padding-top:0px;\n    margin-top:-0.2em;\n    margin-bottom:-0.2em;\n    display: block;\n}\n\n.card:hover{ \n    margin-top:0.5em;  \n    -moz-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);\n    -webkit-box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);\n    box-shadow:0 1px 6px rgba(0, 0, 0, 0.20);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

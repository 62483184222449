import React from 'react';

// Plugins
import ReactTable from '../../ReactTable/ReactTable';
import "./List.css";

const List = ({ data, columns,title, description, noDataText, type, rowSize }) => {

    return <div className="panel">
        {title ? <h2>{ title }</h2> : null }
        <h3>{ description }</h3>
        <ReactTable
            data={ data || [] }
            title={title}
            noDataText={`No ${ noDataText } found.`}
            columns={columns}
            pageSize={ type === 'inventory' ? rowSize : 10 } 
            className={"sticky-table"}
        />
    </div>
};

export default List;

import React from 'react';

// Plugins
import ReactTable from '../../../ReactTable/ReactTable';
import { observer } from 'mobx-react';

const List = ({ columns, data, title, description, noDataText, type, rowSize, isLoading}) => {

    return <div className="panel">
        { title ? <h2>{ title }</h2> : null }
        <h3>{ description }</h3>
        <ReactTable
            data={ data }
            noDataText={noDataText}
            columns={columns}
            pageSize={ type === 'inventory' ? rowSize : 10 } 
            className={"sticky-table"}
            isLoading={isLoading}
            defaultPageSize={ 10 } />
    </div>
};

export default (observer(List));

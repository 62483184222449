import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


import "./PurchaseOrderBoard.css";
import { Divider, Spacer } from '../FormComponents';
import SelectedLot from './SelectedLot';
import { UNITS } from '../Order/OrderDetails/Pages/NetSuiteStatusValues';
import LoadingSipnner from '../UI/LoadingSipnner';
import { currencyFormatter, mainStore } from '../../stores/MainStore';
import { cartStore } from '../../stores/CartStore';

class PurchaseOrderBoard extends Component { 
    constructor(props){
        super(props)

        this.state = {
            cartItems: [],
            isSubmitting: false,
            isSubmittingClass: "",
            message: ""
        }

        this.targetRef = React.createRef();
        this.observer = null;

        this.getToCheckout = this.getToCheckout.bind(this)
        this.handleIntersection = this.handleIntersection.bind(this)        
    }
    componentDidMount(){
        this.props.inventoryStore.setCartItemsFromStore()

        const options = {
            root: null, // use the viewport as the root
            rootMargin: '0px',
            threshold: 0.5 // how much of the target is visible before triggering
          };
      
          this.observer = new IntersectionObserver(this.handleIntersection, options);
          this.observer.observe(this.targetRef.current);
    }
    componentWillUnmount() {
        if (this.observer) {
          this.observer.disconnect();
        }
    }
    componentDidUpdate() {
        const checkoutBtn = document.getElementById("checkout-btn")
        const footer = document.getElementById("purchase-form-footer")
        let checkoutBtnStickyValue = checkoutBtn?.offsetTop;
        if (checkoutBtnStickyValue >= 623) {
            footer.classList.add("sticky");
        }
    }
    getToCheckout(e){
        e.preventDefault()
        this.setState({ isSubmitting: true })
        try {
            
            const { myCart } = this.props.cartStore
            const { currentCompanyID } = this.props.authStore;
    
            let filteredCart = myCart ? myCart.filter((element) => element.companyID === currentCompanyID) : [];
            if(filteredCart.length < 1) {
                mainStore.setError("You have an empty Purchase order");
                return
            };
            
            if(filteredCart.length > 0 && filteredCart[0].cartItems.length < 1) {
                return
            }
            const element = document.getElementById("purchase-order-form")
            element.style.right = "0";
            element.style.transition = "0.5s ease-in-out";
            this.props.inventoryStore.setCustomerShippingAddressList()
            this.props.inventoryStore.openCheckoutPage(true)
    
        } catch (error) {
            
        } finally {
            setTimeout(() => {
                this.setState({ isSubmitting: false })
            },2000)
        }
    }
    goBack(){
        const element = document.getElementById("purchase-order-board")
        element.style.right = "-1000px";
        element.style.transition = "0.5s ease-in-out";
    }
    remove(item){
        this.setState({ isSubmitting: true})
        this.props.cartStore.removeCartLineItem(item.ocCartLineItemID)
        .then(() => {

            //Set Cart in Local 
            this.props.inventoryStore.unSetCartItem(item.inventorynumber,'remove')

            //updated cart items
            const { cartItems } = this.props.inventoryStore;

            //close the cart when there are no items in it
            if(cartItems?.length < 1) {
                this.goBack()
            }
            this.setState({ isSubmitting: false, cartItems })
        })
    }

    async handleIntersection(entries){
        try {
            
            for(let i=0; i < entries.length; i++) {
    
              if (entries[i].isIntersecting) {
                // When the component enters the viewport
                this.setState({ 
                    isSubmitting: true, 
                    isSubmittingClass: 'isSubmitting', 
                    message: `Retrieving cart items. Please wait ...`
                })
    
                //get delivery rates
                await this.props.inventoryStore.getDeliveryRates();
    
                //get cart items and fresh prices
                await cartStore.getCartItems();
               
                //updated cart items
                const { cartItems } = this.props.inventoryStore;
    
                //apply customer default shipping zone prices
                const rates = toJS(this.props.inventoryStore.getDynamicRatesLocal(cartItems))
                //apply rates to state data
                let  stateData = rates ? this.props.inventoryStore.applyDynamicRates(cartItems, rates) : []
    
                this.setState({ 
                    cartItems: stateData,
                    isSubmitting: false, 
                    isSubmittingClass: '', 
                    message: '' 
                })
              } else {
                // When the component exits the viewport
              }
            };
        } catch (error) {
            this.setState({ 
                isSubmitting: false, 
                isSubmittingClass: '', 
                message: error
            })
        }
    };
    
    render(){
        const { cartItems } = this.props.inventoryStore;
        const { customerDefaultShippingAddress } = this.props.authStore
        const { isSubmitting, message, isSubmittingClass } = this.state;
        
        let liveCartItems = cartItems ? [...cartItems] : [];

        return (
            <div className={`panel purchase-order-board ${isSubmittingClass}`} id='purchase-order-board' ref={this.targetRef}>
                <div className='purchase-order-board-close'>
                    <h3  onClick={ (event) => this.goBack(event) }>&times;</h3>
                    <h2>PO Items</h2>
                </div>
                <div className='purchase-order-board-panel'>
                    {
                        liveCartItems.length < 1 ? "No lot items" : ''
                    }
                    {
                        liveCartItems.map((item,index) => (
                            <div key={`selected-lot-${index}`}>
                                <SelectedLot 
                                    description={`Item Desc: ${item.description}`}
                                    lotId={`Lot ID: ${item.inventorynumber}`}
                                    location = {`Location: ${item.location}`}
                                    weight={`${item.quantityavailable} ${UNITS[item.stockunit]}`}
                                    price={`${currencyFormatter((parseFloat(item.quantityavailable) * item?.rate ),'USD','en-US')}`}
                                    oldPrice={ item?.oldPrice}
                                    rate={ (parseFloat(item.rate)).toFixed(4) }
                                    remove={() => this.remove(item)}
                                    showRemove={true}
                                />
                                {item?.hasPriceChanged && <span className='lot-price-change'>The price of this lot has changed - your cart has been updated to reflect this change</span>}
                                <Spacer />
                                <Divider />
                            </div>
                        ))
                    }
                    { isSubmitting && <LoadingSipnner /> }
                    { !customerDefaultShippingAddress && <div style={{ position: 'absolute', top: '60%', right: '10%', left: '10%', color: "#283A8E", fontWeight: "500", textAlign: "center"}}>{ `You do not have a default shipping address. Please contact your sales rep.` }</div>}
                    { isSubmitting && <div style={{ position: 'absolute', top: '30%', right: '10%', left: '10%', color: "#283A8E", fontWeight: "500", textAlign: "center"}}>{ message }</div>}

                    <div className='purchase-form-footer' id='purchase-form-footer'>
                        <div className='property-group'>
                            <div className='properties' style={{ display: 'flex', justifyContent:'space-between'}}>
                                <div className='label'>Total weight</div><div className='value'>{ (liveCartItems.map((element) => parseFloat(element.quantityavailable)).reduce((a,b) => a + b, 0)/1000).toFixed(2) }k LB</div>
                            </div>
                            <div className='properties' style={{ display: 'flex', justifyContent:'space-between'}}>
                                <div className='label'>Total price</div><div className='value'>{ currencyFormatter(((liveCartItems.map((element) => parseFloat(element.quantityavailable) * element.rate).reduce((a,b) => a + b, 0))),'USD','en-US') }</div>
                            </div>
                        </div>
                        { <button className='button' id="checkout-btn" disabled={isSubmitting} onClick={this.getToCheckout}>{"Checkout"}</button> }
                    </div>
                </div>
            </div>
        )
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(PurchaseOrderBoard));
import { observable, action, makeObservable, toJS, runInAction } from 'mobx';
import clientPersist from 'client-persist';
import { makePersistable } from 'mobx-persist-store';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


// Plugins
import axios from 'axios';
import moment from 'moment';

// Stores
import { mainStore, waitFor } from './MainStore';
import { NetSuiteStatusValues } from '../components/Order/OrderDetails/Pages/NetSuiteStatusValues';
import { sendSentryEvent } from './Utils';
import { authStore } from './AuthStore';
import api from '../http';

// to use sessionStorage
clientPersist.setDriver(clientPersist.SESSIONSTORAGE);

class OrderStore {

    // Observable state
    hydrated = false;
    token = undefined;
    user = undefined;
    order = undefined;
    orders = [];
    recentOrders = undefined;
    items = [];
    relatedRecords = [];
    filter = undefined;
    quote = undefined;
    quoteitems = [];

    // Store tranId of a fetched transactions
    fetchedOrderId = undefined;
    fetchedQuoteTranId = undefined;
    fetchedRelatedRecordsTranId = undefined;
    fetchedInvoiceID = undefined;
    fetchedItemfulfillmentID = undefined;
    itemfulfillments = undefined;
    invoiceItems = [];
    itemfulfillmentItems = [];
    showRelatedRecords = false;
    showRFQ = false;
    backgroundLoader = true;
    transactions = undefined;
    invoices = [];
    itemships = [];
    billOfLading = undefined;
    currentItemShip = undefined;
    currentBillOfLading = undefined;
    currentInvoice= undefined;
    salesOrderLots= undefined;
    openQuotes = [];
    searchResults= undefined;
    claimResponse= undefined;
    salesOrderResponse= undefined;

    linkSource = undefined;
    isPendingRequest = false;

    userName = undefined

    constructor(){
        makeObservable(this, {
            // Observable state
            hydrated: observable,
            token: observable,
            user: observable,
            order: observable,
            orders: observable,
            recentOrders: observable,
            items: observable,
            relatedRecords: observable,
            filter: observable,
            quote: observable,
            quoteitems: observable,

            fetchedOrderId: observable,
            fetchedQuoteTranId: observable,
            fetchedRelatedRecordsTranId: observable,
            fetchedInvoiceID: observable,
            fetchedItemfulfillmentID: observable,
            itemfulfillments: observable,
            invoiceItems: observable,
            itemfulfillmentItems: observable,
            showRelatedRecords: observable,
            showRFQ: observable,
            backgroundLoader: observable,
            transactions: observable,
            invoices: observable,
            itemships: observable,
            billOfLading: observable,
            currentItemShip: observable,
            currentBillOfLading: observable,
            currentInvoice: observable,
            salesOrderLots: observable,
            openQuotes: observable,
            searchResults: observable,
            claimResponse: observable,
            salesOrderResponse: observable,

            linkSource: observable,
            isPendingRequest: observable,
            userName: observable,

            setHydrated: action,
            setToken: action,
            setUser: action,
            setLinkSource: action,
            setOrder: action,
            setQuoteItems: action,
            setInvoiceItems: action,
            setItemfulfillmentItems: action,
            setQuote: action,
            setRelatedRecords: action,
            resetItemshipsInvoices: action,
            setCurrentItemShip: action,
            setCurrentInvoice: action,
            setCurrentBillOfLading: action,
            setOpenQuotes: action,
            setIsPendingRequest: action,
            setBillOfLading: action,
            setSearchResults: action,
            setOrders: action,
            setRecentOrders: action,
            setSalesOrderResponse: action,
            setClaimResponse: action,
            forget: action,
            fetchOrders: action,
            pullOrders: action,
            fetchRecentOrders: action,
            pullRecentOrders: action,
            fetchOrder: action,
            pullOrder: action,
            fetchMTRDownload: action,
            fetchFile: action,
            // fetchFileById: action,
            downloadBlob: action,
            downloadPDF: action,
            fetchRelatedRecords: action,
            pullRelatedRecords: action,
            fetchQuote: action,
            pullQuote: action,
            fetchQuoteItems: action,
            pullQuoteItems: action,
            fetchRelatedItems: action,
            pullRelatedItems: action,
            fetchOpenQuotes: action,
            pullOpenQuotes: action,
            fetchBillOfLading: action,
            pullBillOfLading: action,
            search: action,
            pullSearchResults: action,
            submitClaim: action,
            submitSalesOrder: action,
            fetchQuoteBackground: action,
            pullQuoteBackground: action,
            fetchQuoteItemsBackground: action,
            pullQuoteItemsBackground: action,
            fetchItemfulfillmentBackground: action,
            pullItemfulfillmentBackground: action,
            fetchRelatedRecordsBackground: action,
            pullRelatedRecordsBackground: action,
            resetRecentViewedOrder: action
        });

        makePersistable(this, { name: 'orderStore', properties: ['token', 'user', 'order', 'currentItemShip','salesOrderLots','invoiceItems','currentInvoice', 'currentBillOfLading', 'billOfLading', 'userName', 'invoices', 'itemships'], storage: window.localStorage });

    }

    setHydrated(hydrated) {
        this.hydrated = hydrated;
    }

    setToken(token) {
        this.token = token;
    }

    setUser(user) {
        this.user = user;
    }
    setUserName(userName) {
        this.userName = userName
    }

    setLinkSource(linkSource, type='default') {
        if(type === 'mtr'){
            this.linkSource = linkSource;
        }else {
            this.linkSource = `data:application/pdf;base64,${linkSource}`;
        }
    }
    resetLinkSource() {
        this.linkSource = undefined;
    }
    setOrder(order,tranId) {
        if(!order.approveCheckBox && order.status === 'Pending Fulfillment') {
            order.status = 'Pending Approval'
        }
        this.order = order;
        this.fetchedOrderId = tranId;
        this.salesOrderLots = order.lots;
    }
    setQuoteItems(items) {
        this.quoteitems = items;
        this.backgroundLoader = false;
    }
    setInvoiceItems(items,invoiceTranId, orderItems,orderId,orderLocationId) {
        // filter the real items from the items list
        orderItems && orderItems.map((orderItem) =>
        items && items.map((item) => {
                if(orderItem.internalId === item.item.id){
                    item.lots = orderItem.lots
                    item.lotValue = orderItem.lotValue
                    item.orderId = orderId
                    item.orderLocationId = orderLocationId
                }
                return null
            })
        )
        this.invoiceItems = items;
        this.fetchedInvoiceID = invoiceTranId;
    }
    setItemfulfillmentItems(items, id, orderItems,orderId,orderLocationId) {
        // filter the real items from the items list
        orderItems.map((orderItem) =>
        items && items.map((item) => {
                if(orderItem.internalId === item.item.id){
                    item.lots = orderItem.lots
                    item.lotValue = orderItem.lotValue
                    item.orderId = orderId
                    item.orderLocationId = orderLocationId
                }
                return null
            })
        )
        
        this.itemfulfillmentItems = items ? items : [];
        this.fetchedItemfulfillmentID = id;
    }
    setQuote(quote, tranId) {
        this.quote = quote;
        this.fetchedQuoteTranId = tranId;
        this.showRFQ = true;
    }
    setRelatedRecords(items, tranId) {
        items.forEach((item) => {
            item.forEach((item) => {
                if(item.abbrevtype === 'INV'){
                    this.invoices.push(item);
                }
                if(item.abbrevtype === 'ITEMSHIP'){
                    if(item.custbody_truck && item.custbody_stop){
                        item.bol = `${item.custbody_truck};${item.custbody_stop}`
                    } else {
                        item.bol = "";
                    }
                    this.itemships.push(item)
                }
            })
        })
        this.relatedRecords= items;
        this.fetchedRelatedRecordsTranId = tranId;
        this.showRelatedRecords = true;
        this.isPendingRequest = false;

        this.transactions =  { INV: this.invoices, ITEMSHIP: this.itemships };
    }

    setItemfulfillment(items, tranId) {
        this.itemfulfillments= items;
    }
    resetRelateRecords(){
        this.setShowRelatedRecords = false;
        this.setShowRFQ = false;
        this.setBackgroundLoader = true;
        this.setInvoices = [];
        this.setItemships  = [];
        this.setTransactions =  undefined;
    }
    resetItemshipsInvoices(){
        this.itemships  = [];
        this.invoices  = [];
    }
    setCurrentItemShip(tranid){
        let item = this.itemships.find((item) => item.tranid === tranid);
        this.currentItemShip = item;
    }
    setCurrentInvoice(tranid){
        let invoice = this.invoices.find((item) => item.tranid === tranid);
        this.currentInvoice = invoice;
    }
    setCurrentBillOfLading(tranId){
        this.currentBillOfLading = tranId;
    }
    setOpenQuotes(openQuotes){
        openQuotes.forEach((item) => {
            item.entitystatusname = NetSuiteStatusValues[`CustomerStatus:${item.entitystatus}`] ;
            item.statusname = NetSuiteStatusValues[`Estimate:${item.status}`] ;
        })
        this.openQuotes = openQuotes;
    }
    setBillOfLading(bolData){
        this.billOfLading = bolData;
    }
    setIsPendingRequest(value){
        this.isPendingRequest = value;
    }

    setSearchResults(data){
        this.searchResults = data;
    }

    setOrders(orders, status){
        orders.forEach((element) => {
            if(!element.approveCheckBox && element.status === 'B') {
                element.statusName = 'Pending Approval' ;
            } else {
                const status = NetSuiteStatusValues[`SalesOrd:${element.status}`];
                if(status) {
                    element.statusName = status.split(':')[1];
                } else {
                    element.statusName = element.status;
                }
            }
        })
        runInAction(() => {
            this.orders = orders;
            this.isPendingRequest = status;
        })
    }
    setRecentOrders(orders){
        let orderData = orders || orders.items !== null ? orders.items : []
        orderData.forEach((element) => {
            if(element.custbody_approve_checkbox === "F" && element.status === 'B') {
                element.statusName = 'Pending Approval' ;
            } else {
                const status = NetSuiteStatusValues[`SalesOrd:${element.status}`];
                if(status) {
                    element.statusName = status.split(':')[1]
                } else {
                    element.statusName = element.status
                }
            }
            element.dueDate = element.custbody_due_date;
        })
        this.recentOrders = orderData;
    }
    setSalesOrderResponse(salesOrderRes){
        this.salesOrderResponse = salesOrderRes;
    }
    setClaimResponse(claimRes){
        this.claimResponse = claimRes;
    }
    forget() {
        this.hydrated = false;
        this.token = undefined;
        this.user = undefined;
        this.order = undefined;
        this.orders = [];
        this.invoiceItems = []
        this.filter = undefined;
        this.userName = undefined
        this.salesOrderLots = undefined
        this.currentInvoice = undefined
        this.currentBillOfLading = undefined
        this.invoices = []
        this.itemships = []
    }

    resetRecentViewedOrder() {
        this.currentInvoice = undefined;
        this.salesOrderLots = undefined;
        this.invoiceItems = [];
        this.order = undefined;
        this.currentItemShip = undefined;
        this.billOfLading = undefined;
        this.relatedRecords = [];
        this.fetchedOrderId = undefined;
        this.fetchedRelatedRecordsTranId = undefined;
        this.transactions = undefined;
        this.showRelatedRecords = false;
        this.invoices = [];
        this.itemships = [];
    }

    fetchOrders(filter) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullOrders(filter));
    }

    async pullOrders({ tranId, otherRefNum, status, tranDateBegin, tranDateEnd }) {
        this.filter = { tranId, otherRefNum, status, tranDateBegin, tranDateEnd };

        mainStore.setProgress({ loading: true }); //Pass this if fetching from background

        // Reset orders
        this.setOrders([], true)
        // Convert dates
        if (tranDateBegin) {
            tranDateBegin = moment(tranDateBegin).format('MM/DD/YYYY');
        }
        if (tranDateEnd) {
            tranDateEnd = moment(tranDateEnd).add(1,'day').format('MM/DD/YYYY');
        }

        //Prepare sentry transactional data
        const sentrySpan = sendSentryEvent('fetchOrders','http');
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;

        // Extract currentCompanyID from session
        const { currentCompanyID } = authStore;

        return await axios.get(`/api/salesorder/orders?userId=${ currentCompanyID }&tranId=${ tranId ? tranId : '' }&otherRefNum=${ otherRefNum ? otherRefNum : '' }&status=${ status ? status : '' }&tranDateBegin=${ tranDateBegin ? tranDateBegin : '' }&tranDateEnd=${ tranDateEnd ? tranDateEnd : '' }`)
            .then(action((orders) => {
                let data = orders.data ? orders.data : [];
                data.sort((a, b) => {
                    let nameA = a.createdDate; // ignore upper and lowercase
                    let nameB = b.createdDate; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return 1;
                    }
                    if (nameA > nameB) {
                        return -1;
                    }
                    // names must be equal
                    return 0;
                });
                this.setOrders(toJS(data), false)

                sentrySpan.finish();
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchRecentOrders(companyId) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullRecentOrders(companyId));
    }

    pullRecentOrders(companyId) {
        mainStore.setProgress({ loading: true }); //Pass this if fetching from background

        //Prepare sentry transactional data
        const sentrySpan = sendSentryEvent('fetchRecentOrders','http');
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/salesorder/getSalesOrders?entityId=${ companyId }`)
            .then(action((orders) => {
                let data = orders.data;
                this.setRecentOrders(data, false)

                sentrySpan.finish();
                // Reset order detail
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchOrder(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullOrder(order));
    }

    pullOrder(orderData) {
        // Loader start
        mainStore.setProgress({ loading: true, message:"" });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;

        // prepare sentry transaction
        const sentrySpan = sendSentryEvent(`fetchOrder:: ${orderData.tranId}`,'http');
        return axios.get(`/api/inventory/order?internalId=${ orderData.internalId }`)
            .then((order) => {
                this.setOrder(order.data,orderData.tranId)
                // end sentry
                sentrySpan.finish();

                // Loader end
                mainStore.setProgress({ loading: false });
            })
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }
    /**
     * Gets report from AWS
     * params: fileName
     * */
    fetchMTRDownload(order,mode='download', auth=true) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;

        let route='getFileUnauthenticated'
        if(auth) {
            route = 'getFile';
        }
        //Get file from AWS by using the fileName
        return axios.get( `api/file/${route}?fileName=${order.fileName}`, {responseType: 'blob'})
        .then(action((file) => {
            if(order.fileName && mode === 'download'){
                this.downloadBlob(file, order.fileName)
                mainStore.setProgress({ loading: false });
                return;
            }
            if(order.fileName && mode === 'view'){
                this.setLinkSource(file.data, 'mtr')
                mainStore.setProgress({ loading: false });
                return;
            }

            // Loader end
            mainStore.setProgress({ loading: false });
        }))
        .catch((error) => {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
            mainStore.setProgress({ loading: false });
        });
    }

    /**
     * Gets Report from NetSuite (SO, INV, BOL, RFQ)
     * params: internalId
     * */
    fetchFile(typeData,mode='download',type) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        let url = `api/inventory/getPDFReport?internalId=${ typeData.internalId }&type=${type}&stop=1`;
        if(typeData?.stopId) {
            url = `api/inventory/getPDFReport?internalId=${ typeData?.internalId }&type=${type}&stop=${typeData?.stopId}`
        }
        return axios.get(url)
            .then(action((file) => {
                if(file.data.error){
                    mainStore.setError(file.data.error);
                } else {
                    if(mode === 'download'){
                        this.downloadPDF(file.data, typeData.tranId);
                    } else {
                        this.setLinkSource(file.data);
                    }
                }
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }
    fetchPacketZipFile(typeData,mode='download',type) {

        // send SO, INV, BOL, IF, MTR
        let payload = { invoices:[], itemships: [], saleorder: [], mtr:[]}

        // prepare invoices
        this.invoices.forEach((invoice) => {
            payload.invoices.push({ internalId: invoice.id, tranId: invoice.tranid })
        });

        //prepare itemships
        this.itemships.forEach((itemship) => {
            payload.itemships.push({ truckId: (itemship.bol).split(";")[0], stopId: (itemship.bol).split(";")[1] })
        });

        //prepare sales order
        payload.saleorder.push({ internalId: typeData.internalId, tranId: typeData.tranId })

        //prepare all MTR file names
        for (const [key, value] of Object.entries(this.salesOrderLots)) {
            console.log(key, value)
            if(!payload.mtr.includes(value.mtr_name) && value.mtr_name) {
                payload.mtr.push(value.mtr_name)
            }
        }
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        let url = `api/file/getPacketZipFile`;
        return axios.post(url,payload,{ headers: { 'Content-Type': 'application/json' } })
            .then(action((file) => {
                if(file.data.error){
                    mainStore.setError(file.data.error);
                } else {
                    if(mode === 'download'){
                        this.downloadZipFile(file.data, typeData.tranId)
                    } else {
                        this.setLinkSource(file.data);
                    }
                }
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    //function to download blob
    downloadBlob(file, fileName) {
        console.log(file)
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        const blobFile = new Blob([file.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    //function to download PDF
    downloadFiles(listOfFiles=[]) {
        listOfFiles.forEach((file) => {
            if(file.isMTR) {
                // Convert the string to a Blob
                // const contentType = 'application/pdf';
                // const blob = this.stringToBlob(file.fileData, contentType);

                // // Create a URL for the Blob
                // const blobUrl = URL.createObjectURL(blob);

                // // Create a link element
                // const link = document.createElement('a');
                // link.href = blobUrl;
                // link.download = file.fileName;
                // link.click();
                
            } else {
                const linkSource = `data:application/pdf;base64,${file.fileData}`;
                const downloadLink = document.createElement("a");
                const filename = `${file.fileName}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = filename;
                downloadLink.click();
            }
        })
    }

    //function to download PDF
    downloadPDF(fileData, fileName) {
        const linkSource = `data:application/pdf;base64,${fileData}`;
        const downloadLink = document.createElement("a");
        const filename = `${fileName}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = filename;
        downloadLink.click();
    }

    //function to create and download zip
    downloadZipFile = async (pdfFilesData, fileName) => {
        //instantiate zip library
        const zip = new JSZip();

        //declare the mime type
        const mimeType = 'application/pdf';
    
        // Add each PDF file to the zip file
        const fetchAndAddToZip = async (fileData) => {
            //convert each base64 content to Blob
            let blob = ""; //this.base64ToBlob(fileData.fileData, mimeType);
            if(fileData.isMTR) {
                blob = fileData.fileData;
            } else {
                blob = this.base64ToBlob(fileData.fileData, mimeType);
            }
            zip.file(`${fileData.fileName}.pdf`, blob);
        };
    
        // Wait for all files to be added to the zip file
        await Promise.all(pdfFilesData.map(fileData => fetchAndAddToZip(fileData)));
    
        // Generate the ZIP file and trigger the download
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, `${fileName}-packet.zip`);
    };

    //Utility to help convert base64 content to blob
    base64ToBlob(base64, mime) {
        const byteChars = atob(base64);
        const byteNumbers = new Array(byteChars.length);
      
        for (let i = 0; i < byteChars.length; i++) {
          byteNumbers[i] = byteChars.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mime });
    }

    stringToBlob(str, contentType = '') {
        const byteArrays = [];
        const byteCharacters = str;

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    

    fetchRelatedRecords(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullRelatedRecords(order));
    }

    pullRelatedRecords(order) {
        console.log("non background task")
        // Loader start
        mainStore.setProgress({ loading: true, message: "Loading Related Records, Please wait..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/relatedRecords?orderId=${ order.internalId }`)
            .then(action((records) => {
                this.setRelatedRecords(records.data,order.tranId)
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchQuote(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullQuote(order));
    }

    pullQuote(order) {
        // Loader start
        mainStore.setProgress({ loading: true, message:"Loading Quote, Please wait ..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/quote?quoteId=${order.quoteID.internalId}`)
            .then(action((quote) => {
                this.setQuote(quote.data, order.tranId)

                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchQuoteItems(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullQuoteItems(order.quoteID.internalId));
    }

    pullQuoteItems(quoteId) {
        // Loader start
        mainStore.setProgress({ loadingItems: true, message:"Loading Items from RFQ, Please wait ..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/quoteitems?quoteId=${quoteId}`)
            .then(action((order) => {
                this.setQuoteItems(order.data)
                // Loader end
                mainStore.setProgress({ loadingItems: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loadingItems: false });
            });
    }

    fetchRelatedItems(path) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullRelatedItems(path));
    }

    pullRelatedItems(path) {
        // Loader start
        mainStore.setProgress({ loadingItems: true, message:"Loading Related Items, Please wait ..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/getRelatedItems?path=record/v1/${path}`)
            .then(action((related) => {
                let id = path.split('/')[1];
                if(path.includes('invoice')){
                    this.setInvoiceItems(related.data,id,this.order.items,this.order.internalId,this.order.locationId);
                }
                if(path.includes('itemfulfillment')){
                    this.setItemfulfillmentItems(related.data, id, this.order.items, this.order.iternalId, this.order.locationId);
                }
                // Loader end
                mainStore.setProgress({ loadingItems: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loadingItems: false });
            });
    }

    fetchOpenQuotes(userId) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullOpenQuotes(userId));
    }

    pullOpenQuotes(userId) {
        // Loader start
        mainStore.setProgress({ loading: true, message:"Loading Opened Quotes, Please wait ..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/getOpenQuotes?userId=${userId}`)
            .then(action((openQuotes) => {
                this.setOpenQuotes(openQuotes.data.items)
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message || `${error.message}:: Request has been aborted due to malicious code`);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchBillOfLading(truckId, stopId) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullBillOfLading(truckId, stopId));
    }

    pullBillOfLading(truckId, stopId) {
        // Loader start
        mainStore.setProgress({ loadingBillOfLading: true, message:"Loading Bill of Lading, Please wait ..." });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/getBOLData?truckId=${truckId}&stopId=${stopId}`)
            .then(action((bolData) => {
                this.setBillOfLading({...bolData.data, stopId })
                // Loader end
                mainStore.setProgress({ loadingBillOfLading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loadingBillOfLading: false });
            });
    }

    search(id,type) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullSearchResults(id,type));
    }

    pullSearchResults(id,type) {
        this.setSearchResults(undefined)
        // Loader start
        mainStore.setProgress({ loading: true, message:`Searching the records for ${id}, Please wait ...` });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        const { currentCompanyID, userCompanies } = authStore;

        let lotNo = ""
        let heatNo = ""
        if(type === "lot"){
            lotNo = id
        }
        if(type === "heat"){
            heatNo = id
        }

        let apiUrl = "";
        if(type === "lot" || type === "heat") {
            apiUrl = `inventory/getLotMTRID?type=mtr_id_from_lots_saved_search&lotNumber=${lotNo}&heatNumber=${heatNo}&entity=${currentCompanyID}`
        }
        if(type === "inv") {
            apiUrl = `search/searchINV?id=${id}&type=${type}&entity=${currentCompanyID}`
        }
        if(type === "bol") {
            apiUrl = `search/searchBOL?id=${id}&type=${type}&entity=${currentCompanyID}`
        }
        return axios.get(`/api/${apiUrl}`)
            .then(action((response) => {
                const { data } = response;
                if(data.error){
                    let company = userCompanies.find((element) => element.companyId === currentCompanyID);
                    let message = `${data.message} ${company.companyName}.`
                    mainStore.setError(message);
                    this.setSearchResults({ error: "true", message })
                } else {
                    this.setSearchResults(data)
                }
                // Loader end
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                let message = data.message;
                if(data.Message){
                    message = data.Message
                }
                mainStore.setError(message);
                mainStore.setProgress({ loading: false });
            });
    }

    submitClaim(payload) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post(`/api/inventory/claims`,payload,{ headers: { 'Content-Type': 'application/json' } })
            .then(action((response) => {
                const { data } = response
                this.setClaimResponse(data)
                mainStore.setProgress({ loading: false });
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    submitSalesOrder(payload) {
        // prepare sentry transaction
        const sentrySpan = sendSentryEvent(`submitPurchaseOrder::`,'http');
        const { email, ipAddress } = this.user ;
        payload['userInfoString'] = "Sales order created via the web portal by " + this.userName + " (" + email + ") at the following time: " + new Date() + ". IP address: " + ipAddress;

        //Add auth header to axio
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post(`/api/salesorder/createSalesOrderRESTLet`,payload,{ headers: { 'Content-Type': 'application/json' } })
            .then(action((response) => {
                const { data } = response

                //end sentry task
                sentrySpan.finish();

                this.setSalesOrderResponse(data)
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
            });
    }
    // Background Services
    fetchQuoteBackground(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullQuoteBackground(order));
    }

    pullQuoteBackground(order) {
        // Loader start
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/quote?quoteId=${order.quoteID.internalId}`)
            .then(action((quote) => {
                this.setQuote(quote.data, order.tranId);
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchQuoteItemsBackground(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullQuoteItemsBackground(order.quoteID.internalId));
    }

    pullQuoteItemsBackground(quoteId) {
        // Loader start
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/quoteitems?quoteId=${quoteId}`)
            .then(action((order) => {
                this.setQuoteItems(order.data);
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchRelatedRecordsBackground(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullRelatedRecordsBackground(order));
    }

    pullRelatedRecordsBackground(order) {
        console.log('background task')
        // Loader start
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/relatedRecords?orderId=${ order.internalId }`)
            .then(action((records) => {
                this.setRelatedRecords(records.data,order.tranId)
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message || `${error.message}:: Request has been aborted due to malicious code`);
            });
    }

    fetchItemfulfillmentBackground(order) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullItemfulfillmentBackground(order));
    }

    pullItemfulfillmentBackground(order) {
        // Loader start
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.get(`/api/inventory/orderItemfulfillment?tranId=${ order.tranId }`)
            .then(action((records) => {
                this.setItemfulfillment(records.data,order.tranId)
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
            });
    }

    async fetchPacketZipFileUnauthenticated(itemships, mode='download') {
        // send SO, INV, BOL, IF, MTR
        let payload = { invoices:[], itemships: [], saleorder: [], mtr:[]}

        //add itemships to payload
        payload.itemships = itemships

        const url = `api/file/getPacketZipFileUnauthenticated`;
        // Loader start
        mainStore.setProgress({ loading: true });

        try {
            const response =  await api.post(url,payload,{ headers: { 'Content-Type': 'application/json' } })
            if(response.data.error){
                mainStore.setError(response.data.error);
            } else {
                if(mode === 'download'){
                    this.downloadFiles(response.data)
                } else {
                    this.setLinkSource(response.data);
                }
            }
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
        } finally {
            mainStore.setProgress({ loading: false });
        }

    }

    async fetchMTRDownloadAsync(order,mode='download', auth=true) {
        // Loader start
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;

        let route='getFileUnauthenticated'
        if(auth) {
            route = 'getFile';
        }
        try {
            const response = await api.get( `api/file/${route}?fileName=${order.fileName}`, {responseType: 'blob'})
            if(order.fileName && mode === 'download'){
                this.downloadBlob(response, order.fileName)
                mainStore.setProgress({ loading: false });
                return;
            }
            if(order.fileName && mode === 'view'){
                this.setLinkSource(response.data, 'mtr')
                mainStore.setProgress({ loading: false });
                return;
            }
            
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
            mainStore.setProgress({ loading: false });
        }
    }

    async setPackageUpdate(ID, xpParams) {
        // Loader start
        mainStore.setProgress({ loading: true });
        try {
            await api.post('api/file/updatePackageProps',{ ID, XPParams: JSON.stringify(xpParams) });
        } catch (error) {
            let data = error.response && error.response.data ? error.response.data : error;
            mainStore.setError(data.message);
            mainStore.setProgress({ loading: false });
        }
    }

}

export const orderStore = new OrderStore();

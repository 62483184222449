import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Style
import './Details.css';

// Components
import List from './List/List';
import { useNavigate } from 'react-router-dom';

class Details extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMap: false,
            dataMap: undefined,
            distance: undefined
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            let order = this.props.orderStore.orders.find((order) => order.tranId === this.props.match.params.id);
            this.props.orderStore.fetchOrder(order);
        } else {
            window.location.href = '/login';
        }
    }

    goBack = (event) => {
        event.preventDefault();
        this.props.history(-1);
    }

    handleFile = (fileId) => {
        this.props.inventoryStore.fetchFile(fileId);
    }

    handleOpenMap = (locationId, shipAddress, distance) => {
        this.props.mainStore.setProgress({ loading: true });
        let origin = this.props.cartStore.allLocations.find((el) => { return el.internalId === locationId; });
        let destination = this.props.cartStore.user.addressList.find((el) => { return el.internalId === shipAddress; });
        let dataMap = [];
        this.props.cartStore.addressGeolocation(origin.addrText, (result) => {
            dataMap.push(result);
            this.props.cartStore.addressGeolocation(destination.addrText, (data) => {
                dataMap.push(data);
                this.setState({ showMap: true, dataMap, distance });
                this.props.mainStore.setProgress({ loading: false });
            });
        });
    }

    handleCloseMap = () => {
        this.setState({ showMap: false });
    }

    render() {
        const { goBack } = this;
        const { order, orders } = this.props.orderStore;
        let orderData = { type:'' }

        if(order){
            orderData = { ...order, type:'Order'}
        }
        return (
            <section className="box order-details">
                {
                    (orders.length > 0 && order) && (
                        <List order={ orderData } goBack={ goBack } handleFile={ this.handleFile } handleOpenMap={ this.handleOpenMap }  />
                    )
                }
            </section>
        );
    }
}

export default inject('orderStore', 'inventoryStore', 'mainStore', 'cartStore', 'authStore')(observer((props) => <Details history={useNavigate()} {...props}/>));

import './react-table.css'

import React from 'react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel
} from '@tanstack/react-table'
import SkeletonLoader from '../UI/SkeletonLoader'

const ReactTable = ({ columns, data, noDataText, pageSize, isLoading }) => {
  const [sorting, setSorting] = React.useState([]);

  const [{ pageIndex }, setPagination] =
    React.useState({
      pageIndex: 0,
      pageSize
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  )
  
  //fake data during loading and when length of loaded data is 0
  const generateFakeData = (size) => Array.from({ length: size }, (_, index) => index + 1);

  //declare final data to be presented to the table
  let finalData = [];
  //if data is being fetched from the server
  if(isLoading) {
    finalData = generateFakeData(3)
  }

  //if data has been fetched from the server but there is data
  if(!isLoading && data && data.length > 0) {
    finalData = data;
  }
  
  //if data has been fetched from the server but there is NO data
  if(!isLoading && data && data.length === 0) {
    finalData = generateFakeData(3)
  }
  const table = useReactTable({
    data: finalData,
    columns,
    state: {
      pagination,
      sorting
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    debugTable: true
  })

  // const rows = table.getRowModel();

  // Calculate the column widths based on the content of the longest cell
  // Do NOT remove this commented out function :: it might be very useful in the future
  // const calculatedColumnWidths = useMemo(() => {
  //   const columnWidths = {};
  //   table.getHeaderGroups().forEach((headerGroup) => {
  //     headerGroup.headers.forEach((column) => {
  //       if (rows?.rows) {
  //         const cellTexts = rows.rows?.map((row) =>
  //           String(row.getValue(column.id) || '').length
  //         );
  //         let maxWidth = Math.max(...cellTexts) * 8;
  //         //override to 100px
  //         let fixedColumns = ['checkAvailabilityWithID']
  //         if (fixedColumns.includes(column.id)) {
  //           maxWidth = 100
  //         }
  //         columnWidths[column.id] = maxWidth < 100 ? 100 : maxWidth > 250 ? 250 : maxWidth;
  //       }

  //     });
  //   });
  //   return columnWidths;
  // }, [table, rows.rows]);

  return (
    <div className="p-2">
      <div className="h-2" />
      <div className='table-container'>
        <table className='table ReactTable'>
          <thead className='rt-thead'>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className='rt-tr'>
                {headerGroup.headers?.map(header => {
                  return (
                    <th key={header.id} className='rt-th' colSpan={header.colSpan} style={{  width: header.getSize(), textAlign: 'center' }} >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none flex min-w-[36px]'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {{
                            asc: <span className="pl-2">↑</span>,
                            desc: <span className="pl-2">↓</span>
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className='rt-tbody'>
            {table.getRowModel().rows?.map(row => {
              return (
                <tr key={row.id} className='rt-tr'>
                  {row.getVisibleCells().map(cell => {
                    if(isLoading) return <td key={`loading-state-${cell.id}`}><SkeletonLoader height={5} borderRadius={10}/></td>
                    return (
                      <td key={cell.id} className='rt-td tdCALS' style={{ width: cell.column.getSize(), textAlign: 'left' }} >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {
          (isLoading === false || isLoading === undefined) &&
          data && 
          data.length === 0 && 
          <span 
            style={{ 
              textAlign:'center', 
              fontSize:'1rem', 
              background:'#fff', 
              padding:'0.5rem', 
              position: 'absolute', 
              top: '50%', 
              left: '40%', 
              right: '50%',
               width: '30%' 
              }}>
                { noDataText }
          </span>
        }
      </div>
    </div>
  )
}

export default ReactTable;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import { NetSuiteStatusValues } from '../../OrderDetails/Pages/NetSuiteStatusValues';
import { currencyFormatter, mainStore } from '../../../../stores/MainStore';
import LoadingSipnner from '../../../UI/LoadingSipnner';

class INV extends Component {
    constructor(props) {
        super(props)

        this.state = {
            otherrefnum: "",
            instructions: "",
            memo: "",
            fax: "",
            id: "",
            foreignamountpaid: "", 
            foreignamountunpaid: "",
            foreigntotal: "",
            lastmodifieddate: "", 
            shipdate: "",
            status: "",
            tranid: "",
            transactionnumber: "", 
            trandate: "",
            duedate: "",
            daysopen: "",
            daysoverduesearch: "",
            invNo: "",
            loading: false
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
        
        } else {
            window.location.href = '/login';
        }
    }

    handleInvNoChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ invNo: value.trim() });
        }
    }

    handleSearch = (e) => {
        e.preventDefault()
        const { invNo } = this.state
        if(invNo) {
            this.setState({ loading: true })
            this.props.orderStore.search(invNo,'inv')
            .then(() => {
                this.setState({ loading: false })
                const { searchResults } = this.props.orderStore;
                if(searchResults) {
                    this.setState({ ...searchResults.data })
                }
            })
        } else {
            mainStore.setError("Please type in your invoice number");
        }
    }

    handleFile(id,tranid) {
        this.setState({ loading: true })
        this.props.orderStore.fetchFile({ internalId: id, tranId: tranid },'download','transaction')
        .then(() => {
            this.setState({ loading: false })
        })
    }

    downloadButton(id,tranid){
        return id && <div className="order-document-actions">
            <div className="view-report" onClick={() => this.handleFile(id,tranid)}>
                { tranid } <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } />
            </div>
        </div>
    }

    render() {

        const { 
            otherrefnum,
            instructions,
            memo,
            fax,
            id,
            foreignamountpaid, 
            foreignamountunpaid,
            foreigntotal, 
            lastmodifieddate, 
            shipdate, 
            status, 
            tranid,
            transactionnumber, 
            trandate,
            duedate,
            daysopen,
            daysoverduesearch,
            invNo,
            loading
         } = this.state;

         const { searchResults } = this.props.orderStore;


        return (
            <section className="box inventory">
                <div className="panel panel-default">
                    <h3>Search for My Invoice</h3>
                    <div className="panel-body">
                        <form onSubmit={ this.handleSearch }>
                            <div className="flex flex-row">
                                <div className="flex-1 flex-2-md">
                                    <input ref="tranId" className={`no-margin`} type="text" placeholder="Invoice # e.g. INVXXXXX" value={ invNo ? invNo : '' } onChange={ this.handleInvNoChange }/>
                                </div>
                                <div className="flex-1">
                                    <button className="button button--block no-margin" type="submit">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='panel' style={{ height: 400 }}>
                    { loading ? <LoadingSipnner /> : null }
                    { searchResults && searchResults.error ? <div style={{ position: "absolute", top:"40%", left: "30%", fontSize: "1.2rem"}}>{ searchResults.message }</div> : ""}
                    {
                        searchResults && searchResults.searchType === "INV" 
                        ? 
                        <div className="order-information">
                            <InfoGridPanel className={"col-4"}>
                                <InfoGridItem  label={"Invoice ID:"} value={ tranid }/>
                                <InfoGridItem  label={"Invoice Trans Number:"} value={ transactionnumber }/>
                                <InfoGridItem  label={"Transaction Date:"} value={ trandate }/>
                                <InfoGridItem  label={"Ship Date:"} value={ shipdate }/>
                                <InfoGridItem  label={"Lastmodified Date:"} value={ lastmodifieddate }/>
                                <InfoGridItem  label={"Ref Number:"} value={ otherrefnum }/>
                                <InfoGridItem  label={"Foreign Amt Paid:"} value={ currencyFormatter(foreignamountpaid,'USD','en-US')  }/>
                                <InfoGridItem  label={"Foreign Amt Unpaid:"} value={ currencyFormatter(foreignamountunpaid,'USD','en-US')  }/>
                                <InfoGridItem  label={"Foreign Total:"} value={ currencyFormatter(foreigntotal,'USD','en-US')  }/>
                                <InfoGridItem  label={"Fax:"} value={ fax }/>
                                <InfoGridItem  label={"Due Date:"} value={ duedate }/>
                                <InfoGridItem  label={"Days Over due search:"} value={ daysoverduesearch }/>
                                <InfoGridItem  label={"Days Open:"} value={ daysopen }/>
                                <InfoGridItem  label={"Status:"} value={ NetSuiteStatusValues[`CustInvc:${status}`] }/> 
                                <InfoGridItem  label={"Invoice Report:"} value={ this.downloadButton(id, tranid) }/>                         
                            </InfoGridPanel>
                            <InfoGridPanel className={"col-2"}>
                                <InfoGridItem  label={"Special Instructions:"} value={ instructions }/>
                                <InfoGridItem  label={"Memo:"} value={ memo }/>
                            </InfoGridPanel>
                        </div>
                        : ""
                    }
                </div>
            </section>
        );
    }
}

export default inject( 'orderStore','mainStore', 'authStore')(observer(INV));
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lot {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    top: 5px;
    background-color: #fff;
}
.lot h3 {
    cursor: pointer;
}
.lot-view {
    display: flex;
}
.lot-list {
    flex: 1;
}
.selected-lot {
    /* flex: 0.1; */
}
/* .lot-list .sticky-table .rt-tr {
    cursor: pointer;
} */
.lot-view-close {
    display: flex;
    justify-content: space-between;
}
#lot-view-close-btn {
    font-size: 1.2rem;
}
.download-mtr {
    display: flex;
    /* flex-direction: column; */
    /* width: 180px !important; */
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
}
/* .download-mtr > div {
    text-wrap: nowrap;
    width: 100%;
} */
.view-report__inline {
    /* font-size: 0.65rem; */
}
.react-pdf__Page__textContent {
    display: none;
}

`, "",{"version":3,"sources":["webpack://./src/components/InventorySummary/LotsView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,QAAQ;IACR,QAAQ;IACR,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,OAAO;AACX;AACA;IACI,eAAe;AACnB;AACA;;GAEG;AACH;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;IAC7B,WAAW;IACX,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;;;GAGG;AACH;IACI,wBAAwB;AAC5B;AACA;IACI,aAAa;AACjB","sourcesContent":[".lot {\n    height: 100vh;\n    width: 100vw;\n    overflow: auto;\n    position: absolute;\n    z-index: 9;\n    left: 0;\n    right: 0;\n    top: 5px;\n    background-color: #fff;\n}\n.lot h3 {\n    cursor: pointer;\n}\n.lot-view {\n    display: flex;\n}\n.lot-list {\n    flex: 1;\n}\n.selected-lot {\n    /* flex: 0.1; */\n}\n/* .lot-list .sticky-table .rt-tr {\n    cursor: pointer;\n} */\n.lot-view-close {\n    display: flex;\n    justify-content: space-between;\n}\n#lot-view-close-btn {\n    font-size: 1.2rem;\n}\n.download-mtr {\n    display: flex;\n    /* flex-direction: column; */\n    /* width: 180px !important; */\n    gap: 0.5rem;\n    align-items: center;\n    justify-content: flex-start;\n}\n/* .download-mtr > div {\n    text-wrap: nowrap;\n    width: 100%;\n} */\n.view-report__inline {\n    /* font-size: 0.65rem; */\n}\n.react-pdf__Page__textContent {\n    display: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

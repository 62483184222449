import React from 'react'

import "./Card.css";

const Card = ({ text, value, icon }) => {

    return(
        <div className="card">
          <i className={icon}></i>
          <div>
            <b>{value}</b>
            <span>{ text }</span>
          </div> 
        </div>
    )
}


const CardContainer = ({ children }) => {
    return(
        <div className="cards-contianer">
            { children  }
        </div>
    )
}

export {
    Card,
    CardContainer
}
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';


class SkeletonLoader extends Component {

    render() {
        const { width, height, borderRadius } = this.props
        return (
            <div className='loading-skeleton' style={{ width: width, height: height, borderRadius }}></div>
        )
    }
}

export default inject('mainStore')(observer(SkeletonLoader));

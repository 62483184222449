// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-details {
    overflow-x: hidden;
    overflow-y: scroll; /* has to be scroll, not auto */

    -webkit-overflow-scrolling: touch;

    width: 100vw;
    height: 100vh;
    padding-top: 80px;

    background-color: #DADBDD;
}
.mtr-report-view {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Order/Details/Details.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB,EAAE,+BAA+B;;IAEnD,iCAAiC;;IAEjC,YAAY;IACZ,aAAa;IACb,iBAAiB;;IAEjB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,MAAM;IACN,OAAO;IACP,WAAW;AACf","sourcesContent":[".order-details {\n    overflow-x: hidden;\n    overflow-y: scroll; /* has to be scroll, not auto */\n\n    -webkit-overflow-scrolling: touch;\n\n    width: 100vw;\n    height: 100vh;\n    padding-top: 80px;\n\n    background-color: #DADBDD;\n}\n.mtr-report-view {\n    position: absolute;\n    z-index: 999;\n    top: 0;\n    left: 0;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

// Styles
import './TopNavNew.css';
import SkeletonLoader from '../../UI/SkeletonLoader';


class TopNavSkeleton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            myAccountOpen: false
        };

    }

    handleLogout = () => {
        this.props.authStore.logout();
        this.props.history('/');
        window.location.reload()
    }

    handleMyAccount = (e) => {
        e.preventDefault();
        this.setState({ myAccountOpen: !this.state.myAccountOpen });
    }
    handleSettings = (e) => {
        this.setState({ myAccountOpen: false })
    }

    render() {
        return (
            <header className="header-topnav">
                <Link to={ '/' } replace><img className="topnav-logo" src="/images/logo.png" alt="AA Metals" /></Link>
                {
                    <React.Fragment>
                        <nav>
                            <ul>
                                <li>
                                    <NavLink className="" to={ '/home' } data-cy="home">Home</NavLink>
                                </li>
                                <li>
                                    <a className="">
                                        My New PO
                                    </a>
                                </li>
                                <li>
                                    <a className="" name="button">Search <i className='fa fa-caret-down'></i></a>
                                    <ul>
                                        <li>
                                            <NavLink className=""  data-cy="my-orders"  to={ '/' }>
                                                <SkeletonLoader height={5}/>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className=""  data-cy="my-orders"  to={ '/' }>
                                                <SkeletonLoader height={5}/>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="" name="button">Company <i className='fa fa-caret-down'></i></a>
                                    <ul>
                                        <li>
                                            <NavLink className=""  data-cy="my-orders"  to={ '/' }>
                                                <SkeletonLoader height={5}/>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className=""  data-cy="my-orders"  to={ '/' }>
                                                <SkeletonLoader height={5}/>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            
                            <ul>
                                <li>
                                    <a className="" name="button" data-cy="account" type="button">My Account <i className='fa fa-caret-down'></i></a>
                                    <ul className="">
                                        <li>
                                            <NavLink className=""  data-cy="my-orders"  to={ '/' }>
                                                <SkeletonLoader height={5}/>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/" className="" onClick={ this.handleLogout } data-cy="logout"><i className="fas fa-sign-out-alt"></i>Logout</NavLink> 
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a>My Sales Rep <i style={{ fontSize: '1rem', fontWeight: '600' }} className="fas fa-headset"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </React.Fragment>
                }
            </header>
        );
    }
}

export default inject('authStore','mainStore')(observer((props) => <TopNavSkeleton history={useNavigate()} {...props}/>));

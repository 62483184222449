import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Modal, Button } from 'antd';
// Style
import './NotFound.css';
import LoadingSipnner from '../../UI/LoadingSipnner'
import { authStore } from '../../../stores/AuthStore';
import { mainStore } from '../../../stores/MainStore';

export function withRouter(Children){
    return(props)=>{
       return <Children {...props}  history = {useNavigate()}/>
    }
}
class SessionTimeOut extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showDialog: false,
            loading: false,
            itemRemoved: false
        }
    }

    componentDidMount() {
        this.removeItems()
    }

    handleGoBack = () => {
        authStore.logout()
        this.props.history('/login')
        window.location.reload()
    };

    handleConfirm(){
        authStore.logout()
        this.props.history('/login')
        window.location.reload()
    }

    removeItems() {
        if(this.props.inventoryStore?.cartItems?.length > 0) {
            this.setState({ showDialog: false, loading: true })
            let ids = []
            for(let i=0; i<this.props.inventoryStore?.cartItems?.length; i++) {
                // const v = await this.props.cartStore.removeCartItems(this.props.inventoryStore?.cartItems[i].ocCartLineItemID)
                ids.push(this.props.inventoryStore?.cartItems[i].ocCartLineItemID)
            }
            const allTasks = ids.map((pageNumber)=>{
                return this.props.cartStore.task(pageNumber);
            });

            Promise.all(allTasks).then((result)=>{
                // once the task is finished then you stop loader over here
                authStore.logout()
                // this.props.history('/login')
                // window.location.reload()
                this.setState({ itemRemoved: true, showDialog: true })
            }).catch((err)=>{
                //If error comes then also you have to stop loader
                console.log(err);
                console.log("Something went wrong")
                mainStore.setError(`Something went unusual went wrong. It seems there were some items left in your cart. Please remove them when you log in again or contact the admin.`);
            }).finally(() => {
                this.setState({ loading: false })
            })
        } else {
            authStore.logout()
            this.props.history('/login')
        }
    }

    render() {
        const { showDialog, loading, itemRemoved } = this.state
        // const { cartItems } = this.props.inventoryStore

        return (
            <section className="session-timeout flex column middle center">
                <h1>Your Session Timed Out</h1>
                <button className="button" name="button" type="button" onClick={ () => this.handleGoBack() } disabled={loading}>Return to login page</button>

                {
                    showDialog && itemRemoved &&
                    <Modal
                        title="Caution!"
                        open={showDialog}
                        onCancel={() => this.handleConfirm()}
                        footer={[
                            <Button key="confirm" type="primary" onClick={() => this.handleConfirm()}>
                                Okay
                            </Button>
                        ]}
                    >
                        <span>Your session has expired while items were in your cart. Those items have been removed from your cart and you have been logged out in all open tabs.</span>
                    </Modal>
                }
                { loading && <LoadingSipnner /> }
                    
                { loading && <div style={{ position: 'absolute', top: '58%', right: '10%', left: '10%', color: "#283A8E", fontWeight: "500", textAlign: "center"}}>{`Removing cart items, please wiat...`}</div>}
            </section>
        );
    }

}

// Giving the arrow function a name before exporting it as the default
export default inject('authStore','orderStore','cartStore','mainStore', 'inventoryStore')(withRouter(observer(SessionTimeOut)));

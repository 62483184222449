import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class InfoDetail extends Component {

    render() {
        return(
            <div className="info-details">
                <div className="info-name">{ this.props.label }</div> 
                <div className="info-value">{ this.props.value }</div>
            </div>
        )
    }
}

export default inject('mainStore')(observer(InfoDetail));

import React from 'react';
import { inject, observer } from 'mobx-react';


const TransparentLoader = (props) => {

    const cancelRequest = () => {
        props.authStore.logout()
        window.location.reload()
    }
    return (
        <div>
            <span className="loader__message" style={{ left: props?.left ? '10px' : '', display: 'flex', flexDirection: 'column', gap: 10 }}>
                { props?.message }
                <button onClick={() => cancelRequest()}>Cancel</button>
            </span>
            <div className="loader">
                <div className="loader__inner"></div>
            </div>
        </div>
    );

}
export default inject( 'authStore')(observer(TransparentLoader));

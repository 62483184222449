import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

// Style
import './Details.css';

// Components
import List from './List/List';

class PDFViewer extends Component {


    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            this.props.orderStore.fetchFile({ trandId:this.props.match.params.id, internalId:this.props.match.params.internalId },'view',this.props.match.params.type)
        } else {
            window.location.href = '/login';
        }
    }

    goBack = (event) => {
        event.preventDefault();
        this.props.history(-1);
    }

    render() {
        const { goBack } = this;
        

        return (
            <section className="box order-details">
                <List order={{ tranId:this.props.match.params.id, internalId:this.props.match.params.internalId, type:this.props.match.params.type }} goBack={ goBack } />
            </section>
        );
    }

}

export default inject('orderStore', 'inventoryStore', 'mainStore', 'cartStore', 'authStore')(observer((props) => <PDFViewer history={useNavigate()} {...props}/>));

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import "./Info.css";

class Customer extends Component {
    constructor(props){
        super(props)

        this.state = {
            showEdit: false,
            title: this.props.data.title,
            entityId: this.props.data.entityId,
            phone: this.props.data.phone, 
            email: this.props.data.email,
            isContact: this.props.data.isContact
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            //this.props.authStore.fetchCustomer('customer_rest_call')
        }
    }

    handleChange = (e) => {
        this.setState({...this.state, [e.target.name]: e.target.value})
    }

    updateBoolValue() {
        this.setState({showEdit: !this.state.showEdit})
    }
    
    submitData (){

        setTimeout(()=>{
            this.setState({showEdit: !this.state.showEdit})
        },100)
    }
    
    toggleList = () => {
        let panel = document.querySelector('.customer-address-list');
        panel.classList.toggle('active');
    }
    
    
    render() {
        const { entityId, title,phone, email, isContact } = this.state;
        const { user } = this.props.authStore
                
        return (
            <div>
            <h2>PRIMARY CONTACT INFO</h2>
            <div className="panel" id="customer">
                { 
                    process.env.REACT_APP_FEATURE_FLAG === "true" ?
                        <div className="info-actions">
                            { this.state.showEdit && <button className="cancel-button" onClick={this.updateBoolValue.bind(this)}>Cancel</button> }
                            { !this.state.showEdit && <button className="edit-button" onClick={this.updateBoolValue.bind(this)}>Edit</button> } 
                            { this.state.showEdit && <button className="save-button" onClick={this.submitData.bind(this)}>Save</button> }
                        </div>
                    : ""
                } 
                <div className="info-details">
                    <div className="info-name">Name:</div> 
                    <div className="info-value">{ this.state.showEdit ? <input type="text" name="name" size={entityId.length} onChange={ this.handleChange} value={entityId || ''} className="edit-form" required /> : isContact ? entityId : "There is no primary contact set for this customer."}</div>
                </div>
                <div className="info-details">
                    <div className="info-name">Position / Job Title:</div> 
                    <div className="info-value">{ this.state.showEdit ? <input type="text" name="title"  onChange={ this.handleChange} value={title || ''} className="edit-form" required /> : isContact ? title : "N/A"}</div>
                </div>
                <div className="info-details">
                    <div className="info-name">Email</div> 
                    <div className="info-value">{ email }</div>
                </div>
                <div className="info-details">
                    <div className="info-name">Phone</div> 
                    <div className="info-value">{ phone }</div>
                </div>
            </div>
            {
                    !this.state.showEdit && <div className="panel" id="company">
                                                <div className="info-details">
                                                    <div className="info-name">Logged-in User Email</div> 
                                                    <div className="info-value">{ user?.email }</div>
                                                </div>
                                                <div className="info-details">
                                                    <div className="info-name">Logged-in User Company</div> 
                                                    <div className="info-value">{ user?.name }</div>
                                                </div>
                                            </div>
            }
        </div>
        );
    }
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer(Customer));
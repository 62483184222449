import { action, makeAutoObservable, observable } from 'mobx';
import { create, persist } from 'mobx-persist';
import clientPersist from 'client-persist';
import { makePersistable } from 'mobx-persist-store';
import { Session } from 'bc-react-session';

// Plugins
import axios from 'axios';
import _ from 'lodash';

// Stores
import { cartStore } from './CartStore';
import { inventoryStore } from './InventoryStore';
import { mainStore, waitFor } from './MainStore';
import { orderStore } from './OrderStore';
import api from '../http';

// to use sessionStorage
clientPersist.setDriver(clientPersist.SESSIONSTORAGE);

class AuthStore {

    // Observable state
    @observable hydrated = false;
    @persist @observable token = undefined;
    @persist @observable ocToken = undefined;
    @persist @observable refreshToken = undefined;
    @persist('boolean') @observable toggleSalesRep = false;
    @persist('object') @observable user = undefined;
    @persist @observable userName = undefined;

    @persist @observable logoutModalOpen = false;
    @persist @observable timerValue = 0;
    @persist @observable timerName = undefined;
    @persist @observable modalTimer = undefined;
    @persist @observable modalTimerValue = 0;
    @persist @observable timeout = 1000 * 60 * 120; // 10 minutes
    @persist @observable timeoutWarning = 1000 * 60 * 1; // 1 minute
    @persist timeoutWorker = new Worker('/js/TimeoutWorker.js');


    //I created the customer variable because the user variable gets populated with authentication response user data
    @persist('object') @observable customer = undefined;
    @persist('object') @observable contact = undefined;
    @persist('object') @observable userCompanies = undefined;
    @persist('object') @observable salesRep = undefined;
    @persist('object') @observable salesRepDetails = undefined;
    @persist('object') @observable loggedInUserDetails = undefined;
    announcements = undefined;
    customerPriceLevel = undefined;
    verificationFailed = false;
    customerDefaultShippingAddress = undefined;
    homepageAlreadyLoaded = false;
    noticeAlreadyLoaded = false;
    customerShippingAddress = [];
    currentCompanyID = undefined;
    currentCompanyName = "";
    addressSelectList=[];
    showNotice = true;
    showModal = true;

    @observable credentials = {
        username: '',
        password: ''
    };

    constructor(){
        makeAutoObservable(this, {
            customerPriceLevel: observable,
            verificationFailed: observable,
            customerDefaultShippingAddress: observable,
            homepageAlreadyLoaded: observable,
            noticeAlreadyLoaded: observable,
            customerShippingAddress: observable,
            currentCompanyID: observable,
            currentCompanyName: observable,
            addressSelectList: observable,
            showNotice: observable,
            showModal: observable,

            setCustomerPriceLevel: action,
            setVerificationFailed: action,
            setCustomerDefaultShippingAddress: action,
            setHomepageAlreadyLoaded: action,
            setNoticeAlreadyLoaded: action,
            setCurrentCompany: action,
            resetAddresses: action,
            setHideNotice: action
        });
        makePersistable(this, { name: 'authStore', properties: ['token', 'ocToken', 'userCompanies', 'user', 'userName', 'customerPriceLevel', 'customerDefaultShippingAddress', 'homepageAlreadyLoaded', 'noticeAlreadyLoaded', 'customerShippingAddress', 'currentCompanyID', 'currentCompanyName', 'addressSelectList', 'showNotice','announcements','showModal'], storage: window.localStorage });
    }

    setHydrated(hydrated) {
        this.hydrated = hydrated;
        cartStore.setHydrated(hydrated);
        inventoryStore.setHydrated(hydrated);
        mainStore.setHydrated(hydrated);
        orderStore.setHydrated(hydrated);
    }

    setToken(token, ocToken) {
        this.token = token;
        this.ocToken = ocToken;
        cartStore.setToken(token);
        cartStore.setOCToken(ocToken);
        inventoryStore.setToken(token);
        inventoryStore.setOCToken(ocToken);
        orderStore.setToken(token);
    }

    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
    }

    setUser(user) {
        this.user = user;
        cartStore.setUser(user);
        inventoryStore.setUser(user);
        orderStore.setUser(user);
    }

    setUserName(userName) {
        this.userName = userName
        orderStore.setUserName(userName)
    }

    setHideNotice(noticeState,modalState) {
        this.showNotice = noticeState;
        this.showModal = modalState
    }

    // This is to handle this.customer data instead of the this.user data
    setCustomer(user) {
        this.customer = user;

        Session.setPayload({
            customerPriceLevel: user.priceLevel,
            customerShippingAddress: user.addressList
        });
    }

    setSalesRep(salesRep){
        this.salesRep = salesRep
    }

    setContact(contact) {
        let contactDetails = { }
        if(!!contact.priceLevel){
            contactDetails = { isContact: false, ...contact}
        } else {
            contactDetails = { isContact: true, ...contact}
        }
        this.contact = contactDetails;
    }

    setUserCompanies(userCompanies, userName){
        this.userCompanies = userCompanies;
        this.userName = userName
    }

    setLoggedInUserDetails(loggedInUserDetails){
        this.loggedInUserDetails = loggedInUserDetails;
    }

    setSalesRepDetails(salesRepDetails){
        this.salesRepDetails = salesRepDetails;
    }

    setCustomerDefaultShippingAddress(data) {

        //store the default shipping address in the store
        if(data) {
            //Check if there is an FOB shipping address
            const fobAddress = data.find((address) => (address.shippingZone).toLowerCase() === 'fob' && address.defaultShipping === true);
            const defaultAddress = data.find((address) => address.defaultShipping === true);
            this.customerDefaultShippingAddress = fobAddress ? fobAddress : defaultAddress;

            let addressSelectList =[{ value: "", text: "Choose Prefered Shipping Address", defaultShipping: false },{ value: -2, text: "- Custom -", defaultShipping: false }];
            data?.forEach((element) => {
                addressSelectList.push({ value: element.internalId, text: element.label,  defaultShipping: element?.defaultShipping })
            })
            this.addressSelectList = addressSelectList
            this.customerShippingAddress = data
        } else {
            this.customerDefaultShippingAddress = undefined;
            this.customerShippingAddress = []
        }
    }

    resetAddresses() {
        this.customerDefaultShippingAddress = undefined;
        this.customerShippingAddress = []
        this.addressSelectList=[{ value: -2, text: "- Custom -", defaultShipping: false }]
    }

    setUsername(username) {
        this.credentials.username = username;
    }

    setPassword(password) {
        this.credentials.password = password;
    }

    setAnnouncements(announcements) {
        this.announcements = announcements;
    }
    setVerificationFailed(status) {
        this.verificationFailed = status
    }

    setHomepageAlreadyLoaded(state) {
        this.homepageAlreadyLoaded = state
    }

    setNoticeAlreadyLoaded(state) {
        this.noticeAlreadyLoaded = state
    }

    setCurrentCompany(company) {
        this.currentCompanyID = company?.companyId;
        this.currentCompanyName = company?.companyName
    }

    @action toggleLogoutModal() {
        authStore.logoutModalOpen = !authStore.logoutModalOpen;
        if (authStore.logoutModalOpen === true) {
             this.modalTimer = setInterval(function() {
                authStore.modalTimerValue += 1000;
            }, 1000);
        }
        else {
            authStore.modalTimerValue = 0;
            clearInterval(this.modalTimer);
        }
    }

    forget() {
        this.hydrated = false;
        this.token = undefined;
        this.ocToken = undefined
        this.refreshToken = undefined;
        this.user = undefined;
        this.customer = undefined;
        this.contact = undefined;
        this.salesRep = undefined
        this.userCompanies = undefined;
        this.credentials = {
            username: '',
            password: ''
        };
        this.userName = undefined
        this.customerPriceLevel = undefined
        this.customerDefaultShippingAddress = undefined
        this.homepageAlreadyLoaded = false
        this.noticeAlreadyLoaded = false
        this.customerShippingAddress = []
        this.currentCompanyID = undefined
        this.currentCompanyName = undefined
        this.addressSelectList = []
        Session.destroy()
    }

    clearContact() {
        this.contact = undefined
        this.salesRep = undefined
        this.customer = undefined
    }

    setToggleSalesRep() {
        this.toggleSalesRep = !this.toggleSalesRep
    }

    resetPassword(username, password, newPassword){
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/auth/resetPassword', { username, password, newPassword }, { headers: { 'Content-Type': 'application/json' }})
            .then( response => {
                let data = response.data;
                if(data.error_description){
                    mainStore.setError(`Password reset failed: ${data.error_description}`);
                } else {
                    mainStore.setSuccess('Your password has been reset!');
                }
                mainStore.setProgress({ loading: false });
            })
            .catch( error => {
                let data = error.response && error.response.data ? error.response.data.error_description : error.message;
                mainStore.setError(data);
                mainStore.setProgress({ loading: false });
            });
    }

    requestPasswordResetCode(email){
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/auth/requestPasswordResetCode', { email }, { headers: { 'Content-Type': 'application/json' }})
            .then( response => {
                let data = response.data;
                if(data.error_description){
                    mainStore.setError(`Password reset failed: ${data.error_description}`);
                } else {
                    mainStore.setSuccess('Your password has been reset!');
                }
                mainStore.setProgress({ loading: false });
            })
            .catch( error => {
                let data = error.response && error.response.data ? error.response.data.error_description : error.message;
                mainStore.setError(data);
                mainStore.setProgress({ loading: false });
            });
    }

    initTimeoutWorker() { // TODO Configure Webpack after update to compile workers, otherwise worker must be in public folder
        authStore.timeoutWorker.terminate();
        authStore.timeoutWorker = new Worker('/js/TimeoutWorker.js');
        authStore.timeoutWorker.postMessage(['Timeout Interval',authStore.logoutModalOpen, authStore.timeout, authStore.timeoutWarning]);
        authStore.timeoutWorker.onmessage = (e) => { this.timeoutWorkerMessages(e); };
    }

    // THIS METHOD IS A HELPER FUNCTION TO FILTER MESSAGES SENT BY WEBWORKER
    async timeoutWorkerMessages(event) {
        let message = event.data[0];

        switch (message) {
            // This message posted from webworker when time elapsed is greater than authStore.timeout minus authStore.timeoutWarning
            case 'Timeout Warning':
                if (authStore.logoutModalOpen === false) {
                    authStore.toggleLogoutModal();
                }
                if (authStore.logoutModalOpen === true && window.location.href.includes('session-timeout')) {
                    authStore.toggleLogoutModal();
                }
                authStore.timeoutWorker.postMessage(['Timeout Warning', authStore.timeoutWarning]);
                break;
            // This message is posted from webworker when timeout modal finishs counting down or total time elapsed is greater than authStore.timeout
            case 'Timeout':
                authStore.toggleLogoutModal();
                window.location.href = '/session-timeout';
                authStore.timeoutWorker.terminate();
                break;
            // If no message terminate webworker
            default:
                authStore.timeoutWorker.terminate();
                break;
        }
    }

    resetTimer(){
        authStore.timerValue = 0;
        if(authStore.token !== undefined) {
            authStore.initTimeoutWorker();
        }
    }

    login() {
        mainStore.setProgress({ loading: true });
        const { username, password } = this.credentials;

        // Using OrderCloud
        return axios.post('/token', `username=${ username.toLowerCase() }&password=${ password }&grant_type=password&client_id=backend`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action(({ data }) =>  {
                this.setToken(data.access_token,data.ocToken);
                this.setRefreshToken(data.refresh_token);
                this.fetchUser(data);
                this.initTimeoutWorker();

                Session.start('aametals-app-session', {
                    payload: {
                        user: this.user,
                        token: this.token,
                        ocToken: this.ocToken
                    },
                    expiration: 60000 // (60000) defaults to 1 minute
                });

                cartStore.getCartLineItems();
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data.error_description : error.message;
                mainStore.setError(data);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchUser(data) {
        let newUser = {
            userId: data.userId,
            userStatus: data.userStatus,
            email: data.username,
            entityId: data.entityId,
            name: data.name,
            role: data.role,
            roleId: data.roleId,
            priceLevel: data.priceLevel,
            addressList: data.addressList ? JSON.parse(data.addressList) : undefined,
            trailerNotesBol: data.trailerNotesBol,
            trailerNotesSo: data.trailerNotesSo,
            salesRepId: data.salesRepId,
            salesRepEmail: data.salesRepEmail,
            ipAddress: data.ipAddress,
            phone: data.phone,
            fax: data.fax,
            defaultAddress: data.defaultAddress,
            salesRep: data.salesRep,
            category: data.category,
            dateCreated: data.dateCreated,
            balance: data.balance,
            creditLimit: data.creditLimit,
            overdueBalance: data.overdueBalance,
            depositBalance: data.depositBalance
        };
        this.setUser(_.merge(this.user || {}, newUser));
    }

    fetchCustomerData(data) {
        let newUser = {
            userId: data.userId,
            userStatus: data.userStatus,
            email: data.email,
            entityId: data.entityId,
            name: data.name,
            role: data.role,
            roleId: data.roleId,
            priceLevel: data.priceLevel,
            addressList: data.addressList ? JSON.parse(data.addressList) : undefined,
            trailerNotesBol: data.trailerNotesBol,
            trailerNotesSo: data.trailerNotesSo,
            salesRepId: data.salesRepId,
            salesRepEmail: data.salesRepEmail,
            ipAddress: data.ipAddress,
            phone: data.phone,
            fax: data.fax,
            defaultAddress: data.defaultAddress,
            salesRep: data.salesRep,
            category: data.category,
            dateCreated: data.dateCreated,
            balance: data.balance,
            creditLimit: data.creditLimit,
            overdueBalance: data.overdueBalance,
            depositBalance: data.depositBalance,
            salesRepData: data.salesRepData,
            isSalesRepAssigned: data.isSalesRepAssigned
        };
        this.setCustomer(_.merge(this.customer || {}, newUser));
    }
    setCustomerPriceLevel(data) {
        this.customerPriceLevel = data.priceLevel
        inventoryStore.setCustomerPriceLevel(data.priceLevel)
    }
    logout() {
        clearInterval(authStore.timerName);
        this.timeoutWorker.terminate();
        authStore.logoutModalOpen = false;
        mainStore.setProgress({ loading: true });
        authStore.forget();
        cartStore.forget();
        inventoryStore.forget();
        mainStore.forget();
        orderStore.forget();
        mainStore.setProgress({ loading: false });
        localStorage.clear()
        Session.destroy();
    }

    checkUser(navigate) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullUser(navigate));
    }

    pullUser(navigate) {
        const session = Session.get("aametals-app-session");
        if (session.active) {
            this.setUser(this.user);
            this.setUserCompanies(this.userCompanies);
            this.setToken(this.token, this.ocToken);

            /**
             * Instead of using window.location.href (sentry flagged security risk) in the authStore for
             * navigation, we are relying on HashRouters history.replace method which can be accessed in 
             * this components props
             */
            navigate('/home')
        }
    }

    async forgotPassword(email) {
        let postBody = JSON.stringify({
            "Email": email,
            "username": email
        })
        return axios.post(`/api/auth/requestPasswordResetCode`, postBody)
        .then((res) => {
            if (res.data.error_description){
                mainStore.setError(res.data.message);
            }
        })
        .catch((error) => {
            let data = error.response && error.response.data ? error.response.data.error_description : error.message;
            mainStore.setError(data);
            mainStore.setProgress({ loading: false });
        });
    }

    async forgotPasswordSubmit(Email, Code, password){
        //mainStore.setProgress({ loading: true });
        return axios.post('/api/auth/resetForgottenPassword', { Email, Code, password }, { headers: { 'Content-Type': 'application/json' }})
            .then( response => {
                let data = response.data;
                if(data.error_description){
                    this.setVerificationFailed(true)
                    mainStore.setError(`Password reset failed: ${data.message}`);
                } else {
                    this.setVerificationFailed(false)
                    mainStore.setSuccess('Your password has been reset!');
                }
               // mainStore.setProgress({ loading: false });
            })
            .catch( error => {
                let data = error.response && error.response.data ? error.response.data.error_description : error.message;
                mainStore.setError(data);
                mainStore.setProgress({ loading: false });
            });
    }

    fetchCustomer(api_type,userId) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullCustomer(api_type,userId));
    }

    @action pullCustomer(api_type, userId) {

        if (userId && (!this.user || !this.user['userStatus'])) {
            mainStore.setProgress({ loading: true });
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/inventory/${api_type}?internalId=${ userId }`)
                .then(action(({data}) => {
                    this.fetchCustomerData(data);
                    this.setCustomerPriceLevel(data);

                    mainStore.setProgress({ loading: false });
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    fetchContact(userId) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullContact(userId));
    }

    @action pullContact(userId) {
        if (userId && (!this.user || !this.user['userStatus'])) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/inventory/getContact?userId=${ userId }`)
                .then(action(({data}) => {
                    this.setContact(data);
                    mainStore.setProgress({ loading: false });
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    fetchSalesRep() {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullSalesRep());
    }

    pullSalesRep() {
        if (!this.user['salesRepEmail']) {
            axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
            return axios.get(`/api/inventory/salesRep?internalId=${ this.customer.salesRepId }`)
                .then(action(({data}) => {
                    this.setSalesRep(data);
                }))
                .catch((error) => {
                    let data = error.response && error.response.data ? error.response.data : error;
                    mainStore.setError(data.message);
                    mainStore.setProgress({ loading: false });
                });
        } else {
            return new Promise((resolve) => {
                resolve(window.setTimeout(undefined, 100));
            });
        }
    }

    // fetchUserCompanies(email) {
    //     return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullUserCompanies(email));
    // }

    async fetchUserCompanies(email){
        const response = await api.get(`/api/inventory/contactCompaniesPermissions?email=${email}`);
        if(response?.status === 401) {
            window.location.href = '/session-timeout';
            mainStore.setError(`${response?.statusText}: Authorization Denied`);
        } else if(response?.status === 200){
            const  { data }  = response
            // Session.setPayload({
            //     companies: data?.companies,
            //     userId: data?.userId,
            //     userName: data?.name,
            //     currentCompanyID: data && data?.companies.length > 0 ? data?.companies[0].companyId : undefined
            // });
            this.setUserName(data?.name)
            this.setUserCompanies(this.userCompanies || data?.companies);
            
            let currentCompany = data && data?.companies?.length > 0 ? data?.companies[0] : undefined;
            if(this.currentCompanyID) {
                currentCompany = this.userCompanies.find((userCompany) => this.currentCompanyID === userCompany?.companyId);
            }
            this.setCurrentCompany(currentCompany)
            this.setHydrated(true);
        } else {
            // this.logout()
        }

    }
    
    @action fetchAnnouncements(location, path) {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullAnnouncements(location, path));
    }

    @action pullAnnouncements(location, path) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        axios.defaults.headers.common['OrderCloudAuthorization'] = this.ocToken;
        this.announcements = undefined
        mainStore.setProgress({ loading: true });
        return axios.get(`/api/client/${path}?location=${location}`)
            .then(action(({ data }) => {
                mainStore.setProgress({ loading: false });
                if((typeof(data) === 'string' && data.includes('Access token is invalid or expired'))){
                    // authStore.logout();
                    // window.location.reload()
                    window.location.href = '/session-timeout';
                    return;
                }
                this.setAnnouncements(data);
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }

    @action fetchLoggedInUserDetails() {
        return waitFor(() => mainStore.hydrated && this.hydrated, () => this.pullLoggedInUserDetails());
    }

    pullLoggedInUserDetails(email) {
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        const { payload: {  user } } = Session.get("aametals-app-session");
        return axios.get(`/api/inventory/loggedInUserDetails?email=${user.email}`)
            .then(action(({data}) => {
                mainStore.setProgress({ loading: false });
                if(data.status === 401) {
                    window.location.href = '/session-timeout';
                    mainStore.setError(`${data.title}: Authorization Denied`);
                } else {
                    this.setLoggedInUserDetails(data);
                    this.setHydrated(true);
                    Session.setPayload({
                        userDetails: data
                    });
                }
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }


    async fetchCustomerShippingAddress(id) {
        const response = await api.get(`/api/location/shippingaddress?netsuiteID=${id}`);
        if(response && response.status === 200 && response.data?.length > 0) {
            this.setCustomerDefaultShippingAddress(response.data)
        } else {
            this.setCustomerDefaultShippingAddress(undefined)
        }
    }
    // An update function to handle customer info updates
    updateCustomer(name, email, phone, fax, defaultAddress) {
        mainStore.setProgress({ loading: true });
        axios.defaults.headers.common['Authorization'] = 'bearer ' + this.token;
        return axios.post('/api/inventory/updateCustomer', `userId=${ this.user.userId }&name=${ name }&email=${ email }&phone=${ phone }&fax=${ fax }&defaultAddress=${ defaultAddress }`, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(action((customer) => {
                this.user = customer.data;
            }))
            .catch((error) => {
                let data = error.response && error.response.data ? error.response.data : error;
                mainStore.setError(data.message);
                mainStore.setProgress({ loading: false });
            });
    }
}

const hydrate = create({
    storage: clientPersist,
    jsonify: true
});

export const authStore = new AuthStore();

hydrate('authStore', authStore).then(() => {
    authStore.setHydrated(true);
});

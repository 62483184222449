import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



//Custom imports
import { authStore } from '../../stores/AuthStore';

// Style
import './Home.css';
import { Customer, Company } from './Info';
import SkeletonLoader from '../UI/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

function CompanyInfo(props) {
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [contact, setContact] = useState(null);
    const [assignedCompanies, setAssignedCompanies] = useState(null)

    useEffect(() => {
        let token = authStore.token;
        if (token) {
            const { currentCompanyID, user } = props.authStore
            if(currentCompanyID){
                setLoading(true)
                authStore.fetchCustomer('customer',currentCompanyID)
                .then(() => {
                    authStore.fetchContact(currentCompanyID)
                    .then(() => {
                        setCustomer(toJS(authStore.customer))
                        setContact(toJS(authStore.contact))
                        setAssignedCompanies(authStore.userCompanies)
                        setLoading(false)
                    })
                })
            } else {
                user && authStore.fetchUserCompanies(user.email)
            }
        } else {
            window.location.href = '/login';
        }
    },[props.authStore])

    const handleChange = (e) => {
        this.setState({...this.state, [e.target.name]: e.target.value})
    }


    if(!contact?.giveAccess){
        if(assignedCompanies && assignedCompanies.length < 1){
            return <section className="box home">
                <div className="panel center">
                    <hgroup className="home__title">
                        <h1>Welcome to AA Metals!</h1>
                        <h3>
                            {
                                `The permissions for your user may have changed. Please contact your AA Metals representative for assistance regarding login access to the AA Metals Customer Portal.`
                            }
                        </h3>
                    </hgroup>
                </div>
            </section>
        }
    }

    return (
        <section className="box home">
            {
                loading && <div className="info">
                                <SkeletonLoader height={205}/>
                                <SkeletonLoader height={205} />
                            </div>
            }
            {
                contact?.id && customer?.userId && <div className="info">
                    <Customer data={contact} handleChange={handleChange}/> 
                    <Company data={customer} handleChange={handleChange}/>
                </div>
            }
        </section>
    );
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer((props) => <CompanyInfo history={ useNavigate() } {...props}/>));

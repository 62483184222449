// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --blue: #283A8E;
    --yellow: #FC0;
    --black: #000;
    --gray: #AAABAE;
    --purple: #2E2D75;
    --red: #880000;
    --green: #005500;
    --white: #fff;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui/_variables.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[":root {\n    --blue: #283A8E;\n    --yellow: #FC0;\n    --black: #000;\n    --gray: #AAABAE;\n    --purple: #2E2D75;\n    --red: #880000;\n    --green: #005500;\n    --white: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

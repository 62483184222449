/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import TagManager from 'react-gtm-module'

import List from "../Order/History/List/List";
import NewFilters from './Filters/NewFilters';
import LotsView from './LotsView';

import './InventorySummary.css';
import { mainStore } from '../../stores/MainStore';

function Stainless(props) {

    const [ data, setData ] = useState([]);
    const [ itemGroups, setItemGroups ] = useState([]);
    const [ availabilityList ] = useState(['In Stock', 'In Transit', 'Call Your Rep']);
    const [ locations, setLocations ] = useState([]);
    const [ gauge, setGauge ] = useState([]);
    const [ surface, setSurface ] = useState([]);
    const [ filteredData, setFilteredData ] = useState([]);
    // const [ columns, setColumns ] = useState([]);
    const [ itemId, setItemId ] = useState(null);
    const [ locationId, setLocationId ] = useState(null);
    const [ warehouseLocationId, setWarehouseLocationId ] = useState(null);
    const [ itemPrice, setItemPrice ] = useState(null);
    const [ description, setDescription ] = useState('');
    const [ itemNSId, setItemNSId ] = useState('');
    const [ rate, setRate ] = useState(0);
    const [ loading, setLoading ] = useState(true)

    //get shipping rates from store
    const { shippingRates, useAlternateAddress, selectedShipAddress, showLotsView, clearInventoryFilters  } = props.inventoryStore;

    const { customerDefaultShippingAddress } = props.authStore;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let token = props.authStore.token;
        if (token) {
            props.inventoryStore.toggleLotsView(false); // Clear the lots view overlay

            //get inventory data
            fetchData();
        } else {
            window.location.href = '/login';
        }
    }, [props.authStore.token, props.authStore.customer]);

    const fetchData = async () => {
        //make http requests
        await props.inventoryStore.fetchInventoryProductGroups('Stainless')
        await props.inventoryStore.fetchAvailableLocations()
        await props.inventoryStore.fetchProducts('Stainless')

        //get data from store
        const { inventorySearchData, itemGroups } = props.inventoryStore;
        
        //apply customer default shipping zone prices
        // const rates = toJS(await props.inventoryStore.getDynamicRates(inventorySearchData?.flattenedData))
        const rates = toJS(await props.inventoryStore.getDynamicRatesLocal(inventorySearchData?.flattenedData))

        //set loader off
        setLoading(false)
        
        //apply rates to state data
        const stateData = rates ? props.inventoryStore.applyDynamicRates(inventorySearchData?.flattenedData, rates) : []

        //apply rates to items
        const items = rates ? props.inventoryStore.applyDynamicRates(inventorySearchData?.items, rates) : []
        
        //set UI data
        setData(inventorySearchData ? stateData : []);
        setFilteredData(inventorySearchData ? items : []);
        
        //Filter configurations
        setItemGroups(inventorySearchData ? itemGroups : []);
        setLocations(inventorySearchData ? inventorySearchData?.locations : []);
        setGauge(inventorySearchData ? inventorySearchData?.gauge : [])
        setSurface(inventorySearchData ? toJS(inventorySearchData?.surface) : [])
    }
    
    const handleFilters = (filter) => {
        let itemGroup = filter.itemGroup;
        let location = filter.location
        delete filter['itemGroup'];
        delete filter['location']

        let groups = [], selectedGroups=[], locations = [], selectedLocations = [];
        //itemGroup can be multi-selected so we need to filter in a loop
        itemGroup.forEach((item) => {
            groups = data.filter((element) => element.itemGroup_name === item.value);
            selectedGroups.push(...groups)
        })

        location.forEach((item) => {
            locations = data.filter((element) => element.locationId === item.value);
            selectedLocations.push(...locations)
        })

        //Find the common elements between the selectedGroups and selectedLocations
        const selectedGroupdsAndLocations = selectedGroups.filter(value => selectedLocations.includes(value))
        
        //store the filtered data by item groups and locations into newData
        let newData = [ ...selectedGroups, ...selectedLocations].length > 0 ? [ ...selectedGroups, ...selectedLocations] : data;


        //if there are commen elements between selected item groups and selected item locations
        if(selectedGroupdsAndLocations.length > 0) {
            newData = selectedGroupdsAndLocations
        }

        //if there are no common elements while item groups and location has been selected. this will always result in an empty list
        if(selectedGroupdsAndLocations.length < 1 && itemGroup.length > 0 && location.length > 0) {
            newData = selectedGroupdsAndLocations;
        }

        if(location.length > 0 && locations.length < 1 && selectedGroupdsAndLocations.length < 1) {
            newData = []
        }
        //item group is selected but there are no items that match
        if(itemGroup.length > 0 && selectedGroups.length < 1) {
            newData = []
        }
        //all other filters apart from item groups and locations
        if(filter.gauge === 'Any' && filter.thickness === 'Any' && filter.surface === 'Any' && filter.availability === 'Any'){
            setFilteredData(newData);
        } else {
            let filtered = [];
            // console.log(filter, "filter")
            for(let key in filter){
                if(filter[key] !== 'Any'){
                    filtered = newData.filter((item) => item[key] === filter[key]);
                    newData = filtered;
                }
            }
            if(filtered.length < 1){
                setFilteredData(filtered);
                props.mainStore.setInfo(`Your selected filters did not match any products. Contact us to discuss specifications.`)
            } else {
                setFilteredData(filtered);
                props.mainStore.resetInfo()
            }
        }
    }

    const requestLots = (itemId, locationId, warehouseLocationId) => {
        let selectedItem = props.inventoryStore.inventorySearchData.items.find((element) => element.id === itemId && element.locationId === locationId);
        if(!selectedItem) {
            mainStore.setError('The item you clicked on does not exist. Place contact the system administrator')
            return;
        }

        props.inventoryStore.setIsPendingRequest(true);

        //set currently selected item properties
        setItemId(itemId);
        setDescription(selectedItem.description);
        setItemNSId(selectedItem.itemid);
        setRate(selectedItem.rate);
        setLocationId(locationId);
        setWarehouseLocationId(warehouseLocationId)
        setItemPrice(selectedItem.price)

        props.inventoryStore.toggleLotsView(true);
        props.inventoryStore.setIsPendingRequest(false);


        // Trigger the custom event with the item ID
        TagManager.dataLayer({
            dataLayer: {
                event: 'viewInventory',
                itemID: itemId,
                itemIDNetSuite: selectedItem.itemid
            }
        });
    }

    const viewLotsLink = (value) => {
        //split value to obtain item ID, rate, locationId and warehouseLocationId
        const values = value && value.split('-aam-');
        const ID = values && values[0];
        const locationId = values && values[1];
        const warehouseLocationId = values && values[2];
        return !value ? <div></div> : <div style={{ cursor: 'pointer'}} onClick={() => requestLots(ID,locationId, warehouseLocationId, itemPrice)}> <a style={{ textDecoration: "underline"}}> View Inventory </a> </div>
    }

    //table columns
    const columns = [
        {
            id: 'tag',
            accessorKey: 'tag',
            cell: info => info.getValue(),
            header: () => 'Product TAG',
            footer: info => info.column.id
        },
        {
            id: 'itemGroup_name',
            accessorKey: 'itemGroup_name',
            cell: info => info.getValue(),
            header: () => 'Product Group',
            footer: info => info.column.id
        },
        {
            id: 'item',
            accessorKey: 'item',
            cell: info => info.getValue(),
            header: () => 'Item Name',
            footer: info => info.column.id
        },
        {
            id: 'description',
            accessorKey: 'description',
            cell: info => info.getValue(),
            header: () => 'Description',
            footer: info => info.column.id,
            size: 250
        },
        {
            id: 'rate',
            accessorKey: 'rate',
            cell: info => info.getValue(),
            header: () => (shippingRates && shippingRates.length < 1) || customerDefaultShippingAddress?.shippingZone === 'FOB' ? 'Customer Pick Up Rate($)' : 'Delivered Rate ($)',
            footer: info => info.column.id
        },
        {
            id: 'availability',
            accessorKey: 'availability',
            cell: info => info.getValue(),
            header: () => 'Availability',
            footer: info => info.column.id 
        },
        {
            id: 'location',
            accessorKey: 'location',
            cell: info => info.getValue(),
            header: () => 'Location',
            footer: info => info.column.id
        },
        {
            id: 'checkAvailabilityWithID',
            accessorKey: 'checkAvailabilityWithID',
            cell: info => viewLotsLink(info.getValue()) ,
            header: () => 'Lots',
            footer: info => info.column.id
        }
    ]
    // console.log(availabilityList)
    // console.log(surface)
    return (
        <section className="box inventory">
            <NewFilters
                itemGroups={itemGroups}
                gauge = { gauge }
                surface = { surface }
                availabilityList={availabilityList}
                locations={locations}
                filter={ handleFilters }
                clearInventoryFilters={ clearInventoryFilters }
            />
            <div className="panel" id="order-items">
                {
                    shippingRates && 
                    shippingRates?.length < 1 && 
                    <span style={{ color: '#721C25',backgroundColor: '#F7D7D9', padding: '0.3rem', borderRadius:'0.2rem', zIndex: 9999 }}>
                        The rates on these items are Customer Pickup rates. Please call your Sales Rep to set up your primary shipping address and receive Delivery rates.
                    </span>
                }

                {
                    useAlternateAddress && 
                    <span style={{color: '#1A5B28',backgroundColor: '#D4EDD9', padding: '0.3rem', borderRadius:'0.2rem', zIndex: 9999}}>
                        Your alternate shipping address, <b>{ selectedShipAddress?.label }</b> is being used because your primary shipping address is misconfigured. Please contact your Sales Rep if you want to use the primary shipping address.
                    </span>
                }

                <List 
                    data={filteredData || []} 
                    columns={columns} 
                    title={"Inventory List : STAINLESS"}
                    description={`There are ${filteredData.length} metals`} 
                    type="inventory" 
                    rowSize={filteredData.length}
                    isLoading={loading || props.mainStore.progress?.loading}
                    noDataText={"No inventory records"} 
                />
            </div>
            { 
                showLotsView && 
                <LotsView 
                    hide={showLotsView} 
                    itemNSId={itemNSId} 
                    itemId={itemId} 
                    locationId={locationId}
                    warehouseLocationId={warehouseLocationId} 
                    description={description} 
                    rate={rate ? rate : 0}
                    itemPrice={itemPrice}
                /> 
            }
        </section>
    )
}

export default inject('mainStore', 'inventoryStore', 'authStore')(observer(Stainless));

import { reaction, makeAutoObservable } from 'mobx';

// Plugins
import axios from 'axios';

class MainStore {

    hydrated = false;
    lotsReady = false;
    lotsTimeout = false;
    progress = undefined;
    error = undefined;
    success = undefined;
    info = undefined;
    underMaintenance = process.env.REACT_APP_APP_MAINTENANCE_MODE;
    underMaintenanceStartDate = process.env.REACT_APP_APP_MAINTENANCE_START_DATE;
    underMaintenanceEndDate = process.env.REACT_APP_APP_MAINTENANCE_END_DATE;
    underMaintenanceShowMessage = process.env.REACT_APP_APP_MAINTENANCE_SHOW_MESSAGE;
    underMaintenanceHideMessage = process.env.REACT_APP_APP_MAINTENANCE_HIDE_MESSAGE;
    claims = process.env.REACT_APP_SHOW_CLAIMS;
    featureFlag = process.env.REACT_APP_FEATURE_FLAG;
    viewLots = process.env.REACT_APP_VIEW_LOTS;
    viewDashboardKPI = process.env.REACT_APP_VIEW_DASHBOARD_KPI;
    showCart = process.env.REACT_APP_SHOW_CART;
    deliveryRate = parseFloat(process.env.REACT_APP_DELIVERY_RATE);
    show_staging_warning = process.env.REACT_APP_SHOW_STAGING_WARNING;
    app_env = process.env.REACT_APP_APP_ENV;
    version = {
        app: process.env.REACT_APP_APP_VERSION,
        api: process.env.REACT_APP_API_VERSION
    };

    constructor() {
        // Global axios defaults
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;

        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.common['X-App-Version'] = this.version.app;
        axios.defaults.headers.common['Version'] = "";

        makeAutoObservable(this);

        reaction(
            () => this.error,
            (error) => {
                if (error) {
                    return setTimeout(() => this.resetError(), 100000);
                }
            }
        );

        reaction(
            () => this.success,
            (success) => {
                if (success) {
                    setTimeout(() => this.resetSuccess(), 10000);
                }
            }
        );

        reaction(
            () => this.info,
            (info) => {
                if (info) {
                    return this.resetInfo();
                }
            }
        );

        reaction(
            () => this.lotsTimeout,
            (lotsTimeout) => {
                if (lotsTimeout) {
                    this.progress = { loading: false };
                }
            }
        );
    }

    setHydrated(hydrated) {
        this.hydrated = hydrated;
    }

    forget() {
        this.hydrated = false;
        this.lotsReady = false;
        this.lotsTimeout = false;
        this.progress = undefined;
        this.error = undefined;
        this.success = undefined;
        this.info = undefined;
    }

    fetchVersion() {
        return Promise.resolve();
    }

    setLotsReady(lotsReady) {
        this.lotsReady = lotsReady;
    }

    setLotsTimeout(lotsTimeout) {
        this.lotsTimeout = lotsTimeout;
    }

    timeoutLotsReady(lotsTimeout, timeout) {
        setTimeout(() => {
            this.setLotsTimeout(lotsTimeout);
        }, timeout);
    }

    setProgress(progress) {
        this.progress = progress;
    }

    setError(error) {
        this.error = error;
    }

    resetError() {
        this.error = undefined;
    }

    setSuccess(success) {
        this.success = success;
    }

    resetSuccess() {
        this.success = undefined;
    }

    setInfo(info) {
        this.info = info;
    }

    resetInfo() {
        this.info = undefined;
    }
}

export const mainStore = new MainStore();

// A currency formatter function. It takes in the value, currency and countryCode
// e.g currencyFormatter(200,'USD','en-US') for US Dollar
export function currencyFormatter(value, currency, countryCode){
    let stringValue = value;
    if(value){
        stringValue = value.toString();
    }
    return new Intl.NumberFormat(countryCode, {
        style: "currency",
        currency: currency
    }).format(parseFloat(stringValue ? stringValue.slice(0, (stringValue.indexOf("."))+3) : 0))
}

export function waitFor(condition, callback) {
    if( ! condition()) {
        return new Promise((resolve) => {
            resolve(window.setTimeout(waitFor.bind(null, condition, callback), 100));
        });
    } else {
        return callback();
    }
}

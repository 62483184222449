import React, { Component } from 'react';

import "./FormComponents.css";

class Divider extends Component { 

    render(){
        return (
            <div className='divider'></div>
        )
    }
}

export default Divider;
import React from 'react';

// Style
import './Announcement.css';



function Announcement(props){    


    const { announcements, paddingTop, marginBottom } = props

    return (
        <div>
            { 
                announcements && announcements.length > 0 && <div className='panel announcement' style={{ paddingTop: paddingTop }}>
                    <div>
                        <h2 style={{ marginBottom: marginBottom }}>Announcements</h2>
                        <ul>
                            {
                                announcements.map((announcement, index) => <li key={`announcement-${index}`}> { announcement.message } </li>)
                            }
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
    
}
export default Announcement;

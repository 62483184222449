import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class BackgroundLoader extends Component {
    render() {
        return(
            <div>
                <span className="items-loader__message">loading items, please wait ...</span>
                <aside className="panel panel--items-loader">
                    
                </aside>
            </div>
        )
    }
}

export default inject('mainStore')(observer(BackgroundLoader));

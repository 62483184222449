import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Info extends Component {

    handleResetInfo = () => this.props.mainStore.resetInfo();

    render() {
        const { info } = this.props.mainStore;

        if (info) {
            return (
                <aside className="panel panel--header panel--information">
                    <div style={ { margin: 0 } }><span>{info}</span></div>
                    <div style={ { cursor: 'pointer', fontSize: '1.8rem' } } onClick={ (e) => this.handleResetInfo() }>&times;</div>
                </aside>
            );
        } else {
            return null;
        }
    }
}

export default inject('mainStore')(observer(Info));

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modals */
.modal {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;

    display: block;

    width: 100vw;
    height: 100vh;

    transition: opacity 0.2s ease;

    background-color: #212120ad;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.modal--child {
    position: absolute;

    width: 100%;
    height: 100%;
}

.modal__box {
    position: absolute;
    top: 50%;
    left: 50%;

    overflow-y: scroll; /* has to be scroll, not auto */

    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    -webkit-overflow-scrolling: touch;
}

.modal__title {
    font-size: 24px;

    margin: 0;
    padding: 30px 50px;

    color: #ffffff;
    background-color: var(--blue);
}

.modal__content {
    position: relative;

    padding: 30px 40px 10px;

    background-color: #ffffff;
}

.modal__close {
    position: absolute;
    top: -60px;
    right: 0;

    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ui/modals.css"],"names":[],"mappings":"AAAA,WAAW;AACX;IACI,eAAe;IACf,YAAY;IACZ,MAAM;IACN,OAAO;;IAEP,cAAc;;IAEd,YAAY;IACZ,aAAa;;IAEb,6BAA6B;;IAE7B,2BAA2B;;IAE3B,mCAAmC;YAC3B,2BAA2B;AACvC;;AAEA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;;IAET,kBAAkB,EAAE,+BAA+B;;IAEnD,wCAAwC;YAChC,gCAAgC;;IAExC,iCAAiC;AACrC;;AAEA;IACI,eAAe;;IAEf,SAAS;IACT,kBAAkB;;IAElB,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;;IAElB,uBAAuB;;IAEvB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;;IAER,gBAAgB;AACpB","sourcesContent":["/* Modals */\n.modal {\n    position: fixed;\n    z-index: 100;\n    top: 0;\n    left: 0;\n\n    display: block;\n\n    width: 100vw;\n    height: 100vh;\n\n    transition: opacity 0.2s ease;\n\n    background-color: #212120ad;\n\n    -webkit-backface-visibility: hidden;\n            backface-visibility: hidden;\n}\n\n.modal--child {\n    position: absolute;\n\n    width: 100%;\n    height: 100%;\n}\n\n.modal__box {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n\n    overflow-y: scroll; /* has to be scroll, not auto */\n\n    -webkit-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n\n    -webkit-overflow-scrolling: touch;\n}\n\n.modal__title {\n    font-size: 24px;\n\n    margin: 0;\n    padding: 30px 50px;\n\n    color: #ffffff;\n    background-color: var(--blue);\n}\n\n.modal__content {\n    position: relative;\n\n    padding: 30px 40px 10px;\n\n    background-color: #ffffff;\n}\n\n.modal__close {\n    position: absolute;\n    top: -60px;\n    right: 0;\n\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';


class Select extends Component { 
    
    render(){
        const { label, placeholder, name, onChange, data, className, value, disabled, noDefaults, required } = this.props

        return(
            <div className={`form-group ${disabled ? 'grayed-fields' : ''}`}>
                <label>{ label } { required && <span style={{ color: 'red'}}>*</span>}</label>
                <select placeholder={ placeholder } name={name} onChange={onChange} className={className} value={value} disabled={disabled}>
                    { noDefaults ? null : <option value="">Choose</option> }
                    {
                        data ? data.map((item,key) => <option key={`option-${key}`} value={item.value}>{item.text}</option>) : ''
                    }
                </select>
            </div>
        )
    }
}


export default Select;
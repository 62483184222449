import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import "./Info.css";

class Address extends Component {

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            //this.props.authStore.fetchCustomer('customer_rest_call')
        }
    }
    
    toggleList = () => {
        let panel = document.querySelector('.customer-address-list');
        panel.classList.toggle('active');
    }
    
    
    render() {
        const { defaultAddress } = this.props
        return (
            <div className="customer-address-list panel">
                <div className="panel-header">
                    <h3>ADDRESS LIST</h3> 
                    <i className="fas fa-angle-down" onClick={this.toggleList.bind(this)}></i>
                </div>
                <div className="info-address-list" id="address-panel">
                    <div className="address-list-heading">
                        <h3>label</h3><h3>Address</h3>
                    </div>
                    <div className="address-list">
                        <div className="address-label">Address (Primary)</div> 
                        <div className="address" dangerouslySetInnerHTML={{ __html: defaultAddress } }></div>
                    </div>
                    {
                        this.props.data?.map((address, key) => <div key={key} className="address-list"><div className="address-label" dangerouslySetInnerHTML={{ __html: address.label } }></div> <div className="address" dangerouslySetInnerHTML={{ __html: address.addrText } }></div></div>)
                    }
                </div> 
            </div>
        );
    }
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer(Address));
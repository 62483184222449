/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import './InventorySummary.css';
import AluminumCoil from './AluminumCoil';
import Stainless from './Stainless';


function InventorySearch(props) {
    const [ toggle, setToggle ] = useState(true);



    useEffect(() => {

        // function handleStorageChange(event) {
        //     // console.log(JSON.parse(localStorage.getItem('inventoryStore')).cartItems)
        // }

        // window.addEventListener('storage', handleStorageChange);

        return () => {
            // window.removeEventListener('storage', handleStorageChange);
        }
    },[])



    return (
        <section className='buy-metal-page'>
            <div className='buy-metal-toggle'>
                <div className="toggle-container">
                    <input type="radio" id="aluminum" name="material" checked={toggle} readOnly />
                    <label htmlFor="aluminum" className="toggle-label" style={{ zIndex: toggle ? 1 : 0 }} onClick={() => setToggle(true)}>Aluminum</label>
                    <input type="radio" id="stainless" name="material" checked={!toggle} readOnly/>
                    <label htmlFor="stainless" className="toggle-label" style={{ zIndex: !toggle ? 1 : 0 }} onClick={() => setToggle(false)}>Stainless</label>
                    <div className="toggle-indicator"></div>
                </div>
            </div>

            { toggle  && <AluminumCoil /> }
            { !toggle  && <Stainless /> }

            {/* {
                showNotice && announcements?.showNewFeatureNotice && <OverLayPanel hide={hideFeatureNotice}/>
            } */}
        </section>
    )
}

export default inject('mainStore', 'inventoryStore', 'cartStore', 'authStore')(observer(InventorySearch));

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//Plugins
import moment from 'moment';

import List from '../List/List';
import { currencyFormatter } from '../../../../stores/MainStore';

// Style
import '../OrderDetails.css';

class RelatedRecords extends Component {
    constructor(props){
        super(props)

        this.state = {
            title: "Detail View",
            invoiceData: {},
            itemfulfillmentData: {}
        }
    }
    
    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            if(this.props.orderStore.fetchedRelatedRecordsTranId !== this.props.match.params.id){
                this.fetchData(this.props.match.params.id)
            }
        } else {
            window.location.href = '/login';
        }
    }
    // componentDidUpdate(){
    //     this.props.mainStore.setProgress({ loading: false });
    // }

    async fetchData(tranId){
        let order = this.props.orderStore.orders.find((order) => order.tranId === tranId);

        this.props.orderStore.fetchRelatedRecords(order)
        .then(() => {
            this.props.orderStore.fetchItemfulfillmentBackground(order)
            .then(() => {
            })

        })
    }

    viewDetails(data){
        if((data.value).includes("IF")){
            this.setState({title: "Itemfulfillment", itemfulfillmentData: {...data.original}})
        }
        if((data.value).includes("INV")){
            this.setState({title: "Invoice", invoiceData: {...data.original}})
        }
    }

    createDetailedView(data){
        const { 
            tranid, 
            abbrevtype, 
            type, 
            trandisplayname, 
            trandate, 
            // createddate, 
            estgrossprofit,
            foreignamountpaid, 
            foreignamountunpaid, 
            foreigntotal,
            custbody_so_updated_due_date,
            duedate,
            // custbody_order_approval_email_sent,
            custbody_ship_date,
            custbody_so_approval_date,
            // daysopen,
            // daysoverduesearch,
            lastmodifieddate
        } = data


        let view = abbrevtype === "INV" ?
            <div>

                <div className="order-header">
                    <h2>{ this.state.title }</h2>
                    <div className="order-document-actions">
                        {/* <Link to={ `/order/pdfviewer/${ tranId }` } className="view-report">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link>
                        <div className="view-report">Download PDF <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } onClick={ (e) => this.handleFile(9) }/></div> */}
                    </div>
                </div>
                <div className="order-information">
                    <p>Invoice ID: <span>{ tranid }</span></p>
                    <p>Type: <span>{ type }</span></p>
                    <p>Transaction Name: <span>{ trandisplayname }</span></p>
                    <p>Transaction Date <span>{ trandate }</span></p>
                    {/* <p>Created Date: <span> { createddate }</span></p> */}
                    <p>Estimated Gross Profit: <span> { currencyFormatter(estgrossprofit,"USD","en-US") }</span></p>
                    <p>Foreign Amt Paid: <span> { currencyFormatter(foreignamountpaid,"USD","en-US")  }</span></p>
                    <p>Foreign Amt Unpaid: <span> { currencyFormatter(foreignamountunpaid,"USD","en-US")   }</span></p>
                    <p>Foreign Amt Total: <span> { currencyFormatter(foreigntotal,"USD","en-US")  }</span></p>
                    <p>Sales Order Updated Due Date: <span> { custbody_so_updated_due_date }</span></p>
                    <p>Due Date: <span> { duedate }</span></p>
                    {/* <p>Approval Email Sent: <span> { custbody_order_approval_email_sent }</span></p> */}
                    <p>Ship Date: <span> { custbody_ship_date }</span></p>
                    <p>Sales Order Approval Date: <span> { custbody_so_approval_date }</span></p>
                    <p>Sales Order Updated Due Date: <span> { custbody_so_updated_due_date }</span></p>
                    {/* <p>Days Open: <span> { daysopen }</span></p> */}
                    {/* <p>Days Over Due Search: <span> { daysoverduesearch }</span></p> */}
                    <p>Lastmodiefied Date: <span>{ lastmodifieddate }</span></p>
                </div>
            </div>
            :
            <List 
                items={this.generateItemfulfillments(this.props.orderStore.itemfulfillments.recordList[0].itemList.item)[0]} columns={this.generateItemfulfillments(this.props.orderStore.itemfulfillments.recordList[0].itemList.item)[1]} 
                noDataText={"Items Fulfilled"}
            />

            return view;
    }

    generateItemfulfillments(items){
        let itemsFulfilled = items
        .filter((item) => item.amountSpecified === true)
        .map((item) => { 
            return { 
                name: item.item.name, 
                quantity: item.quantity, 
                quantityBilled: item.quantityBilled, 
                quantityFulfilled: item.quantityFulfilled, 
                amount: item.amount, 
                rate: item.rate, 
                expectedShipDate: item.expectedShipDate
            } 
        })

        let columns = [
            {
                id: 'name',
                accessorKey: 'name',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'quantity',
                accessorKey: 'quantity',
                cell: info => info.getValue(),
                header: () => 'Qty',
                footer: info => info.column.id
            },
            {
                id: 'quantityBilled',
                accessorKey: 'quantityBilled',
                cell: info => info.getValue(),
                header: () => 'Qty Billed',
                footer: info => info.column.id
            },
            {
                id: 'quantityFulfilled',
                accessorKey: 'quantityFulfilled',
                cell: info => info.getValue(),
                header: () => 'Qty FulFilled',
                footer: info => info.column.id
            },
            {
                id: 'amount',
                accessorKey: d => { return currencyFormatter(d.amount,'USD','en-US') },
                cell: info => info.getValue(),
                header: () => 'Amt',
                footer: info => info.column.id
            },
            {
                id: 'rate',
                accessorKey: 'rate',
                cell: info => info.getValue(),
                header: () => 'Rate',
                footer: info => info.column.id
            },
            {
                id: 'expectedShipDate',
                accessorKey: d => { return moment(d.expectedShipDate).local().format("YYYY-MM-DD") },
                cell: info => info.getValue(),
                header: () => 'Expected Shipped Date',
                footer: info => info.column.id
            }
        ]

        return [itemsFulfilled, columns];
    }

    render() {
        const { relatedRecords } = this.props.orderStore;
        let records = relatedRecords.length === 0 ? [] : relatedRecords[0];

        records = records.concat(relatedRecords.length === 0 ? [] : relatedRecords[1]);

        let columns = [
            {
                id: 'tranid',
                accessorKey: 'tranid',
                cell: info => <div className="related-records-table-cell" onClick={() => this.viewDetails(info.getValue())}> {info.getValue()} </div>,
                header: () => 'Tranid',
                footer: info => info.column.id
            },
            {
                id: 'trandisplayname',
                accessorKey: 'trandisplayname',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'trandate',
                accessorKey: 'trandate',
                cell: info => info.getValue(),
                header: () => 'Tran Date',
                footer: info => info.column.id
            },
            {
                id: 'createddate',
                accessorKey: 'createddate',
                cell: info => info.getValue(),
                header: () => 'Created Date',
                footer: info => info.column.id
            }
       ]
                
        return (
            <div className="order-details-container">
                <div className="panel" id="order-related-records-list">
                    <List items={records} columns={columns} noDataText={"Related Records"}/>
                </div>
                <div className="panel" id="order-related-records-details">
                    {
                        this.state.title === "Invoice" ? this.createDetailedView(this.state.invoiceData) : this.state.title === "Itemfulfillment" ? this.creeateDetailedView(this.state.itemfulfillmentData) : ""
                    }
                </div>
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(RelatedRecords));

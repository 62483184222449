import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import List from '../../History/List/List';

// Style
import '../OrderDetails.css';

import Loader from '../../../UI/Loader';
import PlaceHolder from '../../../UI/PlaceHolder';
import { useNavigate } from 'react-router-dom';

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
    }
}
class Invoice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingRelatedRecords: true
        }
    }
    componentDidMount(){
        let token = this.props.authStore.token;
        if (token) {
            if(this.props.orderStore.fetchedRelatedRecordsTranId !== this.props.match.params.id){
                this.props.orderStore.resetItemshipsInvoices()
                this.fetchData({ tranId: this.props.match.params.id, internalId: this.props.match.params.internalId });
                setTimeout(() => {
                    this.props.mainStore.setProgress({ message: "Invoices query will take a little longer, Please be patient " });
                },6000)
            }else {
                //clear loading state if component remounts and the sales order id for the previous search has NOT changed
                this.setState({ isLoadingRelatedRecords: false })
            }
        } else {
            window.location.href = '/login';
        }
    }

    // componentDidUpdate(){
    //     this.props.mainStore.setProgress({ loading: false });
    // }

    async fetchData(orderData){
        this.props.orderStore.fetchOrder(orderData)
        .then(() => {
            this.fetchBackgroundData(orderData);
        });
    }

    async fetchBackgroundData(orderData){
        this.props.orderStore.fetchRelatedRecordsBackground(orderData)
        .then(() => {
            this.setState({ isLoadingRelatedRecords: false})
        });
    }

    // generateItems(){
    //     let items = this.props.orderStore.invoiceItems
    //     // .filter((item) => item.amountSpecified === true)
    //     .map((item) => { 
    //         return { 
    //             name: item.item.refName,
    //             location: item.location && item.location.refName,
    //             customPart: item.custcol_customer_part_number && item.custcol_customer_part_number, 
    //             description: item.description,
    //             quantity: item.quantity,
    //             weightlbs: item.custcol_weight_lbs,
    //             rate: item.rate, 
    //             truecost: item.custcol_inv_lot_cost,
    //             weight: item.custcol3,
    //             subtotal: item.custcol7
    //         } 
    //     })

    //     let columns = [
    //         { Header: 'Name', accessor: 'name' },
    //         { Header: 'Location', accessor: 'location' },
    //         { Header: 'Customer Part #', accessor: 'customPart' },
    //         { Header: 'Description', accessor: 'description' },
    //         { Header: 'Qty', accessor: 'quantity' },
    //         { Header: 'Weight/LBS', accessor: 'weightlbs' },
    //         { Header: 'Rate', accessor: 'rate' },
    //         { Header: 'True Cost', accessor: 'truecost' },
    //         { Header: 'Weigth', accessor: 'weight' },
    //         { id:'subtotal',Header: 'Subtotal', accessor: d => { return currencyFormatter(d.subtotal,'USD','en-US') }  }
    //     ]

    //     return [items, columns];
    // }

    viewDetails(path,tranId){
        this.props.orderStore.setCurrentInvoice(tranId);
        const { invoices } = this.props.orderStore;
        const invoice = invoices.find((element) => element.tranid = tranId)

        const url = path + '/' + invoice.id
        this.props.history(url);
    }

    render() {
        if(this.props.orderStore.relatedRecords.length === 0 && !this.props.orderStore.isPendingRequest) return <PlaceHolder text={`This Order, (${ this.props.match.params.id }) has no Invoices at the moment!`}/>;
        if(this.props.orderStore.invoices.length === 0) return <Loader default={true}/>; 
        
        const { invoices } = this.props.orderStore; 

        const columns = [
            {
                id: 'tranid',
                accessorKey: 'tranid',
                cell: info => <div className="related-records-table-cell"> <div onClick={ () => this.viewDetails(`/order/detail/invoice-details/${ this.props.match.params.id}`, info.getValue())}>{ info.getValue() }</div> </div>,
                header: () => 'TranId',
                footer: info => info.column.id
            },
            {
                id: 'trandisplayname',
                accessorKey: 'trandisplayname',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'trandate',
                accessorKey: 'trandate',
                cell: info => info.getValue(),
                header: () => 'Transaction Date',
                footer: info => info.column.id
            },
            {
                id: 'createddate',
                accessorKey: 'createddate',
                cell: info => info.getValue(),
                header: () => 'Created Date',
                footer: info => info.column.id
            }
        ]
        
        return (
            <div>
                <List 
                    columns={columns} 
                    data={invoices || []} 
                    noDataText={"No Invoice Records"} 
                    title={""}
                    description={`There are ${invoices && invoices.length} items`}
                />
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(withRouter(observer((props) => <Invoice history={useNavigate()} {...props}/>)));

import React from 'react';

// Plugins
import ReactTable from '../../../ReactTable/ReactTable';
import BackgroundLoader from '../../../UI/BackgroundLoader';

const List = ({ items, columns, noDataText, listType, rowSize, loadingItems }) => {
    return <div className="panel">
        <h3>There are { items.length } items</h3>
        <ReactTable
            data={ items }
            noDataText={loadingItems ? "" : `No ${ noDataText } found.`}
            columns={columns}
            defaultPageSize={ listType === 'inventory' ? rowSize : 10 } 
        />
        {loadingItems && <BackgroundLoader /> }
    </div>
};

export default List;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Loader extends Component {
    render() {
        const { progress } = this.props.mainStore;

        if(this.props.default){
            return(
                <div>
                    <span className="loader__message">{ progress && progress.message ? progress.message : 'Loading Please wait' }</span>
                    <div className="loader">
                        <div className="loader__inner"></div>
                    </div>
                </div>
            )
        }

        if(this.props.noText){
            return(
                <div>
                    <span className="loader__message"></span>
                    <div className="loader">
                        <div className="loader__inner"></div>
                    </div>
                </div>
            )
        }

        if(this.props.spinner){
            return <div className="loader__spinner"></div>
        }

        if (progress && progress.loading && progress.loading === true) {
            return (
                <div>
                    <span className="loader__message">{ progress && progress.message ? progress.message : 'Loading Please wait' }</span>
                    <div className="loader">
                        <div className="loader__inner"></div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default inject('mainStore')(observer(Loader));

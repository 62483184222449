import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

// Style
import './Details.css';

// Components
import List from './List/List';

function MTRPDFViewer(props){

    const params = useParams()

    useEffect(() => {
        let token = props.authStore.token;
        if (token) {
            props.orderStore.fetchMTRDownload({internalId: params.internalId},'view');
        } else {
            window.location.href = '/login';
        }

    },[props.authStore.token, params.internalId, props.orderStore])

    const goBack = (event) => {
        event.preventDefault();
        props.history(-1);
    }

    return (
        <section className="box order-details">
            <List order={{ tranId: params.id, internalId:params.internalId, type:params.type, fileName: params.fileName }} goBack={ goBack } />
        </section>
    );

}

export default inject('orderStore', 'inventoryStore', 'mainStore', 'cartStore', 'authStore')(observer((props) => <MTRPDFViewer history={useNavigate()} {...props}/>));

import React, { Component } from 'react';


class DateInput extends Component { 
    constructor(props){
        super(props)

        this.state = {
            month: "",
            day: "",
            year: "",
            invalidMonth: false, 
            invalidDay: false, 
            invalidYear: false 
        }

        this.onChangeMonth = this.onChangeMonth.bind(this)
        this.onChangeDay = this.onChangeDay.bind(this)
        this.onChangeYear = this.onChangeYear.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }
    UNSAFE_componentWillReceiveProps(){
        if(this.props.value && this.props.reset) {
            this.setState({ day:"", month: "", year:"" })
        }

        //this will clear the date fields
        if(this.props.value === "" && this.props.reset && `${this.state.day}${this.state.month}${this.state.year}` !== "") {
            this.setState({ day:"", month: "", year:"" })
        }
    }
    onBlur(e, mode){
        if(mode === "month" && /^(1[0-2]|0[1-9]|[1-9])$/.test(e.target.value)) {
            this.setState({ month: (e.target.value).padStart(2,"0"), invalidMonth: false }, () => {
                this.props.onChange(this.state)
            })
        }
        if(mode === "month" && !/^(1[0-2]|0[1-9]|[1-9])$/.test(e.target.value)) {
            this.setState({ invalidMonth: true })
        }

        if(mode === "day" && /^(0[0-9]|1[0-9]|2[0-9]|3[0-1]|[1-9])$/.test(e.target.value)) {
            this.setState({ day: (e.target.value).padStart(2,"0"), invalidDay: false }, () => {
                this.props.onChange(this.state)
            })
        }
        if(mode === "day" && !/^(0[0-9]|1[0-9]|2[0-9]|3[0-1]|[1-9])$/.test(e.target.value)) {
            this.setState({ invalidDay: true })
        }

        if(mode === "year" && /^(20[2-9][0-9])$/.test(e.target.value)) {
            this.setState({ year: (e.target.value).padStart(2,"0"), invalidYear: false }, () => {
                this.props.onChange(this.state)
            })
        }
        if(mode === "year" && !/^(20[2-9][0-9])$/.test(e.target.value)) {
            this.setState({ invalidYear: true })
        }
    }
    onChangeMonth(e) {
        this.setState({ month: e.target.value})
    }

    onChangeDay(e) {
        this.setState({ day: e.target.value})
    }

    onChangeYear(e) {
        this.setState({ year: e.target.value})
    }
    render(){
        const { 
            type, 
            label, 
            name, 
            altText, 
            className, 
            readOnly, 
            required, 
            border
        } = this.props
        const { month, day, year, invalidMonth, invalidDay, invalidYear } = this.state
        
        return (
            <div className='form-group'>
                <label htmlFor={ name }>{ label }{ required && <span style={{ color: 'red'}}>*</span>}</label>
                <div className='date-inputs' style={{ display: 'flex', gap: '10px'}}>
                    <input type={type} name={ 'month' } onChange={ this.onChangeMonth } placeholder={"MM"} value={month} onBlur={(e) => this.onBlur(e,'month')} className={invalidMonth || className ? 'error' : ''} maxLength={2} readOnly={readOnly} style={{ border: !border ? 'block' : 'none', width: '50px'}}/>
                    
                    <input type={type} name={ 'day' } onChange={ this.onChangeDay } placeholder={"DD"} value={day} onBlur={(e) => this.onBlur(e,'day')} className={invalidDay || className ? 'error' : ''} maxLength={2} readOnly={readOnly} style={{ border: !border ? 'block' : 'none', width: '50px'}}/>
                    
                    <input type={type} name={ 'year' } onChange={ this.onChangeYear } placeholder={"YYYY"} value={year} onBlur={(e) => this.onBlur(e,'year')} className={invalidYear || className ? 'error' : ''} maxLength={4}  readOnly={readOnly} style={{ border: !border ? 'block' : 'none', width: '100px'}}/>
                </div>
                <p>{altText}</p>
            </div>
        )
    }
}

export default DateInput;
import React, { Component } from 'react';


class RadioTextArea extends Component { 


    render(){
        const { id, name, value, onChange, className } = this.props
        return (
            <div className='form-group' id='other-form-group'>
                <div>
                    <input type="radio" id={id} name={name} value={value} onChange={onChange}/> <p className={className}>{value}</p>
                </div>
                <textarea name={id} cols="30" rows="5" placeholder='Placeholder Text' onChange={onChange}></textarea>
            </div>
        )
    }
}

export default RadioTextArea;
import React from 'react';


// Plugins
import ReactTable from '../../ReactTable/ReactTable';
import { createColumnHelper } from '@tanstack/react-table';  
import { currencyFormatter } from '../../../stores/MainStore';


const List = ({ items }) => {

    const columnHelper = createColumnHelper(items);

    return <div className="panel">
        <h3>There are { items.length } open quotes</h3>
        <ReactTable
            data={ items }
            noDataText="No open quotes found."
            columns={[
                columnHelper.accessor('tranid', {
                    cell: info => info.getValue(),
                    header: () => 'RFQ ID',
                    footer: info => info.column.id
                }),
                columnHelper.accessor('entitystatusname', {
                    cell: info => info.getValue(),
                    header: () => 'Status',
                    footer: info => info.column.id
                }),
                columnHelper.accessor('statusname', {
                    cell: info => info.getValue(),
                    header: () => 'Label',
                    footer: info => info.column.id
                }),
                columnHelper.accessor('createddate', {
                    cell: info => info.getValue(),
                    header: () => 'Date Issued',
                    footer: info => info.column.id
                }),
                columnHelper.accessor('expectedclosedate', {
                    cell: info => info.getValue(),
                    header: () => 'Expected Closed Date',
                    footer: info => info.column.id
                }),
                columnHelper.accessor('duedate', {
                    cell: info => info.getValue(),
                    header: () => 'Expiration Date',
                    footer: info => info.column.id
                }),
                {
                    id: 'totalcostestimate', 
                    Header: 'Total Cost', 
                    accessor: d => { return currencyFormatter(d.totalcostestimate, 'USD', 'en-US')}
                }
            ]}
            defaultPageSize={ 10 } />
    </div>
};

export default List;

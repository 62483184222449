import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import groupBy from 'lodash/groupBy';

// Style
import './InventorySummary.css';

import List from "./List/List";

class InventorySummary extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: [],
            locations: [],
            locationCombo: [],
            label: ""
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
        } else {
            window.location.href = '/login';
        }
    }

    // async fetchData(){
    //     this.props.inventoryStore.fetchInventorySummary()
    //     .then(() => {

    //         const groupedByItemNames = groupBy(this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.inventorySummary).tableData, function(item) {
    //             return item.description;
    //         });

    //        this.setState({ 
    //            data: this.removeDelimeters(this.prepareListData(groupedByItemNames)), //this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.inventorySummary).tableData,
    //            locations: [...this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.inventorySummary).uniqueLocations],
    //            locationCombo: [...this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.inventorySummary).uniqueLocations]
    //          })
    //     })
    // }
    // handleFilters = (filter) => {
    //     this.props.inventoryStore.filterSummaryData(filter);
    //     const groupedByItemNames = groupBy(this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.filteredInventorySummary).tableData, function(item) {
    //         return item.description;
    //     });
    //     this.setState({ 
    //         data: this.removeDelimeters(this.prepareListData(groupedByItemNames)), //this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.filteredInventorySummary).tableData,
    //         locations: [...this.props.inventoryStore.processSummaryResponse(this.props.inventoryStore.filteredInventorySummary).uniqueLocations]
    //     })
    // }

    prepareListData(data){
        let preparedData = []
        for(let i=0;i<Object.keys(data).length;i++){
            let obj = {status1:'', status2:'', status3:''}
            for(let j=0;j<data[Object.keys(data)[i]].length;j++){
                obj.description = data[Object.keys(data)[i]][j].description
                obj.type = data[Object.keys(data)[i]][j].type
                obj.locations = data[Object.keys(data)[i]][j].locations
                if(obj.status1 === '' || obj.status1 !== ''){
                    obj.status1 = obj.status1 + ';' + data[Object.keys(data)[i]][j].status1;
                }
                if(obj.status2 === '' || obj.status2 !== ''){
                    obj.status2 = obj.status2 + ';' + data[Object.keys(data)[i]][j].status2;
                }
                if(obj.status3 === '' || obj.status3 !== ''){
                    obj.status3 = obj.status3 + ';' + data[Object.keys(data)[i]][j].status3;
                }
            }
            preparedData.push(obj)
        }

        return preparedData;
    }

    removeDelimeters(items){
        items.forEach((el) => {
            el.status1 = el.status1.split(';').filter((item) => item !== "").join(' ; ')
            el.status2 = el.status2.split(';').filter((item) => item !== "").join(' ; ')
            el.status3 = el.status3.split(';').filter((item) => item !== "").join(' ; ')
        })
        return items
    }

    render() {
        // let labels = []
        let columns = [{ Header: 'Type', accessor: 'type' },{ Header: 'Description', accessor: 'description', style: { 'whiteSpace': 'unset' }  }];
        
        if(this.props.inventoryStore.inventorySummary){
            // labels = Object.keys(this.props.inventoryStore.inventorySummary);

            this.state.locations.forEach((location,key) => {
                columns.push({ Header: location, accessor: `status${key+1}` }); //Generate dynamic headers for table
            })
        }

        return (
            <section className="box inventory">
                {/* <Filters items={labels} locations={this.state.locationCombo} statusList={['In Stock', 'On Water']} filter={ this.handleFilters } /> */}
                <div className="panel" id="order-items">
                    <List items={this.state.data} columns={columns} label={this.state.label} listType="inventory" rowSize={this.state.data.length} noDataText={"Inventory Summary Data"}/>
                </div>
            </section>
        );
    }
}

export default inject('orderStore', 'mainStore', 'inventoryStore', 'authStore')(observer(InventorySummary));

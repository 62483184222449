// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.po-number {
  background: #eeeeee;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -5px;
}
.pdf-viewer {
  padding-top: 60px;
}
.pdf-viewer .pdf-zoom-buttons {
  display: flex;
  gap: 1rem;
  color: var(--blue);
  font-weight: bolder !important;
  cursor: pointer;
  text-transform: uppercase;
}
.react-pdf__Document {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30rem;
}
.pdf-next-prev-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.pdf-next-prev-buttons div {
  display: flex;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Order/Details/List/List.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".po-number {\n  background: #eeeeee;\n  padding-bottom: 4px;\n  padding-top: 4px;\n  padding-left: 4px;\n  padding-right: 4px;\n  margin-top: -5px;\n}\n.pdf-viewer {\n  padding-top: 60px;\n}\n.pdf-viewer .pdf-zoom-buttons {\n  display: flex;\n  gap: 1rem;\n  color: var(--blue);\n  font-weight: bolder !important;\n  cursor: pointer;\n  text-transform: uppercase;\n}\n.react-pdf__Document {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-left: 30rem;\n}\n.pdf-next-prev-buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 2rem;\n}\n.pdf-next-prev-buttons div {\n  display: flex;\n  gap: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';


class Radio extends Component { 


    render(){
        const { id, name, value, onChange, className, label } = this.props
        return (
            <div className='form-group'>
                <input type={"radio"} id={id} name={name} value={value} onChange={onChange}/> 
                <p className={className}>{ label }</p>
            </div>
        )
    }
}

export default Radio;
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import "./ClaimForm.css";

class Confirmation extends Component { 
    constructor(props){
        super(props)
        this.showClaims = this.showClaims.bind(this)
    }

    showClaims(){
        this.props.finish()
    }

    render(){

        return (
            <div className='panel claim'>
                <div className='confirmation'>
                    <p className='submission-text'>Thanks for Your Submission!</p>
                    {/* <p className='submission-message'>Your claim number is <span>{'claimNo'}</span> We will email you a claim confirmation with details and follow up response</p> */}
                    <p className='submission-message'>Email images and documentation to <a href='mailto:claims@aametals.com'>claims@aametals.com</a> with a reference to <b>RMA####</b> in the subject line. </p>
                    <button className='preview-button' onClick={() => this.showClaims()}>View claims list</button>
                </div>
            </div>
        )
    }
}

export default inject('cartStore', 'inventoryStore', 'mainStore', 'authStore')(observer(Confirmation));
import React, { Component } from 'react';

import "./ClaimForm.css";

class PreviewGroup extends Component { 

    render(){
        const { label, value } = this.props;
        return (
            <div>
                <div className='preview-group'>
                    <div id='key'>{label}</div><div id='value'>{value}</div>
                </div>
                <div className='divider'></div>
            </div>
        )
    }
}

export default PreviewGroup;
import React, { Component } from "react";
import { inject, observer } from 'mobx-react';
import CognitoForm from '@tylermenezes/cognitoforms-react';
import "./FeedBack.css"

class FeedBackForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetails : {}
        }
    }
    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            this.fetchLoggedInUser()
        } else {
            window.location.href = '/login';
        }
    }

    fetchLoggedInUser(){
        this.props.authStore.fetchLoggedInUserDetails()
        .then(() => {
            this.setState({ userDetails: { ...this.props.authStore.loggedInUserDetails }})
        })
    }

    render(){
        const { userDetails } = this.state
        
        let phone = ""
        if(userDetails && userDetails.success) {
            if(userDetails.phone || userDetails.mobilePhone || userDetails.officePhone) {
                if(userDetails.phone) {
                    phone = userDetails.phone ;
                }
                if(userDetails.mobilePhone) {
                    phone = userDetails.mobilePhone;
                }
                if(userDetails.officePhone) {
                    phone = userDetails.officePhone;
                }
            }
        }

        return(
            <section className="box home">
                <div className="panel feed-back-form" style={{ height: '150vh' }}>
                    {/* 
                        TODO:: Show a loader before cognito finally loads
                        reference https://github.com/tylermenezes/cognitoforms-react
                    */}
                    {/* <LoadingSpinner /> */}
                    
                    {
                        userDetails && userDetails.success && <CognitoForm
                            formId={1}
                            accountId={`7j7b-27Sf0y1DtEz_N_j1Q`}
                            prefill={{
                                Name: userDetails && userDetails.success ? userDetails.firstName + " " + userDetails.lastName : "",
                                Email: userDetails && userDetails.success ? userDetails.email : "",
                                Phone: phone
                            }}
                        />
                    }
                </div>
            </section>
        )
    }
}

export default inject( 'mainStore', 'authStore')(observer(FeedBackForm));
import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';


import SalesOrder from './Pages/SalesOrder';
import RequestForQuote from './Pages/RequestForQuote';
import RelatedRecords from './Pages/RelatedRecords';
import Invoice from './Pages/Invoice';
import InvoiceDetails from './Pages/InvoiceDetails';
import ItemFulfillment from './Pages/ItemFulfillment';
import ItemFulfillmentDetails from './Pages/ItemFulfillmentDetails';
import BillOfLadingDetails from './Pages/BillOfLadingDetails';
import RecentIFBillOfLadingDetails from './Pages/RecentIFBillOfLadingDetails';

import NotFound from '../../../components/Page/NotFound/NotFound';


class OrderDetailsRouter extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return (
            <Routes>
                <Route path="/order/:id/:internalId/*" match element={ <SalesOrder /> } />
                <Route path="/quote/:id/*" match element={ <RequestForQuote /> } />
                <Route path="/relatedrecords/:id/*" match element={ <RelatedRecords /> } />
                <Route path="invoice/:id/:internalId/*" match element={ <Invoice /> } />
                <Route path="/invoice-details/:id/:INV_ID/*" match element={ <InvoiceDetails /> } />
                <Route path="/itemfulfillment/:id/:internalId/*" match element={ <ItemFulfillment /> } />
                <Route path="/itemfulfillment-details/:id/:IF_ID/:truckIds/*" match element={ <ItemFulfillmentDetails /> } />
                <Route path="/itemfulfillment-bol/:id/*" match element={ <BillOfLadingDetails /> } />
                <Route path="/recent-itemfulfillment-bol/:id/*" match element={ <RecentIFBillOfLadingDetails /> } />
                <Route path="*" element={ <NotFound /> } />
            </Routes>
        );
    }

}

export default (observer(OrderDetailsRouter));

import "./PurchaseOrderBoard.css";

import { DELIVERY_METHOD, SHIPPING_METHOD, UNITS, US_STATES } from '../Order/OrderDetails/Pages/NetSuiteStatusValues';
import { DateInput, Divider, Input, Select, Spacer, FileUpload } from '../FormComponents';
import React, { Component } from 'react';
import { currencyFormatter, mainStore } from '../../stores/MainStore';
import { inject, observer } from 'mobx-react';
import { toJS } from "mobx";

import LoadingSipnner from '../UI/LoadingSipnner';
import SelectedLot from './SelectedLot';
import { sendSentryData } from '../../stores/Utils';
import { cartStore } from "../../stores/CartStore";

class PurchaseOrderForm extends Component {
    constructor(props){
        super(props)

        this.state = {
            cartItems: [],
            isSubmitting: false,
            resetDateInput: false,
            basicValues: {
                otherrefnum:"",
                custbodyDueDate:"",
                custbodyCustomerOrderMethod: "2",
                custbody6OrderCondition: "4",
                shipMethod: "",
                shipTo: "",
                custbodyMemoToSo: "",
                shippingAddressText: "",
                addressee: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip:"",
                attention: "",

                disabled: false,
                deliveryCharge: mainStore.deliveryRate,

                //Default delivery type is set to delivery
                deliveryType: 'delivery'
            },
            selectedFiles:[],
            isSubmittingClass: "",
            message: null,
            requiredFields: [
                'otherrefnum',
                'custbodyDueDate',
                'custbodyCustomerOrderMethod',
                'shipMethod',
                'shipTo',
                'shippingAddressText',
                'addressee',
                'address1',
                'city',
                'state',
                'zip'
            ],
            errorList: [],
            showCustomAddressForm: false,
            toggleShiptMethodRequiredStatus: true,
            disableCustomAddressFields: false,
            totalFileSize: 0,
            fileInputKey: Date.now()
        }

        this.targetRef = React.createRef();
        this.observer = null;

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onShipToChange = this.onShipToChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this)
        this.stateChange = this.stateChange.bind(this)

        this.handleIntersection = this.handleIntersection.bind(this) 
        this.handleFileChange = this.handleFileChange.bind(this)
        this.removeFile = this.removeFile.bind(this)
    }
    componentDidMount(){
        const options = {
            root: null, // use the viewport as the root
            rootMargin: '0px',
            threshold: 0.5 // how much of the target is visible before triggering
          };
      
          this.observer = new IntersectionObserver(this.handleIntersection, options);
          this.observer.observe(this.targetRef.current);

        //Retrieve current company ID, the entire cart and current company price level from local storage
        const { currentCompanyID, userCompanies } = this.props.authStore;
        let company = userCompanies.find((element) => element.companyId === currentCompanyID);

        const { cartItems } = this.props.inventoryStore;

        this.setState({ 
            cartItems: toJS(cartItems), 
            basicValues: { 
                ...this.state.basicValues,
                addressee: company?.companyName
            } })
    }

    componentWillUnmount() {
        if (this.observer) {
          this.observer.disconnect();
        }
    }

    componentDidUpdate() {
    
    }

    UNSAFE_componentWillReceiveProps() {
        //if company was switched, receive the resetForm props
        if(this.props.resetForm) {
            //refresh the address list in the form. This will get address list for the currently selected company
            //this.props.inventoryStore.setCustomerShippingAddressList()

            //reset the form fields
            this.setState({ 
                basicValues: { 
                    otherrefnum:"", 
                    custbodyDueDate:"", 
                    custbodyCustomerOrderMethod: "2",
                    custbody6OrderCondition: "4", 
                    shipMethod: "", 
                    shipTo:"", 
                    custbodyMemoToSo: "",
                    shippingAddressText:"",
                    addressee: "",
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip:"",
                    attention: "",

                    disabled: false, 
                    deliveryCharge: mainStore.deliveryRate,

                    //Default delivery type is set to delivery
                    deliveryType: 'delivery'
                },
                errorList: [],
                showCustomAddressForm: false,
                resetDateInput: true
            })

            //this is set the date fields into its default state to allow entrying of new date.
            //removing this will make the date fields not behave as expected
            this.setState({ resetDateInput: false })
        }
    }

    onSubmit(e){
        e.preventDefault()

        //Get cart items from mobxStore
        const { cartItems, selectedFiles } = this.state;

        //Retrieve current company ID, the entire cart and current company price level from local storage
        const { myCart } = this.props.cartStore
        const { currentCompanyID } = this.props.authStore;
        const { deliveryRatesMatrix } = this.props.inventoryStore;

         //Get delivery charge from form
         const { deliveryType, shipMethod, otherrefnum, custbodyCustomerOrderMethod, custbodyDueDate, shipTo  } = this.state.basicValues;


        //Filter current company cart items from the entire cart
        let companyCart = myCart ? myCart.filter((element) => element.companyID === currentCompanyID) : [];

        //create a new list of cart items
        let liveCartItems = []
        if(companyCart.length > 0){
            liveCartItems = [...companyCart[0].cartItems]
        } else {
        }
        liveCartItems = [...cartItems]

        //cart should not be empty
        if(liveCartItems.length < 1) {
            mainStore.setError("You cannot submit an empty PO")
            return;
        }

        //form field validations
        const { requiredFields, basicValues } = this.state
        let errorList = [...requiredFields]
        for(let key in basicValues){
            if(requiredFields.includes(key) && basicValues[key] === ''){
            } else {
                if(requiredFields.includes(key)){

                    errorList.splice(errorList.indexOf(key),1)
                }
            }
        }


        if(custbodyCustomerOrderMethod === '1') {
            errorList = errorList.filter(item => item !== 'shipTo')
        }

        this.setState({ errorList: errorList })

        if(errorList.length > 0 && this.state.showCustomAddressForm){
            return;
        }
        //if delivery, ensure that ship to Address field is populated

        if(errorList.length > 0 && errorList.includes('shipTo')){
            return;
        }

        console.log(custbodyCustomerOrderMethod, shipTo)
        if(custbodyCustomerOrderMethod === '2' && shipTo === "") {
            mainStore.setError("You have selected a wrong date. Please select a date in the future!");
            return;
        }

        //date validations
        let today = new Date();
        let selectedDate = new Date(`${basicValues.custbodyDueDate}`);

        if(selectedDate <= today) {
            mainStore.setError("You have selected a wrong date. Please select a date in the future!");
            return;
        }

        if(selectedDate.toString() === 'Invalid Date') {
            mainStore.setError("You have inputed a wrong date. Please select a date in MM/DD/YYYY format!");
            return;
        }
        
        //validate mandatory fields
        if(shipMethod === "" || otherrefnum === "" || custbodyCustomerOrderMethod === "" || custbodyDueDate === "" ) {
            mainStore.setError("Please fill all form fields. All are mandatory!")
            return;
        }

        //comments should be <= 4000 characters
        if (basicValues.custbodyMemoToSo.length >= 4000) {
            errorList.push('custbodyMemoToSo')
            mainStore.setError("Comments/Notes cannot be more than 4000 characters")
            return;
        }

        if(deliveryRatesMatrix.length < 1 && deliveryType === 'delivery') {
            mainStore.setError("You do not have the right delivery rates. Please reload your page to get the correct rates")
            return;
        }

        this.setState({ isSubmitting: true, isSubmittingClass: 'isSubmitting', message: 'Please be patient. Submitting an order takes several moments to complete ...' })

        let itemLots = [];
        let items = [];
        let sentryLotData = [];
        let sentryLots = []

        //format cart items to a key -> value pair

        const formatedCartItems = this.formatItemsPayload(liveCartItems);

        //retrieve item ids
        const objectKeysItemIds = Object.keys(formatedCartItems);

        //Loop through item Ids and prepare items with their lots from same or different location(s)
        objectKeysItemIds.forEach((itemId, index) => {

            //objectKeysItemLocationIds will be > 1 if item if from more than one location
            const objectKeysItemLocationIds = Object.keys(formatedCartItems[itemId]);

            //Loop through each item location and prepare the items payload
            objectKeysItemLocationIds.forEach((itemLocationId) => {
                //Get cart items using thier location
                let cartItemsByLocation = formatedCartItems[itemId][itemLocationId]
                let lots = [];
                let inventoryDetail = []
                let quantity = 0;
                let rate = 3;//this is set at 3 should incase everything else fails money wont be lost
                let custcol_so_truck_rate = 1; //this is set at 1 should incase everything else fails money wont be lost
                let locationId = "";

                cartItemsByLocation.forEach((element) => {
                    quantity += parseFloat(element.quantityavailable);
                    
                    //original rate of item is [current updated rate - deliveryCharge]
                    rate = parseFloat(element.price);
                    custcol_so_truck_rate = (parseFloat(element.rate) - parseFloat(element.price)).toFixed(2);

                    //store any of the location Ids
                    locationId = element.locationId;

                    //put all lots of an item into a list
                    lots.push({id: element.inventorynumber, qty: quantity.toFixed(2)});
                    sentryLots.push({ id: element.inventorynumber, qty: quantity.toFixed(2), item: element.item_id })

                    //Configure inventory detail for the sales order
                    inventoryDetail.push({ id: element.inventorynumber, qty: parseFloat(element.quantityavailable).toFixed(2), ocLotID: element.ocLotID, ocItemID: element.ocItemID })
                });
                //Create a list lots per each item
                itemLots.push({ item: itemId, line: index+1, linetype: "id", update: false, lots: lots });

                //Prepare sentry lot data that will be sent in the event of a dummy lot error in NetSuite
                sentryLotData.push(sentryLots)
                //Configure a list of items for the sales order in NetSuite
                items.push({
                    item: {id: itemId },
                    quantity: parseFloat(quantity.toFixed(2)),
                    rate: rate.toFixed(4), //this should be the base rate
                    price: -1,
                    custcol_customer_part_number: "",
                    custcol_ordered_qty: parseFloat(quantity.toFixed(2)),
                    location: locationId,
                    custcol_so_truck_rate: deliveryType === "pick-up" ? 0 : custcol_so_truck_rate,
                    lots: inventoryDetail
                });
            })
        });
        let customAddress = ""
        const { address1, address2, addressee, attention, city, zip, state } = this.state.basicValues;
        if(addressee) {
            attention && (customAddress += attention + '\r\n')
            customAddress += addressee + '\r\n'
            customAddress += address1 + '\r\n'
            address2 && (customAddress += address2 + '\r\n')
            customAddress += city + '\r\n'
            customAddress += state + '\r\n'
            customAddress += zip
        }
        //Prepare NetSuite payload
        let payLoad = {
            ...this.state.basicValues,
            entity: currentCompanyID,
            custbodyLotJson: JSON.stringify(itemLots),
            item: {
                items: items
            },
            customAddress: customAddress,
            uploadedFiles: JSON.stringify(selectedFiles),
            salesRepData: (this.props.authStore.customer && this.props.authStore.customer.salesRepData) || ""
        } 

        try {
            //Submit payload
            this.props.orderStore.submitSalesOrder(payLoad)
            .then(() => {

                //success is when salesOrderResponse is a number
                if (parseInt(this.props.orderStore.salesOrderResponse, 10)) {
                    this.setState({ resetDateInput: true })
                    // successful sales order
                    this.onSubmitSuccess();

                } else {
                    const { salesOrderResponse } = this.props.orderStore
                    //Parse error from NetSuite into a JSON Object
                    let err = salesOrderResponse ? JSON.parse(salesOrderResponse) : {};

                    if (err.reasoncode === 'BULK_SO_COMMIT_LOT_NUMBER_AVAILABLE_QUANTITY_VALIDATION') {
                        let messageList = err.message.split(" ");
                        messageList.forEach((element) => {
                            if(element.includes("Lot-")) {
                                let lotNumber = element.split("-")[1];
                                let lot = liveCartItems.filter((ele) => ele.inventorynumber === lotNumber)[0];
                                this.remove(lot)
                                mainStore.setError(`The LOT-${lot.inventorynumber} has already been purchase a moment ago so it has been removed from your order. Please add a new lot and submit again. Thank you`)
                            }
                        })
                        return;
                    }
                    // send error to UI
                    let errMessage = salesOrderResponse ? `There was an issue when submitting your purchase order. Please contact your sales rep and mention the following notice: ${err.message}` : "An unknown error occured. Please contact you sales rep.";
                    mainStore.setError(errMessage)
                    this.setState({ ...this.state, isSubmitting: false, isSubmittingClass: "" })

                    // prepare sentry transaction
                    sendSentryData(`submitPurchaseOrderErr :: Lot numbers :: ${JSON.stringify(sentryLotData)}`);
                }
            })
        } catch (error) {
            this.setState({ isSubmitting: false, isSubmittingClass: '' })
            mainStore.setError('We were unable to determine your user info at the moment. Please log out and log in again before you continue. Thank you.') 
        }

    }

    formatItemsPayload(items) {
        let groupedData = items.reduce((acc, obj) => {
            // Check if itemId key exists
            if (!acc[obj.item_id]) {
                // If not, create the key and assign an empty object to it
                acc[obj.item_id] = {};
            }
        
            // Check if location key exists for the current itemId
            if (!acc[obj.item_id][obj.locationId]) {
                // If not, create the key and assign an empty array to it
                acc[obj.item_id][obj.locationId] = [];
            }
            // Push the current object into the array corresponding to the location
            acc[obj.item_id][obj.locationId].push(obj);
            return acc;
        }, {});
        
        return groupedData;
    }

    onSubmitSuccess() {
        mainStore.setSuccess(`Purchase order ${ this.state.basicValues.otherrefnum } has been created successfully!`)
        this.setState({ 
            isSubmitting: false,
            showCustomAddressForm: false, 
            isSubmittingClass: "",
            resetDateInput: true, 
            basicValues: { 
                shipMethod: "",
                shipTo:"", 
                shippingAddressText:"", 
                disabled: false,
                otherrefnum: "", 
                custbodyCustomerOrderMethod:"2", 
                custbody6OrderCondition:"4", 
                custbodyDueDate: "", 
                custbodyMemoToSo:"",
                state: "",
                address1: "",
                address2: "",
                addressee: "",
                city: "",
                zip:"",
                attention: ""
            },
            selectedFiles:[],
            fileInputKey: null
        })
        this.goBack()
        this.props.inventoryStore.toggleLotsView(false);
        this.props.inventoryStore.clearFilters(true);
        this.props.inventoryStore.slideCartIn()
        this.props.inventoryStore.clearCart()
        this.props.cartStore.clearCompanyCart()
        this.props.inventoryStore.setSelectedShipAddress(undefined)
        this.props.inventoryStore.slideViewIn('purchase-order-confirmation')
        
        //allow users to input date on the next CONSECUTIVE PO creation
        this.setState({ resetDateInput: false })
    }

    goBack(){
        const element = document.getElementById("purchase-order-form")
        element.style.right = "-3000px";
        element.style.transition = "0.5s ease-in-out";

        this.props.inventoryStore.openCheckoutPage(false)
    }

    remove(item){
        this.setState({ isSubmitting: true})
        this.props.cartStore.removeCartLineItem(item.ocCartLineItemID)
        .then(() => {
            this.setState({ isSubmitting: false})
            //Set Cart in Local
            this.props.inventoryStore.unSetCartItem(item.inventorynumber,'remove');
            
            const { cartItems } = this.props.inventoryStore;
            const { basicValues } = this.state

            this.setState({
                cartItems
            })

            //when cart is emptied, clear all errors
            if(cartItems.length === 0) {
                this.setState({ 
                    errorList: [], 
                    basicValues: { 
                        ...basicValues, 
                        shipTo: "", 
                        shipMethod: "",
                        addressee: "",
                        shippingAddressText:"",
                        custbodyMemoToSo: "",
                        otherrefnum: "",
                        custbodyDueDate: "" 
                    }, 
                    showCustomAddressForm: false,
                    resetDateInput: true
                })
                this.props.inventoryStore.slideViewOut('purchase-order-form')

                //this is set the date fields into its default state to allow entrying of new date.
                //removing this will make the date fields not behave as expected
                this.setState({ resetDateInput: false })
            }

        })
    }

    onChange(e){
        if(e.target.name === "shipMethod"){
            if(this.state.basicValues.custbodyCustomerOrderMethod === "2" && e.target.value === "3020") {
                mainStore.setError("You cannot select Pick Up ship method when Delivery is selected. Please select another Ship Method")
                return;
            }
        }

        if(e.target.name === "zip") {
            //test if user input is valid
            const valid = !/^\d{5}$/.test(e.target.value);

            //set validity and errorList in state 
            this.setState({ disableCustomAddressFields: valid, errorList: valid ? ['zip'] : [] })
        }

        this.setState({ basicValues: { ...this.state.basicValues, [e.target.name]: e.target.value } })
    }

    onDateChange(value){
        let date = `${value.year}-${value.month}-${value.day}`
        if(date.length === 10) {
            this.setState({ basicValues: { ...this.state.basicValues, custbodyDueDate: date } })
        }
    }

    async onSelectChange(e){
        const { selectedShipAddress, shippingRates } = toJS(this.props.inventoryStore);
        const { customerShippingAddress, customerDefaultShippingAddress } = this.props.authStore;
        
        let requiredFields = [...this.state.requiredFields]
        let shipMethod = ""
        let shipTo = ""
        let shippingAddressText = ""
        let disabled = false
        let deliveryCharge = mainStore.deliveryRate;
        let custbody6OrderCondition = "4";
        let custbodyCustomerOrderMethod = e.target.value;
        let toggleShiptMethodRequiredStatus = true;
        let showCustomAddressForm = false
        let disableCustomAddressFields = true;

        //Get initial delivery type from state
        let deliveryType = this.state.basicValues.deliveryType;

        //if ship method is customer pickup
        if(e.target.name === "custbodyCustomerOrderMethod" && custbodyCustomerOrderMethod === "1") {
            deliveryCharge = 0.00
            custbody6OrderCondition = "6"
            shipMethod = "3020"
            shippingAddressText = "Customer Pick Up"
            disabled = true
            toggleShiptMethodRequiredStatus = false
            showCustomAddressForm=false
            disableCustomAddressFields = false;
            requiredFields = [
                'otherrefnum',
                'custbodyDueDate',
                'custbodyCustomerOrderMethod',
                'shipMethod',
                'shippingAddressText',
                'addressee',
                'address1',
                'city',
                'state',
                'zip'
            ];

            //When delivery type is pick-up
            deliveryType = 'pick-up';

            //check if there is an FOB address
            if(customerDefaultShippingAddress?.shippingZone === 'FOB') {
                // shipTo = customerDefaultShippingAddress?.internalId
                shippingAddressText = customerDefaultShippingAddress?.addrText
            }
        }
        
         //if ship method is delivery
        if(e.target.name === "custbodyCustomerOrderMethod" && custbodyCustomerOrderMethod === "2") {
            let shipToAddress = customerShippingAddress.find((element) => element.defaultShipping === true)

            //if default shipping address was found but it is configured wrongly i.e. shippingRates is empty
            if(shipToAddress && shippingRates && shippingRates.length === 0) {
                // select an alternate shipping address
                shipToAddress = customerShippingAddress.find((element) => element.defaultShipping === false && (element.state !== "" || element?.shippingZone !== ""));
                
                shipTo = shipToAddress?.internalId
                shippingAddressText = shipToAddress?.addrText
                disableCustomAddressFields = false
                
                await this.fetchShippingRates(shipToAddress);
                
                //if no valid ship address was found,
                if(!shipToAddress) {
                    mainStore.setError(`No valid alternate shipping address was found. Please provide a custom address or call your Sales Rep.`)
                    shipTo = "-2";
                    shippingAddressText = 'Custom Address';
                    showCustomAddressForm = true;
                } else {
                    mainStore.setSuccess(`Delivery rates have been applied to this order using the shipping address :: <b>${shippingAddressText}</b>`)
                }
            }

            //if default shipping address was found and it is configured correctly i.e. shippingRates is not empty
            if(shipToAddress && shippingRates && shippingRates.length > 0) {
                shipTo = selectedShipAddress ? selectedShipAddress?.internalId : shipToAddress?.internalId
                shippingAddressText = selectedShipAddress ? selectedShipAddress?.addrText : shipToAddress.addrText
                disableCustomAddressFields=false
            }

            if(!shipToAddress && shippingRates && shippingRates.length === 0) {
                shipTo = '-2'
                shippingAddressText = 'Custom Address';
                showCustomAddressForm = true;
                disableCustomAddressFields=false;
            }
            if(customerDefaultShippingAddress?.shippingZone === 'FOB') {
                shipTo = "" //customerDefaultShippingAddress?.internalId
                shippingAddressText = customerDefaultShippingAddress?.addrText
            }
            

            requiredFields = [
                'otherrefnum',
                'custbodyDueDate',
                'custbodyCustomerOrderMethod',
                'shipMethod',
                'shipTo',
                'shippingAddressText',
                'addressee',
                'address1',
                'city',
                'state',
                'zip'
            ];

            //When delivery type is delivery
            deliveryType = 'delivery';
        }
        //set state 
        this.setState({
            basicValues: {
                ...this.state.basicValues,
                custbodyCustomerOrderMethod: custbodyCustomerOrderMethod,
                custbody6OrderCondition:  custbody6OrderCondition,
                shipMethod: shipMethod,
                shipTo: shipTo,
                shippingAddressText: shippingAddressText,
                disabled: disabled,
                deliveryCharge: deliveryCharge,
                deliveryType: deliveryType
            },
            requiredFields,
            toggleShiptMethodRequiredStatus,
            showCustomAddressForm,
            disableCustomAddressFields
        })
    }

    stateChange(e) {
        if(e.target.value === "") {
            this.setState({ 
                basicValues: { 
                    ...this.state.basicValues,
                    state: ""
                }  
            })
            return
        }
        this.setState({ 
            // isSubmitting: true, 
            // isSubmittingClass: 'isSubmitting', 
            // message: `Retrieving shipping rates for ${e.target.value}. Please wait ...`,
            basicValues: { 
                ...this.state.basicValues,
                state: e.target.value
            }  
        })

        //Get cartItems and shipping address from store
        const { cartItems } = this.state
        
        //Convert cartItems to js array
        const _cartItems = toJS(cartItems) || [];

        //create a unique collections of all the warehouse location ids. 
        const uniqueWarehouseLocationIds = this.props.inventoryStore.getUniqueWarehouseLocationIds(_cartItems);

        //Create custom data structure and send to rates endpoint to get appropriate rates
        const dynamicRatesPayload = { 
            shippingZone: "",
            state: e.target.value || "",
            locationid: uniqueWarehouseLocationIds 
        };
        const dynamicRatesResponse = this.props.inventoryStore.getShippingRatesByWarehouseLocationLocal(dynamicRatesPayload);
        if(dynamicRatesResponse && dynamicRatesResponse.length < 1) {
            mainStore.setError(`We do not have a shipping zone in <b>${e.target.value}</b> at the moment. Please contact your sales rep at AA Metals.`);
            
            this.setState({ 
                // isSubmitting: false, 
                // isSubmittingClass: '', 
                // message: "",
                basicValues: { 
                    ...this.state.basicValues,
                    state: ''
                }   
            })
            
            return;
        }

        let newCheckoutItems = []
        dynamicRatesResponse.forEach((dynamicRate) => {
            const checkoutItems = cartItems.filter((cartItem) => cartItem.warehouseLocationId === (dynamicRate.lotLocationID).toString())
            checkoutItems.forEach((checkoutItem) => {
                checkoutItem.rate = checkoutItem.price + dynamicRate.rate

                newCheckoutItems.push(checkoutItem)
            })
        })
        this.setState({ 
            // isSubmitting: false, 
            // isSubmittingClass: '', 
            // message: "", 
            checkoutItems: newCheckoutItems
        })

        mainStore.setSuccess(`Rates have been updated successfully`);
    }
    
    async onShipToChange(e){
        // //Retrieve current company ID, the entire cart and current company price level from local storage
        const { currentCompanyID, userCompanies } = this.props.authStore;

        //find currently selected company
        let company = userCompanies.find((element) => element.companyId === currentCompanyID);

        // //Get shipping address from store
        const { customerShippingAddress } = this.props.authStore;

        let shipToAddress = customerShippingAddress.find((element) => element.internalId === e.target.value)

        //if customer selects -Custom-
        if(e.target.value === "-2"){
            const shipTo = e.target.value
            const shippingAddressText = "Custom Address"
            const addressee = company.companyName
            this.setState({ 
                showCustomAddressForm: true, 
                basicValues: {
                    ...this.state.basicValues,
                    addressee: addressee,
                    shipTo: shipTo,
                    shippingAddressText: shippingAddressText,
                    zip:""
                },
                disableCustomAddressFields: true 
            })
            return
        } else {
            this.setState({
                disableCustomAddressFields: false,
                basicValues: {
                    ...this.state.basicValues,
                    state: "",
                    address1: "",
                    address2: "",
                    addressee: "",
                    city: "",
                    zip:"",
                    attention: ""
                }
            })
        }

        //if notthing was selected
        if(e.target.value === "") {
            this.setState({ 
                showCustomAddressForm: false, 
                ...this.state.basicValues,
                addressee: "",
                shipTo: "",
                shippingAddressText: "" 
            })
            return;
        }
        
        //make request
        await this.fetchShippingRates(shipToAddress)
    }
    handleFileChange(e) {
        const files = Array.from(e.target.files);

        //instantiate a file reader
        const reader = new FileReader();
        reader.onload = () => {
            //get the base64 encoding because that is what NetSuite wants
            const base64 = reader.result.split(',')[1];
            const allowedFileTypes = ['pdf','msword','vnd.openxmlformats-officedocument.wordprocessingml.document','vnd.ms-excel','vnd.openxmlformats-officedocument.spreadsheetml.sheet']
            if(!allowedFileTypes.includes(files[0]?.type.split('/')[1])) {
                mainStore.setError(`.${files[0]?.type.split('/')[1]} files are not supported. Upload only .pdf,.docx,.doc,.xls or .xlsx files.`)
                return;
            }
            
            
            //prepare file data structure
            const fileNameTokens = files[0]?.name.split('.')
            const file = { name: `${fileNameTokens[0]}-${Date.now()}.${fileNameTokens[1]}`, fileType: files[0]?.type.split('/')[1], fileContent: base64, fileSize: files[0]?.size }
            
            //concatenate previous uploaded files and current file
            let allFiles = [...this.state.selectedFiles, file]

            if(allFiles.length > 5) {
                mainStore.setError('You are allowed to upload only 5 files')
                this.setState({ fileInputKey: Date.now() })
                return;
            }

            //aloow users to upload only 5 files
            if(this.state.totalFileSize + files[0]?.size  > 5000000) {
                mainStore.setError('The total upload size should be < 5MB!')
                this.setState({ fileInputKey: Date.now() })
                return;
            }
            else {
              this.setState({ selectedFiles: allFiles, totalFileSize: this.state.totalFileSize + files[0]?.size })
            }
        };
        reader.readAsDataURL(files[0]);
    };

    removeFile(fileName) {
        const { selectedFiles } = this.state

        //get remaining selected files
        const files = selectedFiles.filter((selectedFile) => selectedFile?.name !== fileName)

        //get removed file
        const removedFile = selectedFiles.find((selectedFile) => selectedFile?.name === fileName)

        this.setState({ selectedFiles: files, totalFileSize: this.state.totalFileSize - removedFile.fileSize, fileInputKey: Date.now() })
    }

    async fetchShippingRates (shipToAddress) {
        //Get cartItems from store 
        const { cartItems } = this.state
        
        //Convert cartItems to js array
        const _cartItems = toJS(cartItems) || [];

        //create a unique collections of all the warehouse location ids. 
        const uniqueWarehouseLocationIds = this.props.inventoryStore.getUniqueWarehouseLocationIds(_cartItems);
        
        //Retrieve current company ID, the entire cart and current company price level from local storage
        const { currentCompanyID, userCompanies } = this.props.authStore;
        
        //if session variables were NOT set properly
        if(!currentCompanyID || !userCompanies) {
            mainStore.setError('We were not able to determine your company shipping address info at this time. Please log out and log in again before you continue.')
            return;
        }

        let shipTo = ""
        let shippingAddressText = ""
        let addressee = ""

        // if an address is found. this will always be true
        if(shipToAddress) {

            //start loading process
            this.setState({ 
                // isSubmitting: true, 
                // isSubmittingClass: 'isSubmitting', 
                // message: `Retrieving shipping rates for ${shipToAddress?.addrText}. Please wait ...` ,
                basicValues: {
                    ...this.state.basicValues,
                    state: "",
                    city: "",
                    attention: "",
                    address1: "",
                    address2: ""
                }
            })
            
            //get ship zone and state from address
            let shippingZone = shipToAddress?.shippingZone;
            let state = shipToAddress?.state;

            //if the selected address is not configured properly. This should be very rare
            if(!shippingZone && !state) {
                //show this message to customer
                mainStore.setError(`The selected address, <b>${shipToAddress?.addrText}</b> is not configured properly. Please contact your Sales Rep.`);
                
                // this.setState({ isSubmitting: false, isSubmittingClass: '', message: "" })
                
                return;
            }
            //Create custom data structure and send to rates endpoint to get appropriate rates
            const dynamicRatesPayload = { 
                shippingZone: shippingZone || "",
                state: state || "",
                locationid: uniqueWarehouseLocationIds 
            };

            //request for rates
            const dynamicRatesResponse = this.props.inventoryStore.getShippingRatesByWarehouseLocationLocal(dynamicRatesPayload);
           
            if(dynamicRatesResponse && dynamicRatesResponse.length < 1) {
                mainStore.setError(`The selected address, <b>${shipToAddress?.addrText}</b> does not match the configured shipping zones in our system. We will use <b>${this.state.basicValues.shippingAddressText}</b> for shipping or Please contact your Sales Rep.`);
                
                // this.setState({ isSubmitting: false, isSubmittingClass: '', message: "" })
                
                return;
            }
            this.props.inventoryStore.setSelectedShipAddress(shipToAddress);

            let newCheckoutItems = []

            //update checkout items with new rates
            dynamicRatesResponse.forEach((dynamicRate) => {
                const checkoutItems = cartItems.filter((cartItem) => cartItem.warehouseLocationId === (dynamicRate.lotLocationID).toString())
                checkoutItems.forEach((checkoutItem) => {
                    checkoutItem.rate = parseFloat(checkoutItem.price) + parseFloat(dynamicRate.rate)

                    newCheckoutItems.push(checkoutItem)
                })
            })
            // this.setState({ isSubmitting: false, isSubmittingClass: '', message: "", checkoutItems: newCheckoutItems })
            shipTo = shipToAddress?.internalId
            shippingAddressText = shipToAddress?.addrText
            this.setState({
                // isSubmitting: false, 
                // isSubmittingClass: '', 
                // message: "", 
                checkoutItems: newCheckoutItems,  
                showCustomAddressForm: false, 
                basicValues: {
                    ...this.state.basicValues,
                    addressee: addressee,
                    shipTo: shipTo,
                    shippingAddressText: shippingAddressText,
                    state: "",
                    city: "",
                    attention: "",
                    address1: "",
                    address2: ""
                } 
            })

            if(shipToAddress?.shippingZone === "FOB") {
                this.setState({
                    disableCustomAddressFields: false,
                    basicValues: {
                        ...this.state.basicValues,
                        deliveryType: 'pick-up',
                        custbodyCustomerOrderMethod: "1",
                        // shipTo: shipToAddress?.internalId,
                        shippingAddressText: shipToAddress?.addrText,
                        disabled: true
                    }
                })
            }
            return true
        }
    }

    // async getDynamicRates(data) {
    //     //get item delivery rates using customer default shipping address
    //     const uniqueWarehouseLocationIds = this.props.inventoryStore.getUniqueWarehouseLocationIds(data);
    //     const { selectedShipAddress } = this.props.inventoryStore
    //     const { customerDefaultShippingAddress } = this.props.authStore

    //     //Create custom data structure and send to rates endpoint to get appropriate rates
    //     const dynamicRatesPayload = { 
    //         shippingZone: selectedShipAddress ? (selectedShipAddress?.shippingZone || "") : (customerDefaultShippingAddress?.shippingZone || ""),
    //         state: selectedShipAddress ? (selectedShipAddress?.state || "") : (customerDefaultShippingAddress?.state || ""),
    //         locationid: uniqueWarehouseLocationIds 
    //     };
    //     const dynamicRatesResponse = this.props.inventoryStore.getShippingRatesByWarehouseLocationLocal(dynamicRatesPayload)

    //     return dynamicRatesResponse;
    // }

    async handleIntersection(entries){
        
        for(let i=0; i < entries.length; i++) {

          if (entries[i].isIntersecting) {
            // When the component enters the viewport
            this.setState({ 
                // isSubmitting: true, 
                // isSubmittingClass: 'isSubmitting', 
                // message: `Retrieving checkout items. Please wait ...`,
                showCustomAddressForm: false,
                basicValues: { 
                    ...this.state.basicValues,
                    shipMethod: "",
                    deliveryType: "delivery",
                    state: "",
                    address1: "",
                    address2: "",
                    addressee: "",
                    city: "",
                    zip:"",
                    attention: ""
                }
            })

            //get cart items and fresh prices
            await cartStore.getCartItems()

            const { cartItems, selectedShipAddress, shippingRates, useAlternateAddress } = this.props.inventoryStore;
            const { customerDefaultShippingAddress } = toJS(this.props.authStore)

            //apply customer default shipping zone prices
            const rates = toJS(this.props.inventoryStore.getDynamicRatesLocal(cartItems, 'checkout'))
            
            //apply rates to state data
            let stateData = rates ? this.props.inventoryStore.applyDynamicRates(cartItems, rates) : []

            if((shippingRates && shippingRates.length < 1) || (customerDefaultShippingAddress?.shippingZone).toLowerCase() === 'fob') {
                mainStore.setError("Your order is set for Customer Pick Up. For Delivery orders, please choose an alternate address or contact your Sales Rep.")
            }

            if(useAlternateAddress) {
                mainStore.setSuccess(`Your alternate shipping address, <b>${ selectedShipAddress?.label }</b>, is being used because your primary shipping address is misconfigured. Please contact your Sales Rep.`)
            }

            //Preset shipping address text and ship address for customers without FOB address
            let shippingAddressText = 'Customer Pick Up';
            let shipTo = "" 
            if(customerDefaultShippingAddress?.shippingZone === 'FOB') {
                // shipTo = customerDefaultShippingAddress?.internalId
                shippingAddressText = customerDefaultShippingAddress?.addrText
            }

            this.setState({ 
                cartItems: stateData,
                // isSubmitting: false, 
                // isSubmittingClass: '', 
                // message: '',
                // showCustomAddressForm:  shippingRates && shippingRates.length < 1 ? true : false,
                toggleShiptMethodRequiredStatus: true,
                basicValues: { 
                    ...this.state.basicValues,
                    custbodyCustomerOrderMethod: (shippingRates && shippingRates.length < 1 )|| (customerDefaultShippingAddress?.shippingZone === 'FOB') ? "1" : "2",
                    shipMethod: (shippingRates && shippingRates.length < 1) || (customerDefaultShippingAddress?.shippingZone === 'FOB') ? "3020" : this.state.basicValues?.shipMethod,
                    disabled: (shippingRates && shippingRates.length < 1) || (customerDefaultShippingAddress?.shippingZone === 'FOB') ? true : false, 
                    shipTo: selectedShipAddress ? selectedShipAddress?.internalId  : shipTo,
                    shippingAddressText: (shippingRates && shippingRates.length < 1) || (customerDefaultShippingAddress?.shippingZone === 'FOB') ? shippingAddressText : selectedShipAddress ?  selectedShipAddress?.addrText : customerDefaultShippingAddress?.addrText  
                }  
            })
          } else {
            // When the component exits the viewport 
          }
        };
    };
    render(){
        //get data from store
        const {  addressSelectList, customerDefaultShippingAddress } = toJS(this.props.authStore);
        const {  shippingRates } = toJS(this.props.inventoryStore);

        //when customer shipping address is not configured properly or customerDefaultShippingAddress is FOB, show only -Custom- address in the address select field 
        const addressSelectValues = (shippingRates && shippingRates.length < 1) || customerDefaultShippingAddress?.shippingZone === 'FOB' ? addressSelectList.filter((address) => address?.defaultShipping === false) : addressSelectList
        
        //get component state data
        const {
            errorList,
            isSubmitting,
            isSubmittingClass,
            showCustomAddressForm,
            toggleShiptMethodRequiredStatus,
            message,
            cartItems,
            disableCustomAddressFields,
            basicValues: {
                shipMethod,
                shipTo,
                shippingAddressText,
                disabled,
                otherrefnum,
                custbodyCustomerOrderMethod,
                custbodyMemoToSo,
                custbodyDueDate,
                addressee,
                address1,
                address2,
                city,
                state,
                zip,
                attention,
                deliveryCharge,
                deliveryType
            } } = this.state
        return (
            <div className={`panel purchase-order-board ${isSubmittingClass}`} id='purchase-order-form' ref={this.targetRef}>
                <div className='purchase-order-board-close'>
                    <h3  onClick={ (event) => this.goBack(event) }>&times;</h3>
                    <h2>Checkout</h2>
                </div>
                <div className='pricing-notice'>
                    Lot delivery rates are computed based on the address <span className='notice'>{shippingAddressText}</span>
                </div>
                <div className='purchase-order-board-panel'>
                    <div className='heading'>Purchase Order Information</div>
                    <div className='form-group-items'>
                        <Input type={"text"} label={"My PO"} name={'otherrefnum'} value={otherrefnum}  altText={" "}  onChange={this.onChange} className={errorList.includes('otherrefnum') ? 'error' : ''} required={true}/>
                        <DateInput type={"text"}  label={"Requested Due Date (MM/DD/YYYY)"} name={'custbodyDueDate'} altText={"Official ship date will be provided upon Sales Order approval."} value={custbodyDueDate} reset={this.props.resetForm || this.state.resetDateInput} onChange={ this.onDateChange }  className={errorList.includes('custbodyDueDate') ? 'error' : ''} required={true}/>
                    </div>
                    <div className='form-group-items'>
                        <Select data={DELIVERY_METHOD} label={"Delivery Method"} noDefaults={true} name="custbodyCustomerOrderMethod" onChange={this.onSelectChange} value={custbodyCustomerOrderMethod} className={errorList.includes('custbodyCustomerOrderMethod') ? 'error' : ''} required={true}/>
                        <Select data={SHIPPING_METHOD} label={"Ship Method"} name="shipMethod" onChange={this.onChange} value={shipMethod} disabled={disabled} className={errorList.includes('shipMethod') ? 'error' : ''} required={toggleShiptMethodRequiredStatus}/>
                    </div>
                    <div className='form-group-items'>
                        <Select data={addressSelectValues} label={"Ship To Address"} name="shipTo" onChange={this.onShipToChange} value={shipTo} disabled={disabled} className={errorList.includes('shipTo') ? 'error' : ''} required={toggleShiptMethodRequiredStatus} noDefaults={true}/>
                        <Input type={"text"}  label={"Address"} value={shippingAddressText}  readOnly={true} border={true}/>
                    </div>
                    {
                        showCustomAddressForm && <div>
                            <div className='heading'>Enter a Custom Address</div>
                            <div className='form-group-items'>
                                <Input type={"text"}  label={"ZIP Code"} name={'zip'} value={zip}   onChange={this.onChange} className={errorList.includes('zip') ? 'error' : ''} required={true}/>
                                <Input type={"text"}  label={"Addressee"} name={'addressee'} value={addressee} readOnly={true}  onChange={this.onChange} className={errorList.includes('addressee') ? 'error' : ''} required={true}/>
                            </div>
                            <div className='form-group-items'>
                                <Input type={"text"}  label={"Address 1"} name={'address1'} value={address1} disabled={disableCustomAddressFields} onChange={this.onChange} className={errorList.includes('address1') ? 'error' : ''} required={true}/>
                                <Input type={"text"}  label={"Address 2"} name={'address2'} value={address2} disabled={disableCustomAddressFields} onChange={this.onChange} className={errorList.includes('address2') ? 'error' : ''} required={false}/>
                            </div>
                            <div className='form-group-items'>
                                <Input type={"text"}  label={"City"} name={'city'} value={city} disabled={disableCustomAddressFields} onChange={this.onChange} className={errorList.includes('city') ? 'error' : ''} required={true}/>
                                <Select data={US_STATES} label={"State"} name="state" onChange={this.stateChange} value={state} disabled={disableCustomAddressFields} className={errorList.includes('state') ? 'error' : ''} required={true}/>
                            </div>
                            <div className='form-group-items'>
                                <Input type={"text"}  label={"Attention"} name={'attention'} value={attention} disabled={disableCustomAddressFields} onChange={this.onChange} className={errorList.includes('attention') ? 'error' : ''} required={false}/>
                            </div>
                        </div>
                    }

                    <div className='form-group-items'>
                        <Input type={"textarea"}  label={"Comments"} placeHolder={"Enter Comments or Notes"} name={'custbodyMemoToSo'} onChange={this.onChange} value={custbodyMemoToSo} className={errorList.includes('custbodyMemoToSo') ? 'error' : ''} required={false}/>
                    </div>
                    <div className="form-group">
                        <label>Document Upload</label>
                        <FileUpload
                            inputKey={this.state.fileInputKey} 
                            selectedFiles={this.state.selectedFiles}
                            removeFile={this.removeFile}
                            handleFileChange={(e) => this.handleFileChange(e)}
                        />
                    </div>

                    <h2 id='items-label'>PO Items</h2>
                    {
                        cartItems.length < 1 ? "No lot items" : ''
                    }
                    {
                        cartItems.map((item,index) => {
                                //Recompute the rate on the checkout page only when delivery type changes
                                const rate = deliveryType === 'pick-up' ? parseFloat(item.price) : parseFloat(item.rate)
                                return <div key={`selected-lot-${index}`}>
                                    <SelectedLot
                                        description={`Item Desc: ${item.description}`}
                                        lotId={`Lot ID: ${item.inventorynumber}`}
                                        location = {`Location: ${item.location}`}
                                        weight={`${item.quantityavailable} ${UNITS[item.stockunit]}`}
                                        price={`${currencyFormatter((parseFloat(item.quantityavailable) * rate ),'USD','en-US')}`}
                                        oldPrice={ item?.oldPrice }
                                        rate={ (rate).toFixed(4)}
                                    />
                                    {item?.hasPriceChanged && <span className='lot-price-change'>The price of this lot has changed - your cart has been updated to reflect this change</span>}
                                    <Spacer />
                                    <Divider />
                                </div>
                            }
                        )
                    }
                    {/* {
                        
                        total = (cartItems.map((element) => { 
                            const rate = deliveryType === 'pick-up' ? parseFloat(element.price) : parseFloat(element.rate)
                            return parseFloat(element.quantityavailable) * (deliveryCharge < 0 ? element.price : rate)
                        } ).reduce((a,b) => a + b, 0)).toFixed(2)
                    } */}
                    { isSubmitting && <LoadingSipnner /> }
                    
                    { isSubmitting && <div style={{ position: 'absolute', top: '58%', right: '10%', left: '10%', color: "#283A8E", fontWeight: "500", textAlign: "center"}}>{ message }</div>}
                </div>
                <div className='purchase-checkout-form-footer sticky'>
                    <div className='property-group'>
                        <div className='properties' style={{ display: 'flex', justifyContent:'space-between'}}>
                            <div className='label'>Total weight</div><div className='value'>{ (cartItems.map((element) => parseFloat(element.quantityavailable)).reduce((a,b) => a + b, 0)/1000).toFixed(2) }k LB</div>
                        </div>
                        <div className='properties' style={{ display: 'flex', justifyContent:'space-between'}}>
                            <div className='label'>Total price</div><div className='value'>{ currencyFormatter( (cartItems.map((element) => { 
                            const rate = deliveryType === 'pick-up' ? parseFloat(element.price) : parseFloat(element.rate)
                            return parseFloat(element.quantityavailable) * (deliveryCharge < 0 ? element.price : rate)
                        } ).reduce((a,b) => a + b, 0)).toFixed(2),'USD','en-US' )}</div>
                        </div>
                    </div>
                    <button className='button' onClick={(e) => this.onSubmit(e)} disabled={isSubmitting || disableCustomAddressFields }> Submit Purchase Order</button>
                </div>
            </div>
        )
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(PurchaseOrderForm));

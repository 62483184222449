import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import List from '../../History/List/List';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import { currencyFormatter } from '../../../../stores/MainStore';
import { NetSuiteStatusValues, UNITS } from './NetSuiteStatusValues';
import { PreviewClaimForm, ClaimForm, Confirmation } from '../../../ClaimForm';

// Style
import '../OrderDetails.css';
import LoadingSipnner from '../../../UI/LoadingSipnner';

//these are the claim types. TODO:: fetch claim types from NetSuite
const claimTypes = [
    {value:"1",text:"Surface Defects"},
    {value:"2",text:"Flatness Issues"},
    {value:"3",text:"Water Defects"},
    {value:"4",text:"Out of Tolerances"},
    {value:"5",text:"Handling Damages"},
    {value:"6",text:"Extrusions-Surface Defects"},
    {value:"7",text:"Extrusions-Damaged Extrusions"},
    {value:"8",text:"Extrusions-Out of Tolerance"},
    {value:"9",text:"Shipping & Insurance Claim"},
    {value:"10",text:"AA Metals Error"},
    {value:"11",text:"Inbound issues"},
    {value:"12",text:"Rolling Marks"},
    {value:"13",text:"Weight Discrepancy "},
    {value:"14",text:"Mis-Label"},
    {value:"15",text:"Shipment Error"},
    {value:"16",text:"Container Handling Damage"},
    {value:"17",text:"Not a claim"}
]

//table columns
const columns = [
    {
        id: 'name',
        accessorKey: 'name',
        cell: info => info.getValue(),
        header: () => 'Name',
        footer: info => info.column.id
    },
    {
        id: 'description',
        accessorKey: 'description',
        cell: info => info.getValue(),
        header: () => 'Description',
        footer: info => info.column.id
    },
    {
        id: 'location',
        accessorKey: 'location',
        cell: info => info.getValue(),
        header: () => 'Location',
        footer: info => info.column.id
    },
    {
        id: 'quantity',
        accessorKey: 'quantity',
        cell: info => info.getValue(),
        header: () => 'Qty',
        footer: info => info.column.id
    },
    {
        id: 'units',
        accessorKey: 'units',
        cell: info => info.getValue() && UNITS[info.getValue()],
        header: () => 'UOM',
        footer: info => info.column.id
    },
    {
        id: 'truecost',
        accessorKey: 'truecost',
        cell: info => info.getValue(),
        header: () => 'True Cost',
        footer: info => info.column.id
    },
    {
        id: 'subtotal',
        accessorKey: 'subtotal',
        cell: info => info.getValue() && currencyFormatter(info.getValue(), 'USD', 'en-US'),
        header: () => 'Subtotal',
        footer: info => info.column.id
    },
    {
        id: 'lotValue',
        accessorKey: 'lotValue',
        cell: info => info.getValue(),
        header: () => 'Lot [Heat] (Weight/LBS) MTR',
        footer: info => info.column.id,
        size: 280
    }
]
function InvoiceDetails(props){
    const [showClaimForm, setShowClaimForm] = useState(false)
    const [showPreviewForm, setShowPreviewForm] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [payload, setPayload] = useState(null)
    const [loading, setLoading] = useState(true)

    const params = useParams()


    useEffect(() => {
        const fetchData = () => {
            // let invoice = props.orderStore.currentInvoice;
    
            // if(props.orderStore.fetchedInvoiceID !== invoice.id){
                //Reset this.props.orderStore.fetchedInvoiceID to undefined so that the loader will trigger
                props.orderStore.fetchedInvoiceID = undefined
                props.orderStore.fetchRelatedItems(`invoice/${params.INV_ID}/item`)
                .then(() => {
                    setLoading(false)
                })
            // }
        }

        //fetch data from NetSuite
        fetchData()

    },[props.orderStore,params.INV_ID])


    const toggleClaimForm = () => {
        setShowClaimForm(!showClaimForm)
    }
    const togglePreviewForm = (payload) => {
        setShowPreviewForm(!showPreviewForm)
        setPayload(payload)
    }
    const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation)
    }
    const finish = () => {
        setShowConfirmation(false)
        setShowPreviewForm(false)
        setShowClaimForm(false);

        window.location.href = `/claims`;
    }

    
    const handleFile = (record) => {
        props.orderStore.fetchFile({ internalId: record.id, tranId: record.tranid },'download','transaction')
    }
    
    const handleMTRFile = (fileParams) => {
        props.orderStore.fetchMTRDownload(fileParams)
    }
    
    //prepare table data structure
    const prepareTableDataStructure = () => {
        let items = toJS(props.orderStore.invoiceItems) && toJS(props.orderStore.invoiceItems)?.map((item) => { 
            return { 
                name: item.item && item.item.refName,
                location: item.location && item.location.refName,
                locationId: item.location && item.location.id,
                id: item.item && item.item.id,
                description: item.description,
                quantity: item.quantity,
                weightlbs: item.custcol_weight_lbs,
                rate: item.rate, 
                truecost: item.custcol_inv_lot_cost,
                weight: item.custcol3,
                subtotal: item.custcol7,
                lots: item.lots,
                lotValue: item.lotValue,
                units: item.units,
                orderId: item.orderId,
                orderLocationId: item.orderLocationId
            } 
        })
        return items;
    }

    //this function gets lots per each item in the sales order
    const getLotsPerItem = () => {
        //get lots that belong to a particular sales order from the orderStore
        let salesOrderLots = props.orderStore.salesOrderLots;
       
        //get all items that belong to the invoice from the orderStore
        let invoiceItems = toJS(props.orderStore.invoiceItems);
        if(invoiceItems){
            // for each of the items in the inovice record
            invoiceItems.forEach((invoiceItem) => {
                //create the lot columns of the table on the invoice detalied page
                let lotColumnValues = invoiceItem?.lots?.map((lot,key) => {
                    let net_weight = salesOrderLots[lot.internalId].net_weight;
                    let gross_weight = salesOrderLots[lot.internalId].gross_weight;
                    let lotnumber = salesOrderLots[lot.internalId].lotnumber;
                    let heat = salesOrderLots[lot.internalId].heat;
                    let mtr_id = salesOrderLots[lot.internalId].mtr_id;
                    let mtr_name = salesOrderLots[lot.internalId].mtr_name;
                    let pieces = salesOrderLots[lot.internalId].pieces;

                    if(net_weight === "") net_weight = 0;
                    if(gross_weight === "") gross_weight = 0;

                    //create the ui values of the lot column in the table
                    return <div key={`lots-${key}`} className="download-icons" style={{ diplay: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        {`${lotnumber} [${heat}] ${pieces ? pieces + 'ct' : ''} (${parseFloat(net_weight) > 0 ? (parseFloat(net_weight)).toFixed(2) : '---'})`}
                        {
                            mtr_id && mtr_name && <div className="download-icons">
                                {/* Some PDFs DO NOT Render properly. AAM-1152 has been created for this reason */}
                                {/* <Link to={ `/order/mtrpdfviewer/${ this.props.match.params.id }/${ mtr_id }/material-test-report` } className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}>View MTR</Link> */}
                                <div className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}} onClick={() => handleMTRFile({ internalId: mtr_id, fileName: mtr_name})}>Download MTR</div>
                            </div>
                        }
                        <br/><br/>
                    </div>
                })      
                
                //add a lotValue key to the item and store the ui column values in there 
                invoiceItem.lotValue = lotColumnValues;
            })
        }
        
        return invoiceItems;
    }

    //this function prepares the final table data by appending the lotValues to each item
    const prepareTableData = (tableDataStructure, invoiceItemsWithLotColumnValues) => {
        tableDataStructure?.forEach((item) => {
            invoiceItemsWithLotColumnValues?.forEach((itemWithLot) => {
                if(item.id === itemWithLot.item.id) {
                    item.lotValue = itemWithLot.lotValue
                }
            })
        })

        return tableDataStructure
    }

    // const processLotsForClaims = (items) => {
    //     let originalItems = []
    //     let lotDetails = []
    //     if(items.length > 0) {
    //         originalItems = items.filter((item) => item.quantity > 0)
            
    //         originalItems.forEach((item) => {
    //             item.lotValue.forEach((lot) => {
    //                 lotDetails.push({ id:item.id,lotNumber: lot.props.children[0], itemLocationId: item.locationId, orderId: item.orderId,orderLocationId: item.orderLocationId, itemName: item.name })
    //             })
    //         })
    //     }
    //     return lotDetails
    // }

    if(loading) return <LoadingSipnner />;
    
    const { progress } = props.mainStore
    const { currentInvoice } = props.orderStore;
    const { 
        otherrefnum,
        custbody_special_instructions,
        memo,
        fax, 
        foreignamountpaid, 
        foreignamountunpaid,
        foreigntotal, 
        lastmodifieddate, 
        shipdate, 
        status, 
        tranid,
        transactionnumber, 
        trandate,
        duedate,
        daysopen,
        daysoverduesearch,
        custbody_lot_json
        } = toJS(currentInvoice);

        const tableDataStructure = prepareTableDataStructure();
        const invoiceItemsWithLotColumnValues = getLotsPerItem(custbody_lot_json);
        const invoiceItems = prepareTableData(tableDataStructure, invoiceItemsWithLotColumnValues);

        let lotDetails =  []; //this.processLotsForClaims(invoiceItems.items);

    return (
        <div className="order-details-container">
            <div className="panel" id="order-details">
                <div className="order-header">
                    <h2>Invoice</h2>
                    <div className="order-document-actions">
                        {/* The following line is to show the a link to the PDF viewer, which is still a little buggy as of 04/28/23.
                        It and all other similar links in the code have been commented out until the PDF viewer issue is fixed.
                        See AAM-942 and AAM-916. */}
                        {/* <Link to={ `/order/pdfviewer/${ tranid }/${ id }/transaction` } className="view-report">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link> */}
                        <div className="view-report__inline" style={{ cursor: 'pointer', color:'#283A8E' }} onClick={ (e) => handleFile(currentInvoice) }>Download PDF <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } /></div>
                        {/* {invoiceItems.items.length > 0 && claims === "true" ? <button className='show-claim-form' onClick={() => this.toggleClaimForm()}>File a claim</button> : null } */}
                    </div>

                </div>
                <div className="order-information">
                    <InfoGridPanel className={"col-4"}>
                        <InfoGridItem  label={"Invoice ID:"} value={ tranid }/>
                        <InfoGridItem  label={"Invoice Trans Number:"} value={ transactionnumber }/>
                        <InfoGridItem  label={"Transaction Date:"} value={ trandate }/>
                        <InfoGridItem  label={"Ship Date:"} value={ shipdate }/>
                        <InfoGridItem  label={"Lastmodified Date:"} value={ lastmodifieddate }/>
                        <InfoGridItem  label={"Ref Number:"} value={ otherrefnum }/>
                        <InfoGridItem  label={"Foreign Amt Paid:"} value={ currencyFormatter(foreignamountpaid,'USD','en-US')  }/>
                        <InfoGridItem  label={"Foreign Amt Unpaid:"} value={ currencyFormatter(foreignamountunpaid,'USD','en-US')  }/>
                        <InfoGridItem  label={"Foreign Total:"} value={ currencyFormatter(foreigntotal,'USD','en-US')  }/>
                        <InfoGridItem  label={"Fax:"} value={ fax }/>
                        <InfoGridItem  label={"Due Date:"} value={ duedate }/>
                        <InfoGridItem  label={"Days Over due search:"} value={ daysoverduesearch }/>
                        <InfoGridItem  label={"Days Open:"} value={ daysopen }/>
                        <InfoGridItem  label={"Status:"} value={ NetSuiteStatusValues[`CustInvc:${status}`] }/>                            
                    </InfoGridPanel>
                    <InfoGridPanel className={"col-2"}>
                        <InfoGridItem  label={"Special Instructions:"} value={ custbody_special_instructions }/>
                        <InfoGridItem  label={"Memo:"} value={ memo }/>
                    </InfoGridPanel>
                    { progress && progress.loading && <LoadingSipnner />}
                </div>
            </div>
            <div className="panel" id="order-items">
                <List 
                    data={invoiceItems || []} 
                    columns={columns} 
                    noDataText={"Invoice Records"} 
                    isLoading={loading}
                    title={""}
                    description={`There are ${ invoiceItems && invoiceItems.length} items`}
                />
            </div>
            {
                showClaimForm && <ClaimForm hide={toggleClaimForm} showPreview={togglePreviewForm} invoiceLots={lotDetails} claimTypes={claimTypes}/>
            }
            {
                showPreviewForm && <PreviewClaimForm hide={togglePreviewForm} show={toggleConfirmation} payload={payload} claimTypes={claimTypes} PONumber={otherrefnum}/>
            }
            {
                showConfirmation && <Confirmation finish={finish}/>
            }
            
        </div>
    );

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(InvoiceDetails));

import React from 'react'

import "./InfoGridPanel.css";


const InfoGridPanel= ({ children,  className }) => {
    return(
        <div className={`info-grid-panel__contianer ${ className }`}>
            { children  }
        </div>
    )
}


const InfoGridItem = ({ label, value }) => {
    return <div className='info-grid-item'><span>{ label }</span><div>{ value }</div></div>
}


export {
    InfoGridPanel,
    InfoGridItem
}
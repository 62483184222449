import * as Sentry from "@sentry/react";



export const sendSentryEvent = (transactionName, op) => {
  Sentry.configureScope(scope => scope.setTransactionName(transactionName));
  const transaction = Sentry.startTransaction({ name: transactionName });
  const span = transaction.startChild({
      op: op,
      description: `processing ${transactionName}`
  });
  return span;
};

export const sendSentryData = (message) => {
  Sentry.captureMessage(message)
};

// export const getLogs = async () => {
//   let response = await fetch(`${process.env.REACT_APP_AIRTABLE_API_URL}/${process.env.REACT_APP_AIRTABLE_API_ID}/Events?api_key=${process.env.REACT_APP_AIRTABLE_API_KEY}`);
//   response = await response.json();
  
//   return response;
// };

// export const saveLogs = async (data) => {

//   let payload = {
//     method: 'POST', // *GET, POST, PUT, DELETE, etc.
//     mode: 'cors', // no-cors, *cors, same-origin
//     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data) // body data type must match "Content-Type" header
//   }

//   let response = await fetch(`${process.env.REACT_APP_AIRTABLE_API_URL}/${process.env.REACT_APP_AIRTABLE_API_ID}/Events?api_key=${process.env.REACT_APP_AIRTABLE_API_KEY}`,payload);
//   response = await response.json();
  
//   return response;
// };

export const zeroPad = (value, length) => `${value}`.padStart(length, '0'); 
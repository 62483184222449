// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main .sticky-table .rt-thead.-header {
    position: sticky;
    top: 0;
}
.main .sticky-table .rt-tbody {
    height: 550px;
}
.main .sticky-table .pagination-bottom {
    display: none;
}
.table-panel {
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InventorySummary/List/List.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,MAAM;AACV;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".main .sticky-table .rt-thead.-header {\n    position: sticky;\n    top: 0;\n}\n.main .sticky-table .rt-tbody {\n    height: 550px;\n}\n.main .sticky-table .pagination-bottom {\n    display: none;\n}\n.table-panel {\n    background-color: #fff;\n    box-sizing: border-box;\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progress {
    /* transform: rotate(-90deg); */
  }
  
  .circular-progress-bg {
    fill: none;
    stroke: #e6e6e6;
  }
  
  .circular-progress-bar {
    fill: none;
    stroke: #15803d;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PackageDownload/CircularProgress.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;EACjC;;EAEA;IACE,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,mCAAmC;IACnC,yBAAyB;IACzB,wBAAwB;EAC1B","sourcesContent":[".circular-progress {\n    /* transform: rotate(-90deg); */\n  }\n  \n  .circular-progress-bg {\n    fill: none;\n    stroke: #e6e6e6;\n  }\n  \n  .circular-progress-bar {\n    fill: none;\n    stroke: #15803d;\n    transition: stroke-dashoffset 0.35s;\n    transform: rotate(-90deg);\n    transform-origin: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

//Constants
import { formatTableData } from '../OrderDetails/Pages/NetSuiteStatusValues';

// Style
import './History.css';

// Components
import Filters from './Filters/Filters';
import List from './List/List';

class History extends Component {
    constructor(props){
        super(props);
        this.state = {
            columns: [
                {
                    id: 'tranid',
                    accessorKey: 'tranId',
                    cell: info => this.orderLinkUrl(info.getValue()),
                    header: () => 'Sales Order ID',
                    footer: info => info.column.id
                },
                {
                    id: 'otherrefnum',
                    accessorKey: 'otherRefNum',
                    cell: info => info.getValue(),
                    header: () => 'My PO',
                    footer: info => info.column.id
                },
                {
                    id: 'statusName',
                    accessorKey: 'statusName',
                    cell: info => info.getValue(),
                    header: () => 'Status',
                    footer: info => info.column.id
                },
                {
                    id: 'createdDate',
                    accessorKey: 'createdDate',
                    cell: info => info.getValue() && moment(info.getValue()).local().format("YYYY-MM-DD"),
                    header: () => 'Created Date',
                    footer: info => info.column.id
                },
                {
                    id: 'dueDate',
                    accessorKey: 'dueDate',
                    cell: info => info.getValue() && moment(info.getValue()).local().format("YYYY-MM-DD"),
                    header: () => 'Due Date',
                    footer: info => info.column.id
                },
                {
                    id: 'orderMethod',
                    accessorKey: 'orderMethod',
                    cell: info => info.getValue() && formatTableData('orderMethod',info.getValue()),
                    header: () => 'Order Method',
                    footer: info => info.column.id
                },
                {
                    id: 'orderCondition',
                    accessorKey: 'orderCondition',
                    cell: info => info.getValue() && formatTableData('orderCondition',info.getValue()),
                    header: () => 'Condition',
                    footer: info => info.column.id
                }
            ]
        };

        this.orderLinkUrl = this.orderLinkUrl.bind(this)
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (!token) { window.location.href = '/login'; }
        this.props.orderStore.resetRecentViewedOrder()
    }

    orderLinkUrl(tranId) {
        const { orders } = this.props.orderStore
        const order = orders && orders.find((order) => order.tranId === tranId);
        return order && <Link to={ `/order/detail/order/${ tranId }/${order.internalId}` }>{ tranId }</Link>
    }

    handleFilters = (filter) => {

        const { tranId, otherRefNum, status, tranDateBegin, tranDateEnd } = filter;
        let newFilter = { tranId, otherRefNum, status, tranDateBegin, tranDateEnd };
        
        if(tranId){
            newFilter = { tranId: tranId, otherRefNum: null, status: null, tranDateBegin: null, tranDateEnd: null };
        }
        if(otherRefNum){
            newFilter = { tranId: null, otherRefNum: otherRefNum, status: null, tranDateBegin: null, tranDateEnd: null };
        }
        if(!tranId && !otherRefNum && !status && !tranDateBegin && !tranDateEnd ){
            this.props.mainStore.setError('Date fields cannot be empty. Please choose a date range.');
            return;
        }
        this.props.orderStore.fetchOrders(newFilter);
        this.setState({ ...this.state });
    }
    
    render() {
        const { orders, isPendingRequest } = this.props.orderStore
        return (
            <section className="box order-history">
                <Filters filter={ this.handleFilters } />
                <List 
                    columns={this.state.columns}
                    data={orders} 
                    noDataText="No orders found for the specified period." 
                    isLoading={isPendingRequest}
                    type={"orders"}
                    title={"Sales Orders"}
                    description={`There are ${ orders && orders.length } orders`} 
                />
            </section>
        );
    }
}

export default (inject('orderStore', 'authStore')(observer(History)));

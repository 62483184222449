// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.accountSettings {
  height: 100vh;
}
.accountSettings__panel {
  position: absolute;
  right: 20px;
  top: 75px;
  background: white;
  border-radius: 4px;
  padding: 30px;
  z-index: 9;
}

.topnav-submenu__link {
  font-size: 16px;
  font-weight: 500;
  padding: 2px;
}

.topnav-submenu__link:hover {
  color: red;
}

.passwordSettingsReset__form {
  max-width: 326px;
  margin: 0 auto;
  margin-top: 20vh;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 2rem;
}

.passwordSettingsReset__form .button {
  max-width: 160px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.passwordReset__form .button {
  max-width: 240px;
  margin: 20px auto;
  display: block;
}

.passwordResetPrompt {
  padding-bottom: 20px;
  font-weight: 600;
}

.password-tips {
  margin-bottom: 20px;
}

.password-requirements {
  font-weight: 600;
  margin-bottom: 10px;
}
.currentPassword {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 20px;
}
.currentPassword input {
  margin-bottom: 0;
}
.currentPassword span {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/AccountSettings/AccountSettings.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,8EAA8E;EAC9E,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB","sourcesContent":["\n.accountSettings {\n  height: 100vh;\n}\n.accountSettings__panel {\n  position: absolute;\n  right: 20px;\n  top: 75px;\n  background: white;\n  border-radius: 4px;\n  padding: 30px;\n  z-index: 9;\n}\n\n.topnav-submenu__link {\n  font-size: 16px;\n  font-weight: 500;\n  padding: 2px;\n}\n\n.topnav-submenu__link:hover {\n  color: red;\n}\n\n.passwordSettingsReset__form {\n  max-width: 326px;\n  margin: 0 auto;\n  margin-top: 20vh;\n  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);\n  padding: 2rem;\n}\n\n.passwordSettingsReset__form .button {\n  max-width: 160px;\n  position: absolute;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n}\n\n.passwordReset__form .button {\n  max-width: 240px;\n  margin: 20px auto;\n  display: block;\n}\n\n.passwordResetPrompt {\n  padding-bottom: 20px;\n  font-weight: 600;\n}\n\n.password-tips {\n  margin-bottom: 20px;\n}\n\n.password-requirements {\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n.currentPassword {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n  margin-bottom: 20px;\n}\n.currentPassword input {\n  margin-bottom: 0;\n}\n.currentPassword span {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-grid-panel__contianer {
    display: grid;
    grid-gap: 0.3rem;
    padding-bottom: 0.8rem;
}

.col-4 {
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}

.col-2 {
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
}

.info-grid-item {
    color: var(--blue);
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Page/InfoGridPanel/InfoGridPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,yDAAyD;AAC7D;;AAEA;IACI,yDAAyD;AAC7D;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,qBAAqB;AACzB","sourcesContent":[".info-grid-panel__contianer {\n    display: grid;\n    grid-gap: 0.3rem;\n    padding-bottom: 0.8rem;\n}\n\n.col-4 {\n    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));\n}\n\n.col-2 {\n    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));\n}\n\n.info-grid-item {\n    color: var(--blue);\n    font-weight: 800;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin-bottom: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';

import List from '../../History/List/List';

// Style
import '../OrderDetails.css';
import Loader from '../../../UI/Loader';
import PlaceHolder from '../../../UI/PlaceHolder';

export function withRouter(Children){
    return(props)=>{
       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
    }
}

class ItemFulfillment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoadingRelatedRecords: true
        }
    }

    componentDidMount(){
        let token = this.props.authStore.token;
        if (token) {
            if(this.props.orderStore.fetchedRelatedRecordsTranId !== this.props.match.params.id){
                this.props.orderStore.resetItemshipsInvoices()
                this.fetchData({ tranId: this.props.match.params.id, internalId: this.props.match.params.internalId });
                setTimeout(() => {
                    this.props.mainStore.setProgress({ message: "Item fulfillment query will take a little longer. Please be patient" });
                },6000)
            } else {
                //clear loading state if component remounts and the sales order id for the previous search has NOT changed
                this.setState({ isLoadingRelatedRecords: false })
            }
        } else {
            window.location.href = '/login';
        }
    }

    async fetchData(orderData){

        this.props.orderStore.fetchOrder(orderData)
        .then(() => {
            this.fetchBackgroundData(orderData);
        })

        // this.props.orderStore.fetchRelatedRecords(order)
        // .then(() => {
            // this.props.orderStore.fetchItemfulfillmentBackground(order)
            // .then(() => {
                // if(order.quoteID === null){
                //     // Do Nothing
                // }else {
                //     this.props.orderStore.fetchQuoteBackground(order)
                //     .then(() => {
                //         this.props.orderStore.fetchQuoteItemsBackground(order)
                //     })
                // }
            // })
        
            // })
    }
    async fetchBackgroundData(orderData){
        this.props.orderStore.fetchRelatedRecordsBackground(orderData)
        .then(() => {
            this.setState({ isLoadingRelatedRecords: false})
        });
    }

    generateItems(){
        let items = this.props.orderStore.itemfulfillmentItems
        // .filter((item) => item.amountSpecified === true)
        .map((item) => { 
            return { 
                name: item.itemName,
                description: item.description,
                location: item.location && item.location.refName,
                onhand: item.onHand ,
                quantity: item.quantity,
                customPart: item.custcol_customer_part_number
            } 
        })

        let columns = [
            {
                id: 'name',
                accessorKey: 'name',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'description',
                accessorKey: 'description',
                cell: info => info.getValue(),
                header: () => 'Description',
                footer: info => info.column.id
            },
            {
                id: 'location',
                accessorKey: 'location',
                cell: info => info.getValue(),
                header: () => 'Location',
                footer: info => info.column.id
            },
            {
                id: 'onhand',
                accessorKey: 'onhand',
                cell: info => info.getValue(),
                header: () => 'On Hand',
                footer: info => info.column.id
            },
            {
                id: 'quantity',
                accessorKey: 'quantity',
                cell: info => info.getValue(),
                header: () => 'Qty',
                footer: info => info.column.id
            },
            {
                id: 'customPart',
                accessorKey: 'customPart',
                cell: info => info.getValue(),
                header: () => 'Custom Part #',
                footer: info => info.column.id
            }
        ]

        return [items, columns];
    }

    viewDetails(path,tranId){
        this.props.orderStore.setCurrentItemShip(tranId);

        const { itemships } = this.props.orderStore;
        const itemship = itemships.find((element) => element.tranid = tranId)

        const url = path + '/' + itemship.id + '/' + itemship.bol
        this.props.history(url);
    }

    viewBOLDetails(path,tranId){
        this.props.orderStore.setCurrentBillOfLading(tranId);
        this.props.history(path)
    }

   
    
    render() {
        if(this.props.orderStore.relatedRecords.length === 0 && !this.props.orderStore.isPendingRequest) return <PlaceHolder text={`This Order, (${ this.props.match.params.id }) has no Item fulfillments at the moment!`}/>;
        if(this.props.orderStore.itemships.length === 0) return <Loader default={true}/>; 

        const { itemships } = this.props.orderStore;
        
        const columns = [
            {
                id: 'tranid',
                accessorKey: 'tranid',
                cell: info =><div className="related-records-table-cell"><div onClick={ () => this.viewDetails(`/order/detail/itemfulfillment-details/${this.props.match.params.id}`,info.getValue()) }>{ info.getValue() }</div></div>,
                header: () => 'Tranid',
                footer: info => info.column.id
            },
            {
                id: 'trandisplayname',
                accessorKey: 'trandisplayname',
                cell: info => info.getValue(),
                header: () => 'Name',
                footer: info => info.column.id
            },
            {
                id: 'trandate',
                accessorKey: 'trandate',
                cell: info => info.getValue(),
                header: () => 'Tran Date',
                footer: info => info.column.id
            },
            {
                id: 'createddate',
                accessorKey: 'createddate',
                cell: info => info.getValue(),
                header: () => 'Created Date',
                footer: info => info.column.id
            },
            {
                id: 'bol',
                accessorKey: 'bol',
                cell: info => <div className="related-records-table-cell"><div onClick={ () => this.viewBOLDetails(`/order/detail/itemfulfillment-bol/${ this.props.match.params.id}`,info.getValue()) }>{ info.getValue() ? "View BOL" : ""}</div></div>,
                header: () => 'Bill of Lading',
                footer: info => info.column.id
            }
        ]
     
        return (
            <div>
                <List 
                    data={itemships || []} 
                    columns={columns} 
                    noDataText={"No Itemfulfillment Records"}
                    title={""}
                    description={`There are ${(itemships && itemships.length) || 0} items`}
                />
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(withRouter(observer((props) => <ItemFulfillment history={useNavigate()} {...props}/>)));

import React, { Component } from 'react';


class Input extends Component { 


    render(){
        const { 
            type, 
            label, 
            name, 
            onChange, 
            altText, 
            className, 
            checked, 
            value, 
            readOnly,
            disabled, 
            placeHolder, 
            required, 
            border
        } = this.props

        if(type === 'checkbox'){
            return (
                <div className='form-group'>
                    <input type={type} checked={checked} name={name} onChange={onChange} className={className}/> { label }
                </div>
            )
        }

        if(type === 'customCheckbox'){
            return (
                <div className='custom-form-group custom-checkbox'>
                    <label>
                        <input type={'checkbox'} checked={checked} name={name} onChange={onChange} className={className}/>
                        <span><i className={`fas ${checked ? 'fa-minus' : 'fa-plus'}`}></i> { checked ? ' My PO' : ' My PO' }</span>
                    </label>
                </div>
            )
        }

        if(type === 'textarea'){
            return (
                <div className='form-group'>
                    <label htmlFor={name}>{label} { required && <span style={{ color: 'red'}}>*</span>}</label>
                    <textarea name={name} cols="30" rows="5" placeholder={ placeHolder } onChange={onChange} className={className} value={value} readOnly={readOnly}></textarea>
                </div>
            )
        }

        return (
            <div className={`form-group ${disabled ? 'grayed-fields' : ''}`}>
                <label  htmlFor={ name }>{ label }{ required && <span style={{ color: 'red'}}>*</span>}</label>
                <p>{altText}</p>
                <input type={type} name={ name } onChange={ onChange } className={className} value={value} readOnly={readOnly} disabled={disabled} style={{ border: !border ? 'block' : 'none'}}/>
            </div>
        )
    }
}

export default Input;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import api from '../../http';
// Style
import './PackageDownload.css';
import LoadingSipnner from '../UI/LoadingSipnner';
import CircularProgress from './CircularProgress';

const PackageDownload = (props) => {
    const [ bolData, setBolData] = useState([])
    const [ mtrNames, setMTRNames] = useState([])
    const [ ID, setID] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const params = useParams()
    const [ packagePropsXp, setPackagePropsXp] = useState(null)
    const [ linkHasExpired, setLinkHasExpired] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [numberOfDownloads, setNumberOfDownloads] = useState(0)

    const [progress, setProgress] = useState(0);
    
    useEffect(() => {
        async function fetchLinks() {
            const response = await api.get( `api/file/getDocumentProps?packageHash=${params.packageHash}`);
            if(response.status === 200 && response.data.length > 0) {

                const package_props = response.data[0]?.xp?.package_props
                const package_id = response.data[0]?.ID;

                //get end date for link to expire
                const endDownload = new Date(response.data[0]?.xp?.end).getTime();

                //check if expiration date is up
                if(endDownload < new Date().getTime()) {
                    setIsSubmitting(false)
                    setLinkHasExpired(true)
                    setPageLoading(false)
                    await props.orderStore.setPackageUpdate(package_id, { expired : true })
                    return;
                }

                //prepare all dataset
                const mtr_Names = (package_props.mtrNames).split(",").filter((mtrName) => mtrName !== "")
                const stops = (package_props.stops).split(",").filter((stop) => stop !== "")
                const shipments = (package_props.shipments).split(",").filter((shipment) => shipment !== "")

                //set all dataset
                setBolData(createStopTruckPair(stops, shipments))
                setMTRNames(mtr_Names);
                setID(package_id)
                setPackagePropsXp(response.data[0]?.xp)
                setPageLoading(false)
            } else {
                setPageLoading(false)
            }
        }
        fetchLinks();
        // const packageNos = (params.packageNos).split(",").filter((packageNo) => packageNo !== "")
        // const packageIds = (params.packageIds).split(",").filter((packageId) => packageId !== "")

    },[params.packageHash, props.orderStore])

    //prepare stopId and truckId for downloading bol pdf
    const createStopTruckPair = (stops=[], shipments=[]) => {
        let data = [];
        for(let i=0; i<stops.length; i++) {
            data.push({ stopId: stops[i] , truckId: shipments[i]})
        }
        return data;
    }

    const handlePacketDownload = async () => {
        setIsSubmitting(true)
        setProgress(0)

        //get end date for link to expire
        const endDownload = new Date(packagePropsXp.end).getTime();

        //check if expiration date is up
        if(endDownload < new Date().getTime()) {
            setLinkHasExpired(true)

            //update package details and set expiration to true
            await props.orderStore.setPackageUpdate(ID, { expired : true })
            setIsSubmitting(false)
            return;
        }

        
        try {

            //make sure we always have the recent number of downloads
            const response = await api.get( `api/file/getDocumentProps?packageHash=${params.packageHash}`);
            if(response.status === 200 && response.data.length > 0) {
                setPackagePropsXp(response.data[0]?.xp)
                setNumberOfDownloads(response.data[0]?.xp?.no_of_downloads)
            }

            const filesToBeDownloaded = [
                { isMTR: false, bol: bolData },
                { isMTR: true, mtr: mtrNames }
            ]

            //get total number of bols and MTRs
            const totalBOLs = filesToBeDownloaded[0].bol.length;
            const totalMTRs = filesToBeDownloaded[1].mtr.length;
            
            for(let i=0; i < filesToBeDownloaded.length; i++) {
                if(!filesToBeDownloaded[i].isMTR) {
                    //download only BOLs
                    await props.orderStore.fetchPacketZipFileUnauthenticated(filesToBeDownloaded[i].bol);
                    setProgress(((totalBOLs/(totalBOLs + totalMTRs)) * 100).toFixed(0))
                } else {

                    //download only MTRs
                    let no_of_mtrs = 1;
                    for(let j=0; j < filesToBeDownloaded[i].mtr.length; j++) {
                        await props.orderStore.fetchMTRDownloadAsync({ fileName: filesToBeDownloaded[i].mtr[j] },'download',false)
                        
                        setProgress((((totalBOLs + no_of_mtrs)/(totalBOLs + totalMTRs)) * 100).toFixed(0))

                        no_of_mtrs += 1;
                    }
                }
            }

            //update package record
            await props.orderStore.setPackageUpdate(ID, { file_downloaded : true, no_of_downloads: numberOfDownloads + 1 })
        } catch (error) {
            
        } finally {
            setIsSubmitting(false)
        }
    }
    
    return (
        <section className="package-download flex column middle center">
            { 
                pageLoading ? 

                <LoadingSipnner/> : 

                linkHasExpired ? 
                
                <>
                    <div className="download-not-found">
                        <img className="topnav-logo" src="/images/logo.png" alt="AA Metals" />
                    </div>
                    <h2>Package Not Found</h2> 
                    {"The download link has expired. "} 
                    Please contact your sales rep.
                </> :
                (bolData.length + mtrNames.length) < 1 ?

                <>
                    <div className="download-not-found">
                        <img className="topnav-logo" src="/images/logo.png" alt="AA Metals" />
                    </div>
                    <h2>Package Not Found</h2>
                    {"There are no packages found for this request. "} 
                    Please contact your sales rep.
                </> :
                <div className="download-form">
                    <div className="download-form-top">
                        <img className="topnav-logo" src="/images/logo.png" alt="AA Metals" />
                    </div>
                    <div className="download-form-bottom">
                        <div className="document-text">
                            <p>File Package</p>
                            <span>{bolData.length + mtrNames.length} files</span>
                        </div>
                        <div className="document-icon">
                            {/* <i className="fas fa-file-pdf"></i> */}
                            <CircularProgress size={50} progress={progress} />
                        </div>
                    </div>
                    <button name="button" className={`download-form-button ${isSubmitting ? 'downloaing' : 'active' }`} type="button" onClick={ handlePacketDownload } disabled={!(bolData.length + mtrNames.length) || isSubmitting}>
                        { isSubmitting ? "Download in progress..." : "Download files" } 
                    </button>
                </div>
            }
        </section>
    );

}


export default inject('mainStore', 'inventoryStore', 'authStore', 'orderStore')(observer(PackageDownload));
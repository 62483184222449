import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import List from './List/List';

class OpenQuotes extends Component {

    componentDidMount(){
        const { customer, token } = this.props.authStore
        if (token) {
            this.fetchData(customer.userId);
        } else {
            window.location.href = '/login';
        }
    }

    // componentDidUpdate(){
    //     this.props.mainStore.setProgress({ loading: false });
    // }

    async fetchData(userId){
        
        this.props.orderStore.fetchOpenQuotes(userId)
        .then(() => {
        
        })
    }

    
    render() {
       const { openQuotes } = this.props.orderStore;
     
        return (
            <div className="box order-history">
                <List items={openQuotes} noDataText={"Open Quotes"}/>
            </div>
        );
    }

}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(OpenQuotes));

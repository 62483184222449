import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Success extends Component {

    handleResetSuccess = () => this.props.mainStore.resetSuccess();

    render() {
        const { success } = this.props.mainStore;

        if (success) {
            return (
                <aside className="panel panel--header panel--success" >
                    <div style={ { margin: 0 } }><span dangerouslySetInnerHTML={{ __html: success }}></span></div>
                    <div style={ { cursor: 'pointer', fontSize: '1.8rem' } } onClick={ (e) => this.handleResetSuccess() }>&times;</div>
                </aside>
            );
        } else {
            return null;
        }
    }
}

export default inject('mainStore')(observer(Success));

import React from 'react';

const LoadingSipnner = () =>  {
    return (
        <div className="loading-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default LoadingSipnner;

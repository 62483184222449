import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react";
import { Session } from 'bc-react-session';

// Style
import './App.css';

// Page components
import TopNavNew from './components/Page/TopNav/TopNavNew';
import NotFound from './components/Page/NotFound/NotFound';
import PackageDownload from './components/PackageDownload/PackageDownload';

// Components
import LoginForm from './components/Login/Login';
import Home from './components/Home/Home';
import History from './components/Order/History/History';
import Details from './components/Order/Details/Details';
import PDFViewer from './components/Order/Details/PDFViewer';
import MTRPDFViewer from './components/Order/Details/MTRPDFViewer';
import OrderDetails from './components/Order/OrderDetails/OrderDetails';
import InventorySummary from './components/InventorySummary/InventorySummary';
import InventorySearch from './components/InventorySummary/InventorySearch';
import OpenQuotes from './components/OpenQuotes/OpenQuotes';
import AccountSettings from './components/AccountSettings/AccountSettings';
import Claims from './components/ClaimForm/Claims';
import { BOL, INV, LOT } from './components/Order/History/SearchViews';

import SalesOrder from './components/Order/OrderDetails/Pages/SalesOrder';

// UI components
import Errors from './components/UI/Errors';
import Success from './components/UI/Success';
import Info from './components/UI/Info';
// import VersionTag from './components/UI/VersionTag';
import CompanyInfo from './components/Home/CompanyInfo';
import FeedBackForm from './components/FeedBack/FeedBack';
import TopNavSkeleton from './components/Page/TopNav/TopNavSkeleton';
import { authStore } from './stores/AuthStore';
// import { cartStore } from './stores/CartStore';
import RequestForQuote from './components/Order/OrderDetails/Pages/RequestForQuote';
import RelatedRecords from './components/Order/OrderDetails/Pages/RelatedRecords';
import Invoice from './components/Order/OrderDetails/Pages/Invoice';
import InvoiceDetails from './components/Order/OrderDetails/Pages/InvoiceDetails';
import ItemFulfillment from './components/Order/OrderDetails/Pages/ItemFulfillment';
import ItemFulfillmentDetails from './components/Order/OrderDetails/Pages/ItemFulfillmentDetails';
import BillOfLadingDetails from './components/Order/OrderDetails/Pages/BillOfLadingDetails';
import RecentIFBillOfLadingDetails from './components/Order/OrderDetails/Pages/RecentIFBillOfLadingDetails';
import SessionTimeOut from './components/Page/NotFound/SessionTimeOut';

function App (props) {

    const [isBlocked, setIsBlocked] = useState(false);


    useEffect(() => {
        window.onload = props.authStore.resetTimer;
        document.onmousemove = props.authStore.resetTimer;
        document.onkeydown = props.authStore.resetTimer;
    
        //get session date from local storage
        const loggedInStatus  = Session.get("aametals-app-session");
    
        if(!loggedInStatus.active) {
            fetchAnnouncements();
        }
    
        //initialize tag manager
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_ID
        }
        TagManager.initialize(tagManagerArgs)

        // const tabId = Math.random().toString(36).substring(2); // Generate unique ID for this tab
        // const channel = new BroadcastChannel('tab-manager'); // Create a BroadcastChannel for communication between tabs

        // // Check if another instance is already open
        // if (localStorage.getItem('app-tab-open') === 'true') {
        //     setIsBlocked(true); // Block access if another tab is open
        // } else {
        //     // Mark this tab as open
        //     localStorage.setItem('app-tab-open', 'true');
        // }

        // // Broadcast to other tabs that this tab is open
        // channel.postMessage({ type: 'tab-opened', tabId });

        // // Listen for messages from other tabs
        // channel.onmessage = (message) => {
        //     if (message.data.type === 'tab-opened' && message.data.tabId !== tabId) {
        //         window.close()
        //         setIsBlocked(true); // Block this tab if another tab opens
        //     }
        // };

        // // Cleanup when the component unmounts or the tab is closed
        // const handleUnload = () => {
        //     localStorage.setItem('app-tab-open', 'false'); // Mark this tab as closed
        //     channel.postMessage({ type: 'tab-closed', tabId }); // Notify other tabs
        //     channel.close(); // Close the channel
        // };

        // window.addEventListener('beforeunload', handleUnload);

        // return () => {
        //     handleUnload();
        //     window.removeEventListener('beforeunload', handleUnload);
        // }
    },[props.authStore.resetTimer])


    // componentDidUpdate(prevProps) {
    //     if (this.props.location !== prevProps.location) {
    //         window.scrollTo(0, 0);

    //         // Log a virtual pageview event
    //         TagManager.dataLayer({
    //             dataLayer: {
    //                 event: 'virtualPageview',
    //                 pagePath: this.props.location.pathname
    //             }
    //         });
    //     }
    // }

    function handleContinueSession(){
        authStore.toggleLogoutModal();
        authStore.initTimeoutWorker();
        authStore.modalTimerValue = 0;
    }


    async function handleLogout(){
        try {
            window.location.href = '/session-timeout';
        } catch (error) {
            console.log(error)
        }
    }

    async function handleGoBack(){
        try {
            window.location.href = '/home';
        } catch (error) {
            console.log(error)
        }
    }

    function fetchAnnouncements() {
        authStore.fetchAnnouncements('Login','getShortMessage')
        .then(() => {})
    }


    const { userCompanies } = props.authStore;
    //This ensures that the session timer does not attain negative values.
    if((authStore.timeoutWarning - authStore.modalTimerValue) <= 0) {
        handleLogout();
    }

    if (isBlocked) {
        return <section className="session-timeout flex column middle center">
        <h1>Portal is already opened in another tab</h1>
        <button className="button" name="button" type="button" onClick={ handleGoBack }>Click here to return</button>
    </section>
      }

    return (
        <main className="main">
            { authStore.logoutModalOpen && (
                <div className="modal modal--force-logout">
                        <div className="modal__container">
                        <p className="force-logout__message">{`You will be logged out due to inactivity in ${(authStore.timeoutWarning - authStore.modalTimerValue) / 1000} seconds...`}</p>
                        <button className="button button--logout" onClick={handleLogout}>Logout</button>
                        <button className="button button--continue" onClick={handleContinueSession}>Continue Session</button>
                    </div>
                </div>
            )}
            { userCompanies ? <TopNavNew /> : <TopNavSkeleton />}
            <Routes>
                <Route path="/" element={ <LoginForm /> } />
                <Route path="/login" element={ <LoginForm /> } />
                <Route path="/home" element={ <Home /> } />
                <Route path="/order/history" element={ <History /> } />
                <Route path="/order/pdfviewer/:id/:type" element={ <Details /> } />
                <Route path="/order/pdfviewer/:id/:internalId/:type" element={ <PDFViewer /> } />
                <Route path="/order/mtrpdfviewer/:id/:internalId/:fileName/:type" element={ <MTRPDFViewer /> } />
                <Route path="/order/detail/*" element={ <OrderDetails /> } >
                    <Route path="/order/detail/*/order/:id/:internalId/*" element={ <SalesOrder /> } />
                    <Route path="/order/detail/*/quote/:id/*" element={ <RequestForQuote/> } />
                    <Route path="/order/detail/*/relatedrecords/:id/*" element={ <RelatedRecords /> } />
                    <Route path="/order/detail/*/invoice/:id/:internalId/*" element={ <Invoice /> } />
                    <Route path="/order/detail/*/invoice-details/:id/:INV_ID/*" element={ <InvoiceDetails /> } />
                    <Route path="/order/detail/*/itemfulfillment/:id/:internalId/*" element={ <ItemFulfillment /> } />
                    <Route path="/order/detail/*/itemfulfillment-details/:id/:IF_ID/:truckIds/*" element={ <ItemFulfillmentDetails /> } />
                    <Route path="/order/detail/*/itemfulfillment-bol/:id/*" element={ <BillOfLadingDetails /> } />
                </Route>
                <Route path="/invoice-search" element={ <INV /> } />
                <Route path="/bol-search" element={ <BOL /> } />
                <Route path="/lot-search" element={ <LOT /> } />
                <Route path="/claims" element={ <Claims /> } />
                <Route path="/open-quotes" element={ <OpenQuotes /> } />
                <Route path="/inventory-summary" element={ <InventorySummary /> } />
                <Route path="/inventory-search" element={ <InventorySearch /> } />
                <Route path="/company-info" element={ <CompanyInfo /> } />
                <Route path="/feedback" element={ <FeedBackForm /> } />
                <Route path="/account-settings" element={ <AccountSettings /> } />
                <Route path="/recent-itemfulfillment-bol" element={ <RecentIFBillOfLadingDetails /> } />
                <Route path="/order/download/package/:packageHash" element={ <PackageDownload /> } />
                <Route path="/session-timeout" element={ <SessionTimeOut /> } />
                <Route path="*" element={ <NotFound /> } />
            </Routes>

            <Errors />
            <Success />
            <Info />
        </main>
    );

}

export default Sentry.withProfiler(inject('authStore', 'inventoryStore')(observer(App)));

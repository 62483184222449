import React,  { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Session } from 'bc-react-session';
import { mainStore } from '../../stores/MainStore';

class AccountSettings extends Component {

  constructor(props){
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      text: 'show',
      inputType: 'password'
    }
  }

  componentDidMount(){
    let token = this.props.authStore.token;
    if (token) {
        
    } else {
        window.location.href = '/login';
    }
}

  handleCurrentPasswordChange = (e) => {
    e.preventDefault();
    this.setState({
      currentPassword: e.target.value
    })
  }

  handleNewPasswordChange = (e) => {
    e.preventDefault();
    this.setState({ 
      newPassword: e.target.value
    });
  }

  handleNewPasswordConfirmationChange = (e) => {
    e.preventDefault();
    this.setState({
      newPasswordConfirmation: e.target.value
    }); 
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if(this.state.newPassword === '' || this.state.newPasswordConfirmation === '' || this.state.currentPassword === ''){
      mainStore.setError('All password fields are mandatory!');
      return;
    }
    if (this.state.newPassword !== this.state.newPasswordConfirmation) {
      mainStore.setError('Passwords don\'t match!');
    } else {
      const session = Session.get("aametals-app-session");
      this.props.authStore.resetPassword(session.payload.user.email, this.state.currentPassword, this.state.newPassword);
    }
  }

  showPassword = () =>{
    const { text, inputType } = this.state

    this.setState({ inputType: inputType === 'text' ? 'password' : 'text', text: text === 'hide' ? 'show' : 'hide'})
  }

  render(){
    const { currentPassword, newPassword, newPasswordConfirmation, text, inputType } = this.state;

    return (
      <div className='panel accountSettings'>
        <form className="passwordSettingsReset__form login__form" onSubmit={ this.handleSubmit } >
          <div className="password-requirements">Password requirements:</div>
          <ul className="password-tips">
            <li>Minimum length 10 characters</li>
            <li>1 uppercase character (A-Z)</li>
            <li>1 lowercase character (a-z)</li>
            <li>1 digit (0-9)</li>
          </ul>

          { this.state.passwordError && <div>Passwords don't match!</div>}
          <div className="passwordResetPrompt">Enter your current password:</div>
          <div className="currentPassword">
            <input type={inputType} value={ currentPassword } onChange={ this.handleCurrentPasswordChange } required/> <span onClick={this.showPassword}>{text}</span>
          </div>
          <div className="passwordResetPrompt">Enter a new password:</div>
          <input type={inputType}value={ newPassword } onChange={ this.handleNewPasswordChange } placeholder="New password" required />
          <input type={inputType} value={ newPasswordConfirmation} onChange={ this.handleNewPasswordConfirmationChange } placeholder="Confirm new password" required />
          <button className="button button-block" name="button" onClick={ this.handleSubmit} type="submit">Submit</button>
        </form>
      </div>
    )
  }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(AccountSettings));
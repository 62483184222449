import React from 'react';


// Plugins
import ReactTable from '../../ReactTable/ReactTable';

const List = ({ items, columns }) => {


    return <div className="panel">
        <h3>There are { items.length } open quotes</h3>
        <ReactTable
            data={ items }
            noDataText="No open quotes found."
            columns={columns}
            defaultPageSize={ 10 } />
    </div>
};

export default List;

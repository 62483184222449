import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import "./PurchaseOrderBoard.css";

class SelectedLot extends Component { 
    
    render(){
        const { description, lotId, location, weight, price, oldPrice, rate, remove, showRemove }  = this.props
        return (
            <div className='selected-lot'>
                <div className='description'>{ description }</div>
                <div className='lot-id'>{ lotId }</div>
                <div className='lot-id'>{ location }</div>
                <div className='properties'>
                    <div>Weight <span>{ weight }</span></div>
                    <div>Rate <span>{ oldPrice && <s>{ oldPrice }</s>} { rate }</span></div>
                    <div>Price <span>{ price }</span></div>
                </div>
                { showRemove && <div className='remove-btn' onClick={() => remove(lotId) }>Remove</div> }
            </div>
        )
    }
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(SelectedLot));
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class Errors extends Component {

    handleResetError = () => this.props.mainStore.resetError();

    render() {
        const { error } = this.props.mainStore;

        if (error) {
            return (
                <aside className="panel panel--header panel--warning">
                    <div style={ { margin: 0 } }><span dangerouslySetInnerHTML={{ __html: error }}></span></div>
                    <div style={ { cursor: 'pointer', fontSize: '1.8rem' } } onClick={ (e) => this.handleResetError() }>&times;</div>
                </aside>
            );
        } else {
            return null;
        }
    }
}

export default inject('mainStore')(observer(Errors));

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import {  mainStore } from '../../../../stores/MainStore';
import LoadingSipnner from '../../../UI/LoadingSipnner';

class BOL extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bolNo: "", 
            from: "",
            broker: "",
            estShipDate: "", 
            weight: "",
            lotCount: "",
            status: "",
            address: "",
            eTA: "",
            truckId: "",
            stopId: "", 
            invNo: "",
            bolNumber: "",
            stopName: "",
            loading: false
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
        
        } else {
            window.location.href = '/login';
        }
    }

    handleBolNoChange = (e) => {
        let value = e.target.value;
        if (value !== undefined) {
            this.setState({ bolNumber: value });
        }
    }

    handleSearch = (e) => {
        e.preventDefault()
        const { bolNumber } = this.state
        if(bolNumber) {
            this.setState({ loading: true })
            this.props.orderStore.search(bolNumber.trim(),'bol')
            .then(() => {
                this.setState({ loading: false })
                const { searchResults } = this.props.orderStore;
                if(searchResults) {
                    this.setState({ ...searchResults.data })
                }
            })
        } else {
            mainStore.setError("Please type in your BOL number");
        }
    }

    handleFile(truckId,bolNo, stopId) {
        this.setState({ loading: true })
        this.props.orderStore.fetchFile({ internalId: truckId, tranId: bolNo, stopId },'download','billoflading')
        .then(() => {
            this.setState({ loading: false })
        })
    }

    downloadButton(truckId,bolNo, stopId){
        return truckId && <div className="order-document-actions">
        <div className="view-report" onClick={() => this.handleFile(truckId,bolNo, stopId)}>
            { bolNo } <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } />
        </div>
    </div>
    }

    render() {
        const { bolNo, from, broker, estShipDate, weight, lotCount, status, address, eTA, truckId, loading, bolNumber, stopId, stopName } = this.state;
        const { searchResults } = this.props.orderStore;
        
        return (
            <section className="box inventory">
                <div className="panel panel-default">
                    <h3>Search for My BOL</h3>
                    <div className="panel-body">
                        <form onSubmit={ this.handleSearch }>
                            <div className="flex flex-row">
                                <div className="flex-1 flex-2-md">
                                    <input ref="tranId" className={`no-margin`} type="text" placeholder="BOL #" value={ bolNumber ? bolNumber : '' } onChange={ this.handleBolNoChange }/>
                                </div>
                                <div className="flex-1">
                                    <button className="button button--block no-margin" type="submit">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
                <div className='panel' style={{ height: 400 }}>
                    { loading ? <LoadingSipnner /> : null }
                    { searchResults && searchResults.error ? <div style={{ position: "absolute", top:"40%", left: "30%", fontSize: "1.2rem"}}>{ searchResults.message }</div> : ""}
                    {
                        searchResults && searchResults.searchType === "BOL" 
                        ? 
                        <div className="order-information">
                            <InfoGridPanel className={"col-4"}>
                                <InfoGridItem  label={"BOL ID:"} value={ bolNo }/>
                                <InfoGridItem  label={"Stop Name:"} value={ stopName }/>
                                <InfoGridItem  label={"Broker/Company:"} value={ broker }/>
                                <InfoGridItem  label={"Weight:"} value={ weight }/>
                                <InfoGridItem  label={"# of Lots:"} value={ lotCount }/>
                                <InfoGridItem  label={"ETA Date:"} value={ eTA }/>
                                <InfoGridItem  label={"Est Ship Date:"} value={ estShipDate }/>
                                <InfoGridItem  label={"Status:"} value={ status }/>
                                <InfoGridItem  label={"BOL Report:"} value={ this.downloadButton(truckId, stopName, stopId) }/>                         

                            </InfoGridPanel>
                            <InfoGridPanel className={"col-2"}>
                                <InfoGridItem  label={"From:"} value={ from }/>
                                <InfoGridItem  label={"To:"} value={ address }/>
                            </InfoGridPanel>
                        </div>
                        : ""
                    }
                </div>
            </section>
        );
    }
}

export default inject( 'orderStore','mainStore', 'authStore')(observer(BOL));

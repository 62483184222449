import React, { Component } from 'react';

// Style
import './NotFound.css';
import { useNavigate } from 'react-router-dom';

class NotFound extends Component {

    handleGoBack = () => this.props.history(-1);

    render() {
        return (
            <section className="notfound flex column middle center">
                <h1>Oops... Page not found!</h1>
                <button className="button" name="button" type="button" onClick={ this.handleGoBack }>Click here to return</button>
            </section>
        );
    }

}

// Giving the arrow function a name before exporting it as the default
const NotFoundWrapper = (props) => <NotFound history={useNavigate()} />;
export default NotFoundWrapper;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-table {
    display: flex;
    flex-direction: column;
}

.list-table__header {
    display: flex;

    padding: 10px 0;

    border-bottom: 1px solid #dadbdd;
}

.list-table__row {
    font-size: 12px;

    display: flex;

    padding: 10px 0;
}

.list-table__row:nth-child(odd) {
    background-color: #efeff0;
}

.list-table__cell {
    margin: 0;
    padding: 0 5px;
}

.list-table__highlight {
    background-color: var(--blue);
    color: #fff;
    padding: 2px 4px;
}

.list-table__footer {
    display: flex;

    padding: 10px 0;

    border-bottom: 1px solid #dadbdd;
    background-color: #efeff0;
}

.list-table__footer--secondary {
    border: 0;
    background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ui/lists.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;;IAEb,eAAe;;IAEf,gCAAgC;AACpC;;AAEA;IACI,eAAe;;IAEf,aAAa;;IAEb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;;IAEb,eAAe;;IAEf,gCAAgC;IAChC,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,6BAA6B;AACjC","sourcesContent":[".list-table {\n    display: flex;\n    flex-direction: column;\n}\n\n.list-table__header {\n    display: flex;\n\n    padding: 10px 0;\n\n    border-bottom: 1px solid #dadbdd;\n}\n\n.list-table__row {\n    font-size: 12px;\n\n    display: flex;\n\n    padding: 10px 0;\n}\n\n.list-table__row:nth-child(odd) {\n    background-color: #efeff0;\n}\n\n.list-table__cell {\n    margin: 0;\n    padding: 0 5px;\n}\n\n.list-table__highlight {\n    background-color: var(--blue);\n    color: #fff;\n    padding: 2px 4px;\n}\n\n.list-table__footer {\n    display: flex;\n\n    padding: 10px 0;\n\n    border-bottom: 1px solid #dadbdd;\n    background-color: #efeff0;\n}\n\n.list-table__footer--secondary {\n    border: 0;\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


import List from '../../History/List/List';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';
import { NetSuiteStatusValues, UNITS } from './NetSuiteStatusValues';

// Style
import '../OrderDetails.css';
import LoadingSpinner from '../../../UI/LoadingSipnner';


const columns = [
    {
        id: 'name',
        accessorKey: 'name',
        cell: info => info.getValue(),
        header: () => 'Name',
        footer: info => info.column.id
    },
    {
        id: 'description',
        accessorKey: 'description',
        cell: info => info.getValue(),
        header: () => 'Description',
        footer: info => info.column.id
    },
    // {
    //     id: 'location',
    //     accessorKey: 'location',
    //     cell: info => info.getValue(),
    //     header: () => 'Location',
    //     footer: info => info.column.id
    // },
    // {
    //     id: 'onhand',
    //     accessorKey: 'onhand',
    //     cell: info => info.getValue(),
    //     header: () => 'On Hand',
    //     footer: info => info.column.id
    // },
    {
        id: 'quantity',
        accessorKey: 'quantity',
        cell: info => info.getValue(),
        header: () => 'Qty',
        footer: info => info.column.id
    },
    {
        id: 'units',
        accessorKey: 'units',
        cell: info => info.getValue() && UNITS[info.getValue()],
        header: () => 'UOM',
        footer: info => info.column.id
    },
    {
        id: 'lotValue',
        accessorKey: 'lotValue',
        cell: info => info.getValue(),
        header: () => 'Lot [Heat] (Weight/LBS) MTR',
        footer: info => info.column.id
    }
]

function ItemFulfillmentDetails(props) {
    const [bolLoader, setBolLoader] = useState(false)
    const [items, setItems] = useState([])
    const [lots, setLots] = useState([])
    const [loading, setLoading] = useState(true)
    const params = useParams()

    useEffect(() => {
        // let token = props.authStore.token;
        // if (token) {
            const fetchData = () =>{
                // let itemfulfillment = props.orderStore.currentItemShip;
                // if(props.orderStore.fetchedItemfulfillmentID !== params.IF_ID){
                    //Reset this.props.orderStore.fetchedItemfulfillmentID to undefined so that the loader will trigger
                    props.orderStore.fetchedItemfulfillmentID = undefined;
                    props.orderStore.fetchRelatedItems(`itemfulfillment/${params.IF_ID}/item`)
                    .then(() => {
                        let truck_stop_id = params.truckIds.split(';');
                        if(truck_stop_id[0] === ""){
        
                        } else {
                            setBolLoader(true)
                            props.orderStore.fetchBillOfLading(truck_stop_id[0],truck_stop_id[1])
                        }
                        const {lots, items} = toJS(props.orderStore.order)

                        setLots(lots)
                        setItems(items)
                        setLoading(false)
                    })
                // }
            }

            fetchData()
        // } else {
            // window.location.href = '/login';
        // }
    },[props.authStore.token, props.orderStore, params.IF_ID, params.truckIds])

    const getLotsPerItem = (items,lots) => {
        if(lots.error){
            items.forEach((item) => {
                let lotColValue = item.lots.map((lot,key) =>
                    <div key={`lots-${key}`} className="download-icons">
                        {`(---)`}
                        <br/><br/>
                    </div>
                )

                item.lotValue = lotColValue;
            });
            return items;
        }

        items.forEach((item) => {
            let lotColValue = item.lots.map((lot,key) => {
                    let net_weight = lots[lot.internalId].net_weight;
                    let gross_weight = lots[lot.internalId].gross_weight;
                    let lotnumber = lots[lot.internalId].lotnumber;
                    let heat = lots[lot.internalId].heat;
                    let mtr_id = lots[lot.internalId].mtr_id;
                    let mtr_name = lots[lot.internalId].mtr_name;
                    let pieces = lots[lot.internalId].pieces;
                    let pieces_string = pieces ? pieces + 'ct.' : '';

                    if(net_weight === "") net_weight = 0;
                    if(gross_weight === "") gross_weight = 0;

                    return <div key={`lots-${key}`} className="download-icons" style={{ diplay: 'flex', justifyContent: 'center', alignItems:'center'}}>
                        {`${lotnumber} [${heat}] ${pieces_string} (${parseFloat(net_weight)  > 0 ? (parseFloat(net_weight)).toFixed(2) : '---'})`}
                        {
                            mtr_id && mtr_name && <div className="download-icons">
                                {/* Some PDFs DO NOT Render properly. AAM-1152 has been created for this reason */}
                                {/* <Link to={ `/order/mtrpdfviewer/${ params.id }/${ mtr_id }/${mtr_name}/material-test-report` } className="view-report" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}>View MTR</Link> */}
                                <div className="view-report__inline" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#283A8E'}}  onClick={() => handleMTRFile({ internalId: mtr_id, fileName: mtr_name })}>Download MTR</div>
                            </div>
                        }
                        <br/><br/>
                    </div>;
                }
            );
            item.lotValue = lotColValue;
        })
        return items;
    }

    // const generateItems = () => {
    //     let items = props.orderStore.itemfulfillmentItems
    //     .map((item) => {
    //         return {
    //             name: item.itemName,
    //             description: item.description,
    //             location: item.location && item.location.refName,
    //             onhand: item.onHand ,
    //             quantity: item.quantity,
    //             lotValue: item.lotValue,
    //             units: item.units
    //         }
    //     })

    //     let columns = [
    //         {
    //             id: 'name',
    //             accessorKey: 'name',
    //             cell: info => info.getValue(),
    //             header: () => 'Name',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'description',
    //             accessorKey: 'description',
    //             cell: info => info.getValue(),
    //             header: () => 'Description',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'location',
    //             accessorKey: 'location',
    //             cell: info => info.getValue(),
    //             header: () => 'Location',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'onhand',
    //             accessorKey: 'onhand',
    //             cell: info => info.getValue(),
    //             header: () => 'On Hand',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'quantity',
    //             accessorKey: 'quantity',
    //             cell: info => info.getValue(),
    //             header: () => 'Qty',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'units',
    //             accessorKey: 'units',
    //             cell: info => info.getValue() && UNITS[info.getValue()],
    //             header: () => 'UOM',
    //             footer: info => info.column.id
    //         },
    //         {
    //             id: 'lotValue',
    //             accessorKey: 'lotValue',
    //             cell: info => info.getValue(),
    //             header: () => 'Lot [Heat] (Weight/LBS) MTR',
    //             footer: info => info.column.id
    //         }
    //     ]
    //     return [items, columns];
    // }

    const handleMTRFile = (fileParams) => {
        props.orderStore.fetchMTRDownload(fileParams);
    }

    const handleBOLFile = (record)=> {
        if(record.id === 'No_truck_id') return props.mainStore.setError('This item fulfillment has no Truct ID');;

        props.orderStore.fetchFile({ internalId: record.id, tranId: record.tranid, stopId: record.stopId },'download','billoflading')
    }


    if(loading) return <LoadingSpinner/>;
    
    const { currentItemShip } = props.orderStore;
    const { custbody_special_instructions, lastmodifieddate, status, tranid, trandate } = currentItemShip;
    const { progress } = props.mainStore

    const lotItems = getLotsPerItem(items,lots);

    return (
        <div className="order-details-container">
            <div className="panel" id="order-details">
                <div className="order-header">
                    <h2>Item Fulfillment</h2>
                    <div className="order-document-actions">
                        {/* {
                            props.orderStore.billOfLading &&
                            <Link to={ `/order/pdfviewer/${ tranid }/${ props.orderStore.billOfLading.truckId }/billoflading` } className="view-report" style={{ cursor: 'pointer', color:'#283A8E' }}>View BOL PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link>
                        } */}
                        <div className="view-report__inline" style={{ cursor: 'pointer', color:'#283A8E' }} onClick={ () => handleBOLFile({id:props.orderStore.billOfLading ? props.orderStore.billOfLading.truckId : 'No_truck_id' , tranid:`BOL_${props.orderStore.billOfLading ? props.orderStore.billOfLading.stopName : ''}`, stopId: props.orderStore.billOfLading.stopId }) }>
                            Download BOL <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } />
                        </div>
                    </div>

                </div>
                <div className="order-information">
                    <InfoGridPanel className={"col-4"}>
                        <InfoGridItem  label={"Item Fulfillment ID"} value={ tranid }/>
                        <InfoGridItem  label={"Lastmodified Date"} value={ lastmodifieddate }/>
                        <InfoGridItem  label={"Status"} value={ NetSuiteStatusValues[`ItemShip:${status}`] }/>
                        <InfoGridItem  label={"Transaction Date"} value={ trandate }/>
                        <InfoGridItem  label={"Truck"} value={ props.orderStore.billOfLading ? props.orderStore.billOfLading.bolNo : <LoadingSpinner show={bolLoader}/>} />
                        <InfoGridItem  label={"Stop"} value={ props.orderStore.billOfLading ? `${props.orderStore?.billOfLading?.stopName}` : <LoadingSpinner show={bolLoader}/> }/>
                        <InfoGridItem  label={"Weight (LBS)"} value={ props.orderStore.billOfLading ? props.orderStore.billOfLading.weight : <LoadingSpinner show={bolLoader}/>}/>

                    </InfoGridPanel>
                    <InfoGridPanel className={"col-2"}>
                        <InfoGridItem  label={"Address"} value={ props.orderStore.billOfLading ? props.orderStore.billOfLading.address : <LoadingSpinner show={bolLoader}/> }/>
                        <InfoGridItem  label={"Special Instruction"} value={ custbody_special_instructions }/>
                    </InfoGridPanel>
                    { progress && progress.loading && <LoadingSpinner />}
                </div>
            </div>
            <div className="panel" id="order-items">
                <List
                    data={lotItems || []}
                    columns={columns}
                    noDataText={"No Itemfulfillment Lot Items"}
                    isLoading={loading}
                    title={""}
                    description={`There are ${ lotItems && lotItems.length} Items`}
                />
            </div>
        </div>
    );
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(ItemFulfillmentDetails));

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';


import List from '../../History/List/List';
import { InfoGridPanel, InfoGridItem } from '../../../Page/InfoGridPanel/InfoGridPanel';

// Style
import '../OrderDetails.css';
import LoadingSipnner from '../../../UI/LoadingSipnner';


//Table columns
const columns = [
    {
        id: 'salesOrderNo',
        accessorKey: 'salesOrderNo',
        cell: info => info.getValue(),
        header: () => 'SO #',
        footer: info => info.column.id
    },
    {
        id: 'name',
        accessorKey: 'name',
        cell: info => info.getValue(),
        header: () => 'Part #',
        footer: info => info.column.id
    },
    {
        id: 'heat',
        accessorKey: 'heat',
        cell: info => info.getValue(),
        header: () => 'Heat #',
        footer: info => info.column.id
    },
    {
        id: 'lotNumber',
        accessorKey: 'lotNumber',
        cell: info => info.getValue(),
        header: () => 'Lot #',
        footer: info => info.column.id
    },
    {
        id: 'status',
        accessorKey: 'status',
        cell: info => info.getValue(),
        header: () => 'Lot Status',
        footer: info => info.column.id
    },
    {
        id: 'weight',
        accessorKey: 'weight',
        cell: info => info.getValue(),
        header: () => 'WT(LBS) #',
        footer: info => info.column.id
    },
    {
        id: 'piece',
        accessorKey: 'piece',
        cell: info => info.getValue(),
        header: () => 'Pieces',
        footer: info => info.column.id
    }

]

function BillOfLadingDetails(props) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = () => {
            let currentBillOfLading = props.orderStore.currentBillOfLading.split(';');
            //Reset this.props.orderStore.billOfLading to undefined so that the loader will trigger
            props.orderStore.billOfLading = undefined
            props.orderStore.fetchBillOfLading(currentBillOfLading[0],currentBillOfLading[1])
            .then(() => {
                setLoading(false)
            })
        }

        fetchData()

    },[props.orderStore])

    const handleBOLFile = (record) => {
        if(record.id === 'No_truck_id') return props.mainStore.setError('This item fulfillment has no Truct ID');;
        
        props.orderStore.fetchFile({ internalId: record.id, tranId: record.tranid, stopId: record.stopId },'download','billoflading')
    }

    const getLotsPerItem = (order, status) => {
        const items = order.items;
        const lots = order.lots;
        let new_items = [];
        items.forEach((item) => {
            item.lots.map((lot,key) => {
                let net_weight = lots[lot.internalId].net_weight;
                let gross_weight = lots[lot.internalId].gross_weight;
                let lotnumber = lots[lot.internalId].lotnumber;
                let heat = lots[lot.internalId].heat;

                if(net_weight === "") net_weight = 0;
                if(gross_weight === "") gross_weight = 0;
                
                return new_items.push({ 
                    salesOrderNo:order.tranId, 
                    name: item.name, 
                    lotNumber: lotnumber, 
                    heat: heat, 
                    status: status,
                    piece: lot.quantity,
                    weight: parseFloat(net_weight) > 0 ? (parseFloat(net_weight)).toFixed(2) : '---'
                })
            }
            )
        })        
        return new_items
    }
    
    if(loading) return <LoadingSipnner />; 

    const { bolNo, from, broker, estShipDate, weight, lotCount, status, address, eTA, truckId, stopId, stopName } = props.orderStore.billOfLading;
    const { progress } = props.mainStore;        
    const lotItems =  getLotsPerItem(props.orderStore.order,status)


    return (
        <div className="order-details-container">
            <div className="panel" id="order-details">
                <div className="order-header">
                    <h2>Bill of Lading</h2>
                    <div className="order-document-actions">
                        {/* The following line is to show the a link to the PDF viewer, which is still a little buggy as of 04/28/23.
                        It and all other similar links in the code have been commented out until the PDF viewer issue is fixed.
                        See AAM-942 and AAM-916. */}
                        {/* <Link to={ `/order/pdfviewer/${ bolNo }/${ truckId }/billoflading` } className="view-report ">View PDF<i className="icon-view-pdf" style={ { display: 'inline-block', cursor: 'pointer'} } /></Link> */}
                        <div className="view-report" onClick={ (e) => handleBOLFile({id:truckId ? truckId : 'No_truck_id' , tranid:'BOL_' + stopName, stopId }) }>Download PDF <i className="icon-download" style={ { display: 'inline-block', cursor: 'pointer'} } /></div>
                    </div>
                </div>
                <div className="order-information">
                    <InfoGridPanel className={"col-4"}>
                        <InfoGridItem  label={"BOL ID:"} value={ bolNo }/>
                        <InfoGridItem  label={"Stop Name:"} value={ stopName }/>
                        <InfoGridItem  label={"Broker/Company:"} value={ broker }/>
                        <InfoGridItem  label={"Weight:"} value={ weight }/>
                        <InfoGridItem  label={"# of Lots"} value={ lotCount }/>
                        <InfoGridItem  label={"Est Ship Date:"} value={ estShipDate }/>
                        <InfoGridItem  label={"ETA Date:"} value={ eTA }/>
                        <InfoGridItem  label={"Status:"} value={ status }/>
                    </InfoGridPanel>
                    <InfoGridPanel className={"col-2"}>
                        <InfoGridItem  label={"From:"} value={ from }/>
                        <InfoGridItem  label={"To:"} value={ address }/>
                    </InfoGridPanel>
                    { progress && progress.loading && <LoadingSipnner />}
                </div>
            </div>
            <div className="panel" id="order-items">
                <List 
                    data={lotItems || []} 
                    columns={columns} 
                    noDataText={"No Bill of Lading Items"} 
                    isLoading={loading}
                    title={""}
                    description={`There are ${lotItems.length} Items`}
                />
            </div>
        </div>
    );
}

export default inject('cartStore', 'orderStore', 'inventoryStore', 'mainStore', 'authStore')(observer(BillOfLadingDetails));

import React from 'react';
import './CircularProgress.css';

const CircularProgress = ({ size, progress }) => {
  const strokeWidth = 5;
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size} className="circular-progress">
      <circle
        className="circular-progress-bar"
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <text x={center} y={center} textAnchor="middle" dy=".3em" fontSize="15">
        {`${progress}%`}
      </text>
    </svg>
  );
};

export default CircularProgress;


export const NetSuiteStatusValues = {
    "VendBill:A":"Bill:Open",
    "VendBill:B":"Bill:Paid In Full",
    "VendPymt:V":"Bill Payment:Voided",
    "VendPymt:Z":"Bill Payment:Online Bill Pay Pending Accounting Approval",
    "CashSale:A":"Cash Sale:Unapproved Payment",
    "CashSale:B":"Cash Sale:Not Deposited",
    "CashSale:C":"Cash Sale:Deposited",
    "Check:V":"Check:Voided",
    "Check:Z":"Check:Online Bill Pay Pending Accounting Approval",
    "Commissn:A":"Commission:Pending Payment",
    "Commissn:O":"Commission:Overpaid",
    "Commissn:P":"Commission:Pending Accounting Approval",
    "Commissn:R":"Commission:Rejected by Accounting",
    "Commissn:X":"Commission:Paid in Full",
    "CustCred:A":"Credit Memo:Open",
    "CustCred:B":"Credit Memo:Fully Applied",
    "CustDep:A":"Customer Deposit:Not Deposited",
    "CustDep:B":"Customer Deposit:Deposited",
    "CustDep:C":"Customer Deposit:Fully Applied",
    "CustRfnd:V":"Customer Refund:Voided",
    "ExpRept:A":"Expense Report:In Progress",
    "ExpRept:B":"Expense Report:Pending Supervisor Approval",
    "ExpRept:C":"Expense Report:Pending Accounting Approval",
    "ExpRept:D":"Expense Report:Rejected by Supervisor",
    "ExpRept:E":"Expense Report:Rejected by Accounting",
    "ExpRept:F":"Expense Report:Approved by Accounting",
    "ExpRept:G":"Expense Report:Approved (Overridden) by Accounting",
    "ExpRept:H":"Expense Report:Rejected (Overridden) by Accounting",
    "ExpRept:I":"Expense Report:Paid In Full",
    "CustInvc:A":"Invoice:Open",
    "CustInvc:B":"Invoice:Paid In Full",
    "ItemShip:A":"Item Fulfillment:Picked",
    "ItemShip:B":"Item Fulfillment:Packed",
    "ItemShip:C":"Item Fulfillment:Shipped",
    "Journal:A":"Journal:Pending Approval",
    "Journal:B":"Journal:Approved for Posting",
    "Opprtnty:A":"Opportunity:In Progress",
    "Opprtnty:B":"Opportunity:Issued Estimate",
    "Opprtnty:C":"Opportunity:Closed – Won",
    "Opprtnty:D":"Opportunity:Closed – Lost",
    "Paycheck:A":"Paycheck:Undefined",
    "Paycheck:C":"Paycheck:Pending Tax Calculation",
    "Paycheck:D":"Paycheck:Pending Commitment",
    "Paycheck:F":"Paycheck:Committed",
    "Paycheck:P":"Paycheck:Preview",
    "Paycheck:R":"Paycheck:Reversed",
    "CustPymt:A":"Payment:Unapproved Payment",
    "CustPymt:B":"Payment:Not Deposited",
    "CustPymt:C":"Payment:Deposited",
    "LiabPymt:V":"Payroll Liability Check:Voided",
    "PurchOrd:A":"Purchase Order:Pending Supervisor Approval",
    "PurchOrd:B":"Purchase Order:Pending Receipt",
    "PurchOrd:C":"Purchase Order:Rejected by Supervisor",
    "PurchOrd:D":"Purchase Order:Partially Received",
    "PurchOrd:E":"Purchase Order:Pending Billing/Partially Received",
    "PurchOrd:F":"Purchase Order:Pending Bill",
    "PurchOrd:G":"Purchase Order:Fully Billed",
    "PurchOrd:H":"Purchase Order:Closed",
    "Estimate:A":"Quote:Open",
    "Estimate:B":"Quote:Processed",
    "Estimate:C":"Quote:Closed",
    "Estimate:V":"Quote:Voided",
    "Estimate:X":"Quote:Expired",
    "RtnAuth:A":"Return Authorization:Pending Approval",
    "RtnAuth:B":"Return Authorization:Pending Receipt",
    "RtnAuth:C":"Return Authorization:Cancelled",
    "RtnAuth:D":"Return Authorization:Partially Received",
    "RtnAuth:E":"Return Authorization:Pending Refund/Partially Received",
    "RtnAuth:F":"Return Authorization:Pending Refund",
    "RtnAuth:G":"Return Authorization:Refunded",
    "RtnAuth:H":"Return Authorization:Closed",
    "SalesOrd:A":"Sales Order:Pending Approval",
    "SalesOrd:B":"Sales Order:Pending Fulfillment",
    "SalesOrd:C":"Sales Order:Cancelled",
    "SalesOrd:D":"Sales Order:Partially Fulfilled",
    "SalesOrd:E":"Sales Order:Pending Billing/Partially Fulfilled",
    "SalesOrd:F":"Sales Order:Pending Billing",
    "SalesOrd:G":"Sales Order:Billed",
    "SalesOrd:H":"Sales Order:Closed",
    "TaxPymt:V":"Sales Tax Payment:Voided",
    "TaxPymt:Z":"Sales Tax Payment:Online Bill Pay Pending Accounting Approval",
    "CustChrg:A":"Statement Charge:Open",
    "CustChrg:B":"Statement Charge:Paid In Full",
    "TaxLiab:V":"Tax Liability Cheque:Voided",
    "TegPybl:E":"Tegata Payable:Endorsed",
    "TegPybl:I":"Tegata Payable:Issued",
    "TegPybl:P":"Tegata Payable:Paid",
    "TegRcvbl:C":"Tegata Receivables:Collected",
    "TegRcvbl:D":"Tegata Receivables:Discounted",
    "TegRcvbl:E":"Tegata Receivables:Endorsed",
    "TegRcvbl:H":"Tegata Receivables:Holding",
    "TrnfrOrd:A":"Transfer Order:Pending Approval",
    "TrnfrOrd:B":"Transfer Order:Pending Fulfillment",
    "TrnfrOrd:C":"Transfer Order:Rejected",
    "TrnfrOrd:D":"Transfer Order:Partially Fulfilled",
    "TrnfrOrd:E":"Transfer Order:Pending Receipt/Partially Fulfilled",
    "TrnfrOrd:F":"Transfer Order:Pending Receipt",
    "TrnfrOrd:G":"Transfer Order:Received",
    "TrnfrOrd:H":"Transfer Order:Closed",
    "VendAuth:A":"Vendor Return Authorization:Pending Approval",
    "VendAuth:B":"Vendor Return Authorization:Pending Return",
    "VendAuth:C":"Vendor Return Authorization:Cancelled",
    "VendAuth:D":"Vendor Return Authorization:Partially Returned",
    "VendAuth:E":"Vendor Return Authorization:Pending Credit/Partially Returned",
    "VendAuth:F":"Vendor Return Authorization:Pending Credit",
    "VendAuth:G":"Vendor Return Authorization:Credited",
    "VendAuth:H":"Vendor Return Authorization:Closed",
    "WorkOrd:B":"Work Order:Pending Build",
    "WorkOrd:C":"Work Order:Cancelled",
    "WorkOrd:D":"Work Order:Partially Built",
    "WorkOrd:G":"Work Order:Built",
    "WorkOrd:H":"Work Order:Closed",
    "CustomerStatus:1":"Closed",
    "CustomerStatus:2":"In Progress",
    "CustomerStatus:3":"Not Awarded",
    "CustomerStatus:4":"Pending",
    "CustomerStatus:5":"Awarded",
    "CustomerStatus:6":"Unqualified",
    "CustomerStatus:7":"Qualified",
    "CustomerStatus:8":"In Discussion",
    "CustomerStatus:9":"Identified Decision Makers",
    "CustomerStatus:10":"Proposal",
    "CustomerStatus:11":"In Negotiation",
    "CustomerStatus:12":"Purchasing",
    "CustomerStatus:13":"Closed Won",
    "CustomerStatus:14":"Closed Lost",
    "CustomerStatus:15":"Renewal",
    "CustomerStatus:16":"Lost Customer"
}

export const UNITS = {
    "1": "LB",
    "2": "PC"
}

export const DELIVERY_METHOD = [
    {"value":"1","text":"Customer Pick Up"},
    {"value":"2","text":"Delivery"}
]

export const ORDER_CONDITION = [
    {"value":"6","text":"Customer Pick-Up"},
    {"value":"4","text":"Depot"},
    {"value":"15","text":"HFR"},
    {"value":"12","text":"HFR - Confirmed"},
    {"value":"14","text":"Mill - Confirmed"},
    {"value":"1","text":"Mill"},
    {"value":"20","text":"Claims"}
]

export const SHIPPING_METHOD = [
    {"value":"21248","text":"20\u0027/40\u0027 Container + Flatbed Rear"},
    {"value":"21250","text":"20\u0027/40\u0027 Container + Flatbed Rear or Van"},
    {"value":"21247","text":"20\u0027/40\u0027 Container + Flatbed Side"},
    {"value":"21249","text":"20\u0027/40\u0027 Container + Flatbed Side or Van"},
    {"value":"21251","text":"20\u0027/40\u0027 Container + Van"},
    {"value":"12366","text":"Bulk Shippment"},
    {"value":"3023","text":"Flatbed Only"},
    {"value":"21243","text":"Flatbed Rear"},
    {"value":"21245","text":"Flatbed Rear or Van"},
    {"value":"21241","text":"Flatbed Side"},
    {"value":"21244","text":"Flatbed Side or Van"},
    {"value":"5392","text":"Flatbed or Van"},
    {"value":"21253","text":"HC Container + Flatbed Rear"},
    {"value":"21255","text":"HC Container + Flatbed Rear or Van"},
    {"value":"21252","text":"HC Container + Flatbed Side"},
    {"value":"21254","text":"HC Container + Flatbed Side or Van"},
    {"value":"21256","text":"HC Container + Van"},
    {"value":"19547","text":"High Cube Container"},
    {"value":"3020","text":"Pick-Up"},
    {"value":"3018","text":"Standard Container"},
    {"value":"4","text":"UPS"},
    {"value":"3019","text":"Van Only"}
]

export const SHIPPING_ZONES = [
    { shipzone: 'atlanta', orlando: 0.04, baltimore: 0.06},
    { shipzone: 'baltimore', orlando: 0.09, baltimore: 0.04},
    { shipzone: 'charlotte', orlando: 0.04, baltimore: 0.05},
    { shipzone: 'chicago', orlando: 0.08, baltimore: 0.07},
    { shipzone: 'cleveland', orlando: 0.07, baltimore: 0.045},
    { shipzone: 'colorado', orlando: 0.16, baltimore: 0.17},
    { shipzone: 'dakotas', orlando: 0.13, baltimore: 0.12},
    { shipzone: 'houston', orlando: 0.08, baltimore: 0.10},
    { shipzone: 'dallas', orlando: 0.08, baltimore: 0.10},
    { shipzone: 'jackson', orlando: 0.045, baltimore: 0.09},
    { shipzone: 'liberty', orlando: 0.09, baltimore: 0.09},
    { shipzone: 'los angeles', orlando: 0.16, baltimore: 0.19},
    { shipzone: 'minneapolis', orlando: 0.105, baltimore: 0.10},
    { shipzone: 'nashville', orlando: 0.06, baltimore: 0.07},
    { shipzone: 'new england', orlando: 0.11, baltimore: 0.07},
    { shipzone: 'florida', orlando: 0.03, baltimore: 0.10},
    { shipzone: 'rochester', orlando: 0.11, baltimore: 0.05},
    { shipzone: 'seattle', orlando: 0.21, baltimore: 0.20}
]

export const PRICE_LEVELS = [
    { id: "6", name: "B", rate: 2.50 },
    { id: "1", name: "A", rate: 2.05 },
    { id: "5", name: "Online Price", rate: 2.06 },
    { id: "7", name: "C", rate: 2.07 },
    { id: "-1", name: "Custom", rate: 2.05 }
]

export const US_STATES = [
    { "value":"AL", "text":"AL"},
	{ "value":"AK", "text":"AK"},
	{ "value":"AZ", "text":"AZ"},
	{ "value":"AR", "text":"AR"},
	{ "value":"CA", "text":"CA"},
	{ "value":"CO", "text":"CO"},
	{ "value":"CT", "text":"CT"},
	{ "value":"DE", "text":"DE"},
	{ "value":"DC", "text":"DC"},
	{ "value":"FL", "text":"FL"},
	{ "value":"GA", "text":"GA"},
	{ "value":"HI", "text":"HI"},
	{ "value":"ID", "text":"ID"},
	{ "value":"IL", "text":"IL"},
	{ "value":"IN", "text":"IN"},
	{ "value":"IA", "text":"IA"},
	{ "value":"KS", "text":"KS"},
	{ "value":"KY", "text":"KY"},
	{ "value":"LA", "text":"LA"},
	{ "value":"ME", "text":"ME"},
	{ "value":"MD", "text":"MD"},
	{ "value":"MA", "text":"MA"},
	{ "value":"MI", "text":"MI"},
	{ "value":"MN", "text":"MN"},
	{ "value":"MS", "text":"MS"},
	{ "value":"MO", "text":"MO"},
	{ "value":"MT", "text":"MT"},
	{ "value":"NE", "text":"NE"},
	{ "value":"NV", "text":"NV"},
	{ "value":"NH", "text":"NH"},
	{ "value":"NJ", "text":"NJ"},
	{ "value":"NM", "text":"NM"},
	{ "value":"NY", "text":"NY"},
	{ "value":"NC", "text":"NC"},
	{ "value":"ND", "text":"ND"},
	{ "value":"OH", "text":"OH"},
	{ "value":"OK", "text":"OK"},
	{ "value":"OR", "text":"OR"},
	{ "value":"PA", "text":"PA"},
	{ "value":"RI", "text":"RI"},
	{ "value":"SC", "text":"SC"},
	{ "value":"SD", "text":"SD"},
	{ "value":"TN", "text":"TN"},
	{ "value":"TX", "text":"TX"},
	{ "value":"UT", "text":"UT"},
	{ "value":"VT", "text":"VT"},
	{ "value":"VA", "text":"VA"},
	{ "value":"WA", "text":"WA"},
	{ "value":"WV", "text":"WV"},
	{ "value":"WI", "text":"WI"},
	{ "value":"WY", "text":"WY"}
]



export function formatTableData(type, value) {
    const fieldTypes = {
        custbodycustomer_order_method: DELIVERY_METHOD,
        custbody6: ORDER_CONDITION,
        orderMethod: DELIVERY_METHOD,
        orderCondition: ORDER_CONDITION
    }

    if(fieldTypes[type]) {
        const results = fieldTypes[type].filter((element) => element.value === value)
        if(results.length > 0) {
            return results[0].text
        } else {
            return 'Not Configured'
        }
    }
    return value
}
// Replaces al characters after a given word in a sentence
export const replaceCharactersAfterWord = (sentence, word) => {
    const regex = new RegExp(`${word}.*`);
    const replacedSentence = sentence.replace(regex, match => ' '.repeat(match.length));
  
    return replacedSentence;
}
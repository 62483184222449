import React from "react";

const FileUpload = ({ selectedFiles, removeFile, handleFileChange, inputKey }) => {

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <label style={{ fontSize: '0.7rem'}}>Please upload any relevant documents (.pdf, .docx, .doc, .xls or .xlsx formats) related to this purchase order, if needed.</label>
                <label style={{ fontSize: '0.7rem'}}>You may upload up to 5 files, with a total combined size of 5MB or less. Thank you!</label>
                <label htmlFor="upload" className="custom-file-input">
                    <div style={{ display: 'flex', justifyContent:'center', alignItems: 'center', border: '1px solid #AAABAE', width: '50%', marginTop: '0.5rem', borderRadius: '5px', padding: '1rem'}}>
                        <input
                            key={inputKey} 
                            id="upload"
                            type="file" 
                            onChange={handleFileChange} 
                            multiple 
                            accept=".pdf,.docx,.doc,.xls,.xlsx" //accept only these files
                            style={{ display: 'none' }}
                        />
                        
                        <i className="fas fa-paperclip" style={{ fontSize: '1.2rem'}}/>
                    </div>
                </label>
            </div>
            <ul style={{ marginTop: '0.5rem'}}>
                {selectedFiles.map((file, index) => (
                    <li key={index} onClick={() => removeFile(file?.name)} style={{ color: '#AAABAE'}}>
                        <i className="fas fa-paperclip" style={{ fontSize: '0.8rem'}}/>
                        {file?.name}
                        <i className="fas fa-window-close" style={ { marginLeft:'0.1rem', color:'red', display: 'inline-block', cursor: 'pointer'} } />
                    </li>
                ))}
            </ul>
        </>
    )
}

export default FileUpload;
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// Style
import './History.css';

// Components
import List from '../History/List/List';

class OrderHistory extends Component {
    constructor(props){
        super(props);

        this.state = {
            inititalOrders: [
                { tranId: '', otherRefNum:'', status:'', createdDate:'', dueDate:'', orderMethod:'', orderCondition:'' },
                { tranId: '', otherRefNum:'', status:'', createdDate:'', dueDate:'', orderMethod:'', orderCondition:'' },
                { tranId: '', otherRefNum:'', status:'', createdDate:'', dueDate:'', orderMethod:'', orderCondition:'' }
            ]
        }
    }

    componentDidMount() {
        let token = this.props.authStore.token;
        if (!token) { window.location.href = '/login'; }
    }

    handleFilters = (filter) => {

        const { tranId, otherRefNum, status, tranDateBegin, tranDateEnd } = filter;
        let newFilter = { tranId, otherRefNum, status, tranDateBegin, tranDateEnd }
        

        if(tranId){
            newFilter = { tranId: tranId, otherRefNum: null, status: null, tranDateBegin: null, tranDateEnd: null }
        }
        if(otherRefNum){
            newFilter = { tranId: null, otherRefNum: otherRefNum, status: null, tranDateBegin: null, tranDateEnd: null }
        }
        if(!tranId && !otherRefNum && !status && !tranDateBegin && !tranDateEnd ){
            this.props.mainStore.setError('Date fields cannot be empty. Please choose a date range.');
            return;
        }
        this.props.orderStore.fetchOrders(newFilter);
    }

    render() {
        const { data, columns, isLoading } = this.props;
        return (
            <section className="box">
                <List 
                    data={data} 
                    columns={columns} 
                    listType="inventory" 
                    rowSize={data && data.length} 
                    noDataText={"No recent sales order data found"} 
                    title={"Recent Sales Orders"}
                    description={`There are ${data.length} recent sales orders`}
                    isLoading={isLoading}
                />   
            </section>
        );
    }
}

export default inject('orderStore', 'mainStore', 'cartStore', 'authStore')(observer(OrderHistory));

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.announcement {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    padding-top: 0;
    margin-bottom: 2rem;
}
.announcement ul {
    padding-left: 1rem;
}
.announcement ul li {
    list-style-type: disc;
    padding-bottom: 0.5rem;
}
.announcement h2 {
    margin-bottom: 0.7rem;
}
.announcement h3 {
    cursor: pointer;
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Announcement/Announcement.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":["\n.announcement {\n    background-color: #fff;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1rem;\n    font-size: 0.9rem;\n    font-weight: 400;\n    padding-top: 0;\n    margin-bottom: 2rem;\n}\n.announcement ul {\n    padding-left: 1rem;\n}\n.announcement ul li {\n    list-style-type: disc;\n    padding-bottom: 0.5rem;\n}\n.announcement h2 {\n    margin-bottom: 0.7rem;\n}\n.announcement h3 {\n    cursor: pointer;\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Document, Page, pdfjs } from 'react-pdf';

// Style
import './List.css';
import LoadingSipnner from '../../../UI/LoadingSipnner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            numPages: null,
            pageNumber: 1,
            isDownloading: true,
            scale: 1.3
        }

        this.handleZoomIn = this.handleZoomIn.bind(this)
        this.handleZoomOut = this.handleZoomOut.bind(this)
    }

    componentDidMount(){
        if(this.props.order && this.props.order.type === 'material-test-report'){
            //fetch pdf data from AWS
            this.props.orderStore.fetchMTRDownload(this.props.order,'view')
        } else {
            // this.props.orderStore.fetchFile(this.props.order,'view',this.props.match.params.type)
            // .then(() => {
            // })
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages, pageNumber: 1, isDownloading: false });
    }

    changePage = offset => this.setState(prevState => ({
        pageNumber: prevState.pageNumber + offset
    }));

    previousPage = () => this.changePage(-1);
    nextPage = () => this.changePage(1);

    handleZoomIn() {
        this.setState({ scale: Math.min(1.8, this.state.scale + 0.5) });
    }
    
    handleZoomOut() {
        this.setState({ scale: Math.max(1.5, this.state.scale - 0.5) })
    }

    download() {
        this.setState({ isDownloading: true })
        this.props.orderStore.fetchMTRDownload(this.props.order)
        .then(() => {
            this.setState({ isDownloading: false })
        })
    }

    render() {
        const { order, goBack, back } = this.props;
        const { pageNumber, numPages, isDownloading, scale } = this.state;
        return (
        <div className="panel pdf-reader">
            <div className="flex between pdf-viewer">
                { back ? <h3 className="flex"><div onClick={ () => back() }>Back</div></h3> : <h3 className="flex"><a href="#" onClick={ (event) => goBack(event) }>Back</a>&nbsp; { order.type } #: { order.tranId }</h3>}
                <div className='pdf-zoom-buttons'>
                    <h3 onClick={() => this.download()}>Download MTR</h3>
                    <h3 onClick={this.handleZoomIn}>Zoom-In</h3>
                    <h3 onClick={this.handleZoomOut}>Zoom-Out</h3>
                </div>
            </div>
            <div style={{width: '50%', marginLeft: '5rem', marginRight: '20rem', marginBottom: '1rem'}}>
                <div className='pdf-next-prev-buttons'>
                    <p>Page {pageNumber || (numPages ? 1 : '...')} of {numPages || '...'}</p>
                    <div>
                        <button type="button" disabled={pageNumber <= 1} onClick={this.previousPage}>Previous</button>
                        <button type="button" disabled={pageNumber >= numPages} onClick={this.nextPage}>Next</button>
                    </div>
                </div>
                <Document file={this.props.orderStore.linkSource} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                    <Page pageNumber={pageNumber} scale={scale}/>
                </Document>
                { isDownloading ? <LoadingSipnner /> : "" }
            </div>
        </div>
        )
    };
}

export default inject('cartStore', 'inventoryStore', 'orderStore')(observer(List));

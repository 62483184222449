import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

class PlaceHolder extends Component {

    componentDidMount() {
        let token = this.props.authStore.token;
        if (token) {
            
        } else {
            window.location.href = '/login';
        }
    }

    handleFilters = (filter) => {
        
    }

    render() {
       

        return (
            <section className="box home">
                <div className="panel center">
                    <h1>{ this.props.text }</h1>
                </div>
                
            </section>
        );
    }
}

export default inject('orderStore', 'mainStore', 'cartStore', 'authStore')(observer(PlaceHolder));

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pure-chat-btn-home-page {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999999;
    font-weight: bold;
    color: var(--blue);
    bottom: 20px;
    right: 20px;
    font-size: 1rem;
    background: white;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}`, "",{"version":3,"sources":["webpack://./src/styles/ui/pure-chat-custom.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,8EAA8E;AAClF","sourcesContent":["#pure-chat-btn-home-page {\n    position: absolute;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    z-index: 999999;\n    font-weight: bold;\n    color: var(--blue);\n    bottom: 20px;\n    right: 20px;\n    font-size: 1rem;\n    background: white;\n    padding: 1rem;\n    border-radius: 5px;\n    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
